import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import EditSetModal from './EditSetModal';
import { addUserSet, renameUserSet, removeUserSet, setCurrentSet, updateExcludeUserSet, updateVisibilityeUserSet } from '../../redux/actionsUsers';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SetsControl = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const [editName, setEditName] = useState("");
    const [editExclude, setExclude] = useState(false);
    const [setId, setSetId] = useState("");
    const [ownerId, setOwnerId] = useState("");
    const [publicFlag, setPublicFlag] = useState(false);
    const [modalTitle, setModalTitle] = useState("Edit Set");

    const userReducer = useSelector(state => state.userReducer);
    if (!userReducer) { return <></>; }

    let myCompaniesCount = userReducer.myCompanies ? ` (${userReducer.myCompanies.length})` : ``;

    const editSetFn = (setId, ownerId, currentName, exclude, publicFlag, event) => {

        setSetId(setId);
        setOwnerId(ownerId);
        setModalTitle("Edit Set");
        setEditName(currentName);
        setExclude(exclude);
        setPublicFlag(publicFlag);
        setShowAddModal(true);
        event.stopPropagation();
    }

    const addSetFn = () => {
        setSetId(null);
        setModalTitle("Add Set");
        setEditName("");
        setExclude(false);
        setPublicFlag(false);
        setShowAddModal(true);
    }

    const editSuccessFn = (updatedName, exclude, publicFlagParam) => {

        setEditName(updatedName);

        if (updatedName) {
            if (setId) {

                if (editName != updatedName) {
                    console.log("Updating set name");
                    renameUserSet(setId, ownerId, updatedName);
                }
            } else {
                addUserSet(updatedName, [], publicFlagParam );
            }
        }

        // see if exclude has changed
        if (setId && editExclude != exclude) {

            console.log("Updating set notifications");
            updateExcludeUserSet(setId, ownerId, exclude);
        }

        if (setId && publicFlag !== publicFlagParam) {
            // updating public status
            console.log("Updating set visitbility");
            updateVisibilityeUserSet(setId, ownerId, publicFlagParam);
        }

        setShowAddModal(false);
    }

    const removeSuccessFn = () => {

        // setEditName("");

        if (setId) {
            removeUserSet(setId, ownerId);
        }
        setShowAddModal(false);
    }
    const mySets = userReducer.sets;

    if (!mySets || mySets.length === 0) return <></>;

    const currentSetId = userReducer.currentSetId;
    const currentSet = userReducer.currentSet; // currentSetId && mySets.find(set => set.setId === currentSetId) ? mySets.find(set => set.setId === currentSetId) : mySets[0];
    const currentIndex = mySets.findIndex(set => set.setId === currentSetId);

    if (!currentSet) return <></>

    mySets.sort((s1, s2) => {
        if (s1.public === s2.public) return 0; // if both are either true or false, they remain in their order
        if (s1.public) return -1; // if s1 is true, it should come before s2
        return 1; // if s2 is true, it should come before s1
    });

    return (
        <>
            <div className=' flex flex-row items-center content-center '>

                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center underline bg-white pl-3 pt-1  font-normal  text-base text-custom-blue ">
                            {currentSet.name} <span className=''></span>
                            <ChevronDownIcon className="h-7 mr-1  text-gray-700" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >

                        <Menu.Items className="max-h-96	 overflow-y-auto absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="">
                                <div className=" border-t-2 border-gray-100">
                                    <Menu.Item>
                                        <>
                                            <a
                                                onClick={() => addSetFn()}
                                                href="#"
                                                className={classNames(
                                                    'block px-4 py-2 text-sm font-normal'
                                                )}
                                            >
                                                + Add New Set
                                            </a>
                                            <hr />
                                        </>
                                    </Menu.Item>
                                </div>
                                <div className="border-t-2 border-gray-100 font-normal">
                                    {
                                        mySets.filter(set => set.public).map((mySet, setIndex) => (
                                            <Fragment key={mySet.setId}>
                                                <Menu.Item>
                                                    <a
                                                        href="#"
                                                        onClick={(event) => setCurrentSet(mySet.setId)}
                                                        className={classNames(
                                                            (currentIndex === setIndex) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <div className=
                                                            {classNames(
                                                                (mySet.public) ? 'text-custom-blue' : 'text-gray-700',
                                                                'flex flex-row align-middle justify-between'
                                                            )}
                                                        >


                                                            {mySet.name}

                                                            <svg onClick={(event) => editSetFn(mySet.setId, mySet.userId, mySet.name, mySet.exclude, mySet.public, event)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-3  text-gray-700">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </Menu.Item>
                                            </Fragment>

                                        ))
                                    }
                                </div>
                                <div className="py-1 border-t-2 border-gray-100 font-normal">
                                    {
                                        mySets.filter(set => !set.public).map((mySet, setIndex) => (
                                            <Fragment key={mySet.setId}>
                                                <Menu.Item>
                                                    <a
                                                        href="#"
                                                        onClick={(event) => setCurrentSet(mySet.setId)}
                                                        className={classNames(
                                                            (currentIndex === setIndex + mySets.filter(set => set.public).length) ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <div className=
                                                            {classNames(
                                                                (mySet.public) ? 'text-custom-blue' : 'text-gray-700',
                                                                'flex flex-row align-middle justify-between'
                                                            )}
                                                        >


                                                            {mySet.name}

                                                            <svg onClick={(event) => editSetFn(mySet.setId, mySet.userId, mySet.name, mySet.exclude, mySet.public, event)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-3  text-gray-700">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </Menu.Item>
                                            </Fragment>

                                        ))
                                    }
                                </div>
                            </div>
                        </Menu.Items>

                    </Transition>
                </Menu>

            </div>
            <EditSetModal
                show={showAddModal}
                title={modalTitle}
                closeHandler={() => setShowAddModal(false)}
                removeHandler={() => removeSuccessFn()}
                successHandler={(name, exclude, publicFlag) => editSuccessFn(name, exclude, publicFlag)}
                currentPublicFlag={publicFlag}
                currentName={editName}
                currentExclude={editExclude} />

        </>
    );
};


export default SetsControl;