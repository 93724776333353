import React from 'react';
import { FILTERTYPE } from '../redux/actionTypes';
import FilterModal from './filterModal';
import {
  resetFavFilter,
  setFavFilterAttributes,
} from '../redux/actionsFilters';

export class FilterControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterModal: false,
    };
  }

  resetFilter() {
    resetFavFilter();
  }

  filter(state, filterType) {
    setFavFilterAttributes(filterType, state);
  }

  render() {
    return (
      <>
        <div className="w-full flex flex-wrap flex-row text-gray-600">
          {this.props.showSearch && (
            <div className="flex items-center justify-center	mt-3 pr-9">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input type="text" autoFocus className="w-96 border-b-2 border-gray-100  outline-0 outline-white	" id="searchText" name="researchTextsult" value={this.props.companyNameFilter} placeholder="Search Favourites" onChange={((event) => this.filter(event.target.value, FILTERTYPE.COMPANYNAME))} /><br />
            </div>
          )}
          <div className="flex flex-row items-center justify-center">
            <h1 className="w-full pt-3 pr-4">
              {' '}
              Showing: {this.props.filteredCompanies.length} out of{' '}
              {this.props.companies.length}{' '}
            </h1>
          </div>
          <div className="flex flex-row items-center justify-center mt-3 pr-5">
            <button onClick={() => this.props.showFilterHandler()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                />
              </svg>
            </button>
          </div>
          <div>
            {/* <FilterModal show={this.state.filterModal} companies={this.props.companies} filteredCompanies={this.props.filteredCompanies} closeHandler={() => this.setState({
            filterModal: false,
          })} /> */}

          </div>
          <div className="flex flex-row items-center justify-center mt-3 pr-5">
            <button
              onClick={() => this.resetFilter()}
              className="text-m text-blue-500"
            >
              Reset
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default FilterControl;
