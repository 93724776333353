import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { callPutApi } from '../helpers/apiHelper';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { sizes, models } from "../helpers/common";

const animeatedComponents = makeAnimated();


const mapStateToProps = (state) => {
  return {
    companies: state.cnpReducer ? state.cnpReducer.companies : [],
    originalData: state.cnpReducer ? state.cnpReducer.companiesOriginal : [],
  };
};

export class IssueControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: true,
      open: true,
      cancelButton: null,
      radioNum: 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({
      setOpen: true,
      open: true,
      correctModel: '',
      cancelButton: null,
    });
  }

  getListIndex(index) {
    this.setState({
      radioNum: index,
    });
  }

  submitIssue() {
    let index = this.state.radioNum;
    let idValue;
    let param = '';
    if (index ===1) {
      idValue = document.getElementById('freemium-trial').value;
      param = this.state.correctModel;
    } else if (index === 0) {
      idValue = document.getElementById('planName').value;
    } else if (index === 2) {
      idValue = document.getElementById('screenshot').value;
    } else if (index === 3) {
      idValue = document.getElementById('valueMetric').value;
    } else if (index === 4) {
      idValue = document.getElementById('employees').value;
    }
    console.log(idValue);
    console.log(this.state.correctModel);
    this.props.closeHandler();


    const callback = (data) => {
      console.log('sumbitIssue callback!');
      console.log(data);
    };

    // call put api

    const queryParams = new URLSearchParams(window.location.search);
    const slug = queryParams.get('slug');
    const pram = { issue: idValue, slug, value: param };
    console.log(pram);
    callPutApi(
      process.env.REACT_APP_AUDIENCE + '/scraper/sumbitIssue',
      pram,
      callback
    );

    // https://dev-tasks.pricingsaas.com/scraper/sumbitIssue

    return idValue;
  }

  render() {

    const modelData = models.map( (model,index) => ({ value: model, label: model, num : index}));

    return (
      <Transition.Root show={this.props.show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={this.props.closeHandler}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-left sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Report an Issue
                      </Dialog.Title>
                      <div className="mt-2">
                        <ul class="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg ">
                          <li class="w-full rounded-t-lg">
                            <div class="flex items-center pl-3">
                              <input
                                id="freemium-trial"
                                type="radio"
                                value="model"
                                name="list-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onClick={() => this.getListIndex(1)}
                              />
                              <label
                                for="freemium-trial"
                                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Acquisition Model{' '}
                              </label>
                            </div>
                            { this.state.radioNum === 1 &&
                              <div className='pl-9 flex flex-row w-96'>
                                <span>Correct value:</span>
                                <Select
                                    id="modelDropdown"
                                    className="w-full p-1 mr-6"
                                    closeMenuOnSelect={false}
                                    components={animeatedComponents}
                                    // value={ }
                                    // options={this.props.valuemetricsData}
                                    options={modelData}
                                    onChange={((event) =>
                                      this.setState({
                                        correctModel: event.value,
                                      }) )}
                                />
                              </div>}

                          </li>
                          <li class="w-full rounded-t-lg">
                            <div class="flex items-center pl-3">
                              <input
                                id="planName"
                                type="radio"
                                value="planNames"
                                name="list-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onClick={() => this.getListIndex(0)}
                              />
                              <label
                                for="planName"
                                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Plan Names
                              </label>
                            </div>
                          </li>
                          <li class="w-full rounded-t-lg">
                            <div class="flex items-center pl-3">
                              <input
                                id="screenshot"
                                type="radio"
                                value="screenshot"
                                name="list-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onClick={() => this.getListIndex(2)}
                              />
                              <label
                                for="screenshot"
                                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Screenshot
                              </label>
                            </div>
                          </li>
                          <li class="w-full rounded-t-lg">
                            <div class="flex items-center pl-3">
                              <input
                                id="valueMetric"
                                type="radio"
                                value="valueMetric"
                                name="list-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onClick={() => this.getListIndex(3)}
                              />
                              <label
                                for="valueMetric"
                                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Pricing Metric
                              </label>
                            </div>
                          </li>
                          <li class="w-full rounded-t-lg">
                            <div class="flex items-center pl-3">
                              <input
                                id="employees"
                                type="radio"
                                value="employees"
                                name="list-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onClick={() => this.getListIndex(4)}
                              />
                              <label
                                for="employees"
                                class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Employees
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => this.submitIssue()}
                      style={{ background: '#28CDC2' }}
                    >
                      Ok
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => this.props.closeHandler()}
                      ref={this.state.cancelButton}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default connect(mapStateToProps)(IssueControl);
