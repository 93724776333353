import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CSVLink } from 'react-csv';
import { callPutApi } from '../helpers/apiHelper';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export const REPORT_TYPE = {
  CSV: 'csv',
  HTML: 'html',
};

export const PAGE_STATE = {
  READY: 'ready',
  LOADING: 'loading',
  REPORT_READY: 'reportready',
};

const copyToClipboard = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
    console.log("successfully copied");
    })
    .catch(() => {
      alert('something went wrong');
    });
    console.log("copied clicked!")
  }

const callExportApi = (companies, filter, reportType, setPageState, setCSVData, setReportUrl) => {

  const callback = async (data) => {
    console.log(data.data);

    if (reportType === REPORT_TYPE.CSV) {
      setCSVData(data.data);
    } else {
      setReportUrl(data.data);
    }
    setPageState(PAGE_STATE.REPORT_READY);
  };

  const callbackError = (data) => {
    console.log(data);

    alert("Hmmmm... something went wrong");

  }

  let param = { data: companies, filter };
  setPageState(PAGE_STATE.LOADING);

  console.log('calling report api...');
  console.log(param);
  if (reportType === REPORT_TYPE.CSV) {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateCVSReport`,
      param,
      callback,
      callbackError
    );
  } else if (reportType === REPORT_TYPE.HTML) {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateHTMLReport`,
      param,
      callback,
      callbackError
    );
  }
}

const getContents = (pageState) => {
  switch (pageState) {
    case PAGE_STATE.READY:
      return <>ready</>;
    case PAGE_STATE.LOADING:
      return <>loading</>;
    case PAGE_STATE.REPORT_READY:
      return <>download</>;
    default:
      break;
  }

  return <></>;
};

const ReportModal = ({  show, paidUser, companies, filter, closeHandler }) => {
  const [reportURL, setReportURL] = useState('')
  const [reportType, setReportType] = useState(REPORT_TYPE.HTML);
  const [pageState, setPageState] = useState(PAGE_STATE.READY);
  const [csvData, setCSVData] = useState([]);
  const [reportUrl, setReportUrl] = useState();

  const array = `id, employees, pricing page Url\n`;

  const myCloseHandler = () => {
    setPageState(PAGE_STATE.READY);
    closeHandler();
  }

  console.log(paidUser);

  // if (pageState === PAGE_STATE.READY) {
  //   callExportApi(companies.map(c => c.companyId), filter, reportType, setPageState, setCSVData, setReportUrl);
  // }


  return (
    <>
        <Transition.Root show={show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={myCloseHandler}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className='px-12 py-6'>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => myCloseHandler()}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 ">
                                            { pageState === PAGE_STATE.READY &&
                                                <div>Data Export</div>
                                            }
                                            { pageState === PAGE_STATE.REPORT_READY &&
                                                <div>Export is ready...</div>
                                            }
                                            { pageState === PAGE_STATE.LOADING &&
                                                <div>Working...</div>
                                            }

                                        </Dialog.Title>
                                        <div className="flex flex-col mt-3 text-left sm:mt-5">


                                            { pageState === PAGE_STATE.REPORT_READY &&
                                                <>
                                                { reportType === REPORT_TYPE.CSV ?
                                                        <CSVLink data={csvData} filename={"favouries.csv"} >
                                                            <div className="text-m text-blue-500">Click to Download </div>
                                                        </CSVLink>
                                                        :
                                                        <>   <div className="body-modal py-4 ">
                                                              <div className="content ml-0 flex ">
                                                            <div className="flex">
                                                            <a className="text-blue-500" href={reportUrl} target="_blank">Click Here To View</a>
                                                            <ClipboardDocumentListIcon
                                                                className="cursor-pointer block h-6 w-6 m-auto ml-4" style={{ fill: '#e7e5e4' }}
                                                                onClick={() => copyToClipboard(reportUrl)}>
                                                            </ClipboardDocumentListIcon>
                                                             {/* <div type="text"
                                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                                                    focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                                                    dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
                                                                >{reportUrl}
                                                            </div> */}
                                                            </div></div></div>

                                                        </>

                                                }

                                                </>
                                            }
                                            { pageState === PAGE_STATE.LOADING &&
                                                <>
                                                Generating data goodness...
                                                </>
                                            }
                                            { pageState === PAGE_STATE.READY &&
                                                <>

                                                    <div className="mb-3 mt-3">Choose format:</div>

                                                    <div class="flex items-center mb-4">
                                                        { reportType === REPORT_TYPE.HTML ?
                                                            <input checked id="default-radio-2" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                            :
                                                            <input onClick={() => setReportType(REPORT_TYPE.HTML)}  id="default-radio-2" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                        }
                                                        <label for="default-radio-2" className="ml-2  text-gray-900 dark:text-gray-300">Export HTML</label>
                                                    </div>
                                                    <div class="flex items-center ">
                                                        { reportType === REPORT_TYPE.CSV ?
                                                                <input id="default-radio-1" checked type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                                :
                                                                <input onClick={() => setReportType(REPORT_TYPE.CSV)} id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                        }
                                                        <label for="default-radio-1" className="ml-2  text-gray-900 dark:text-gray-300">Export CVS</label>
                                                    </div>

                                                    { !paidUser && reportType === REPORT_TYPE.CSV &&
                                                      <div className="mb-3 mt-3"><strong>Note:</strong> export to CSV is a paid feature. Click <a href="/plans"><span className='text-blue-500'>here</span> to upgrade.</a></div>
                                                    }
                                                    <div className="flex flex-row pt-9">
                                                        <button onClick={() => myCloseHandler()} className="border text-gray-600 mr-3 border-teal-500 font-bold py-2 px-2 w-48 text-center ">
                                                            Cancel
                                                        </button>
                                                        { reportType  ?
                                                            <button
                                                                onClick={() => callExportApi(companies.map(c => c.companyId), filter, reportType, setPageState, setCSVData, setReportUrl)} className="border text-white border-teal-500 font-bold py-2 px-2 w-48 text-center "
                                                                style={{ background: '#008CFF'}}
                                                                >Export
                                                            </button>
                                                        :
                                                            <button
                                                                className="border text-white border-teal-500 font-bold py-2 px-2 w-48 text-center bg-gray-300"

                                                                >Export
                                                            </button>
                                                    }
                                                    </div>
                                                </>
                                            }




                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
    </>
  );
};

export default ReportModal;
