/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainHeader from './Settings/MainHeader';
import CompanyDetailsControl from './Sets/CompanyDetailsControl';
import { publicSearchCompanies, clearSearchResults } from '../redux/actionsCompanies';

const CompanyControl = ({ autenticated = true }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [searching, setSearching] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [slug, setSlug] = useState(null);
    const [instruction, setInstruction] = useState('E.g. "Project Management"');
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const userReducer = useSelector(state => state.userReducer);

    if (firstLoad) {

        setFirstLoad(false);

        const searchParams = new URLSearchParams(window.location.search);
        const slugParam = searchParams.get('slug');
        setSlug(slugParam);
    }

    if (!slug) {
        return <>Invalid parameter</>
    }


    return (
        <>
            <div className="w-full p-4 bg-white lg:border sm:p-8">
                <MainHeader />
                <CompanyDetailsControl publicControl={false} slug={slug} backHandler={() => { window.location = '/psearch'; }} />
            </div>
        </>
    );
};

export default CompanyControl;