export const ADD_TODO = "ADD_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";
export const SET_FILTER = "SET_FILTER";
export const SET_CNP = "SET_CNP";
export const SET_FILTER_ATTRIBUTE = "SET_FILTER_ATTRIBUTE";
export const FILTER_CNP = "FILTER_CNP";
export const UPDATE_FAVS = "UPDATE_FAVS";
export const SET_USER_APPROVED = "SET_USER_APPROVED";
export const SET_ANLYTICS = "SET_ANLYTICS";
export const SET_PLAN_TIERS = "SET_PLAN_TIERS";
export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const INIT = 'INIT';
export const LOAD_PRICING_METRICS = "LOAD_PRICING_METRICS";
export const LOAD_CATEGORY_COMPANIES = "LOAD_CATEGORY_COMPANIES";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const SEARCH_SORTBY = "SEARCH_SORTBY";
export const LOAD_USER_COMPANIES = "LOAD_USER_COMPANIES";
export const LOAD_USER = "LOAD_USER";
export const UPDATE_USER_FAVS = "UPDATE_USER_FAVS";
export const SET_FAV_FILTER_ATTRIBUTE = "SET_FAV_FILTER_ATTRIBUTE";
export const SET_EXPLORE_FILTER = "SET_EXPLORE_FILTER";
export const SET_USER_FILTER = "SET_USER_FILTER";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_USER_COMPANY = "SET_USER_COMPANY";
export const SET_ENTITLEMENT = "SET_ENTITLEMENT";
export const SET_USER_CATEGORIES = "SET_USER_CATEGORIES";
export const SET_USER_EMAIL_FREQ = "SET_USER_EMAIL_FREQ";
export const SET_USER_WEEKLY_DIGEST = "SET_USER_WEEKLY_DIGEST";
export const SET_USER_EMAIL_ALERTS = "SET_USER_EMAIL_ALERTS";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_USER_PERSONA = "SET_USER_PERSONA";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const DISMISS_RECO = "DISMISS_RECO";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_FAV_SORT_ORDER = "SET_FAV_SORT_ORDER";
export const SORT_BY_EXPLORE_ATTRIBUTE = "sortyByExploreAttribute";
export const SORT_BY_EXPLORE_SORT_ORDER = "sortyByExploreOrder";
export const SET_EXPLORE_SORT_ORDER = "SET_EXPLORE_SORT_ORDER";
export const RESET_FAV_FILTER  = "RESET_FAV_FILTER";
export const LOAD_EXPLORE_COMPANIES  = "LOAD_EXPLORE_COMPANIES";
export const SET_EXPLORE_COMPANIES_LOADING  = "SET_EXPLORE_COMPANIES_LOADING";
export const SET_MYCOMPANIES = "SET_MYCOMPANIES";
export const SET_MYCOMPANIES_LOADING = "SET_MYCOMPANIES_LOADING";
export const SET_COMPANY_LIST = "SET_COMPANY_LIST";
export const SET_USERSETS = "SET_USERSETS";
export const SET_CURRENT_SET = "SET_CURRENT_SET";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const SET_LOGIN_REDIRECT = "SET_LOGIN_REDIRECT";
export const LOAD_SET = "LOAD_SET";
export const ADDING_COMPANY_TO_SET = "ADDING_COMPANY_TO_SET";
export const RESET = "RESET";
export const INVALIDATE = "INVALIDATE";
export const IMPERSONATE = "IMPERSONATE";

export const SORT_BY_ATTRIBUTE = 'sortyByAttribute';
export const SORT_BY_ORDER = 'sortyByOrder';

export const FILTERTYPE = {
  ACQUISITION: 'acquisition',
  COMPANYSIZE: 'companysize',
  VALUEMETRIC: 'valuemetric',
  PLANNAMES: 'plannames',
  COMPANYNAME: 'companyname',
  PRICINGMETRIC: 'pricingMetric',
  COMPANYTYPE: 'company',
  FREEMIUMANDFREETRIALTYPE: 'freemiumandtrial',
  FAVOURITESTYPE: 'favourites',
  ENTERPRISETYPE: 'enterprise',
  CATEGORYTYPE: 'category',
  RESETTYPE: 'reset',
};

export const ACQUISITION_FILTER = {
  ALL: 'all',
  FREEMIUM_ONLY: 'freemium',
  FREETRIAL_ONLY: 'trial',
  REVERSE_TRIAL: 'reversetrial',
  DEMO_ONLY: 'demo',
};

export const COMPANYSIZE_FILTER = {
  ALL: 'all',
  LESSTHAN50: 'lessthan50',
  BETWEEN50AND250: 'between50and250',
  BETWEEN250AND1000: 'between250and1000',
  OVER1000: 'over1000',
};

export const GENERIC_FILTER = {
  ALL: 'all',
};

export const OPTIONTYPE = {
  ALLFREEMIUMANDTRIAL: 'allfreemiumandtrial',
  ONLYFREEMIUM: 'onlyFreemium',
  NONE: 'nonfreemiumandtrial',
  ONLYFREETRIAL: 'onlyfreetrial',
  BOTH: 'bothfreemiumandtrial',
  ALLENTERPRISE: 'allenterprise',
  ONLYENTERPRISE: 'onlyenterprise',
  NONENTERPRISE: 'nonenterprise',
  ALLCOMPANYSIZE: 'allcompanysize',
  LESSTHAN50: 'lessthan50',
  BETWEEN50AND250: 'between50and250',
  BETWEEN250AND1000: 'between250and1000',
  OVER1000: 'over1000',
  ONLYFAVOURITES: 'favsonly',
  RESETALL: 'reset',
};

export const SORT_ATTRIBUTES = {
  NAME: 'name',
  CATEGORY: 'category',
  ACQUISITION_MODEL: 'model',
  EMPLOYEES: 'employees',
  FOUNDED: 'founded',
  FOUNDING: 'founding',
  LAST_MODIFIED: 'lastmodified',
  FAVOURITES: 'favourites',
  PRICINGMETRIC: 'pricingmetric',
  VALUEMETRIC: 'valuemetric',
  FREEMIUM: 'freemium',
  TRIAL: 'trial',
};
