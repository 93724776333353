/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from 'react-redux'
import { setUserApproved } from "../redux/actions";
import InsightsControl from "./insightsControl";
import ListControl from "./listControl";
import ReportModal from "./reportModal";
import SelectCompanyControl from "./selectCompanyControl";
import { loadCompanyNames, loadUserCompanies } from "../redux/actionsCompanies";
import FilterControl from "./filterControl";
import { getUserCompanies} from "../redux/actionsCompanies";
import { applyUserFilters, applyUserSort, setFavSortOrder } from "../redux/actionsFilters";
import FilterExploreModal from "../components/FilterExploreModal/FilterExploreModal";
import { sizes, models } from "../helpers/common";
import { setUserFilter } from "../redux/actionsFilters";
import { PlayIcon } from "@heroicons/react/24/outline";
// import { setFavSortOrder } from "../redux/actionsFilters";

const mapStateToProps = state => {

  return {
    companies : getUserCompanies(state),
    favsFilter :  state.filtersReducer &&  state.filtersReducer.favsFilter ? state.filtersReducer.favsFilter : {},
    categories : state.companiesReducer &&  state.companiesReducer.categories ? state.companiesReducer.categories : [],
    pricingMetrics : state.filtersReducer &&  state.filtersReducer.pricingMetrics ? state.filtersReducer.pricingMetrics.map( ps => ps.name) : [],
    filteredCompanies: applyUserSort(applyUserFilters(getUserCompanies(state), state.filtersReducer), state.filtersReducer),
    companyNameFilter: state.filtersReducer ? state.filtersReducer.companyname : "",
    betaApproved : state.userReducer ? state.userReducer.approved : false
  }
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export class FavsControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      sortBy : "lastmodified",
      sortAscending : true,
      showLightbox : false,
      passcode : '',
      tabs: [
        { name: 'List', current: true },
        { name: 'Insights', current: false },
      ],
    };
  }

  currentTab = () => {
    const currentTab = this.state.tabs.find((t) => t.current);
    return currentTab.name;
  };

  toggleTabs = (name) => {
    this.setState((prevState) => {
      const newState = prevState.tabs.map((tab) => {
        return tab.name === name ? (tab.current = true) : (tab.current = false);
      });

      return newState;
    });
  };

  componentDidMount() {
    loadCompanyNames();
    loadUserCompanies();
    console.log(this.props);
    console.log(this.state);
  }

  setPasscode(value) {
    this.setState({ passcode: value });
    if (value === 'buyersoninc') {
      setUserApproved(true);
    }
  }

  suggestCompany() {
    window.location.href = '/add';
  }

  render() {
    if (this.state.refreshing) {
      return (
        <>
          <div className="w-full text-center mt-24">
            Loading data... this will take a few seconds!
          </div>
        </>
      );
    }
    // if (!this.props.betaApproved) {
    //   return <>
    //     <div className="p-9 mt-32 text-xl m-auto text-center">
    //       <div className="block">
    //         We are glad to see you here! PricingSaaS.com is in private beta right now.
    //       </div>
    //       <div className="block my-9">
    //         If you hava a passcode, enter it below (or shoot us an email john@buyerson.co)
    //       </div>
    //       <div className="block my-9">
    //         {/* if you're inpecting this code - that means you really want to get in. We like inquisitive minds. If you find the passcode here, jump in!  */}
    //         <input type="text" autofocus className="textbox border-2 border-gray-600" placeholder="passcode" id="passcode" name="result" value={this.statepasscode} onChange={ (event) => { this.setPasscode(event.target.value)}}>
    //         </input>
    //       </div>
    //     </div>
    //   </>
    // }

    return  (
      <>
          <div className="flex flex-row justify-between mt-9">
          { !this.state.showModal &&
            <>
              {/* <nav
                className="-mb-px flex space-x-8 cursor-pointer"
                aria-label="Tabs"
              >
                {this.state.tabs.map((tab) => (
                  <div key={tab.name}>
                    <button
                      key={tab.name}
                      onClick={() => this.toggleTabs(tab.name)}
                      style={
                        tab.current
                          ? { borderColor: '#008CFF', color: '#008CFF' }
                          : {}
                      }
                      className={classNames(
                        tab.current
                          ? 'border-blue-500 text-blue-500'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                      )}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                    </div>
                  ))}
                </nav> */}
                {/* <div className="py-0 " >
                {this.currentTab() === 'List' &&
                    <button onClick={() => this.setState({ showLightbox : true })} className="border text-gray-600 mr-3 border-teal-500 font-bold py-2 px-2 w-48 text-center ">
                    Slideshow
                    </button>
                }
                <button onClick={() => this.setState({ showModal : true })}
                 className="border text-gray-600 mr-3 border-teal-500 font-bold py-2 px-2 w-48 text-center "
                    >Add</button>
                <button onClick={() => this.setState({ showReportModal : true })}
                className="border text-white border-teal-500 font-bold py-2 px-2 w-48 text-center "

                style={{ background: '#008CFF'}}>
                  Generate Report
                </button>
                </div> */}
                </>
          }

          </div>
            <SelectCompanyControl
              show={this.state.showModal}
              cancelHandler={() => { this.setState({ showModal: false })} }/>

          {/* {  !this.state.showModal && this.currentTab() === 'List' &&
            <div className="flex mt-3 pt-3 mb-9  ">
              <FilterControl
                showFilterHandler={() => { this.setState({ showFilterModal: true })}}
                companies={this.props.companies} filteredCompanies={this.props.filteredCompanies} companyNameFilter={this.props.companyNameFilter} showSearch={true}/>
            </div>
          } */}

          {/* <FilterExploreModal
              show={ this.state.showFilterModal }
              categories={ this.props.categories  }
              pricingMetricsData={ this.props.pricingMetrics.map( (pricingMetric,index) => ({ value: pricingMetric, label: pricingMetric, num : index})) }
              value={this.props.favsFilter.categories}
              sizesData={ sizes }
              modelData={ models }
              sizes={this.props.favsFilter.sizes}
              models={this.props.favsFilter.models}
              onFilterHandler={ (filter) => { setUserFilter(filter, () => this.setState({ showFilterModal: false })); }}
              onCloseHandler={() => { this.setState({ showFilterModal: false })}}  />

          {  this.state.showReportModal &&
            <div className="flex mt-3 mb-9">
              <ReportModal show={ this.state.showReportModal} companies={ this.props.filteredCompanies } closeHandler={() => { this.setState({ showReportModal: false })} } />
            </div>
          } */}
          { !this.state.showModal ?
              this.currentTab() === 'List' ?
                  <ListControl companies={this.props.companies} filteredCompanies={this.props.filteredCompanies} showLightbox={this.state.showLightbox} hideLightbox={() => { this.setState({ showLightbox: false })} } sortOrderHandler={setFavSortOrder}/>
                  :
                  <div className="mt-32"><InsightsControl companies={this.props.filteredCompanies}  /></div>
                :
                <></>
         }

      </>
    );
  }
}

export default connect(mapStateToProps)(FavsControl);
