import {
  LOAD_SET,
  IMPERSONATE,
  ADDING_COMPANY_TO_SET,
  CLEAR_SEARCH_RESULTS,
  SET_CURRENT_COMPANY,
  SET_USER_COMPANY, SET_ANLYTICS, DISMISS_RECO, SET_PLAN_TIERS, SET_LOGIN_REDIRECT, SET_ENTITLEMENT, SEARCH_RESULTS, SET_WORKSPACE, SET_USER_WEEKLY_DIGEST, SET_USER_EMAIL_ALERTS, SEARCH_SORTBY, SET_USER_APPROVED, SET_USER_EMAIL, SET_CURRENT_SET, SET_USERSETS, SET_USER_EMAIL_FREQ, SET_MYCOMPANIES_LOADING, SET_COMPANY_LIST, LOAD_USER, SET_MYCOMPANIES, UPDATE_USER_FAVS, SET_REQUESTS, SET_USER_SETTINGS, SET_USER_CATEGORIES, SET_USER_PERSONA, SET_USER_NAME, RESET, INVALIDATE
} from '../actionTypes';

const initialState = {
  approved: false,
};

let companies = null;
let company = null;
let cIndex = null;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return null;
    }

    case INVALIDATE: {
      return {
        ...state,
        invalidated: true
      };
    }
    case ADDING_COMPANY_TO_SET: {
      return {
        ...state,
        addingCompanyToSet: action.payload.loading
      };
    }
    case IMPERSONATE: {
      return {
        ...state,
        impersonate: action.payload.impersonate
      };
    }
    case DISMISS_RECO: {

      const currentSet = !state.currentSet ? {} : { ...state.currentSet };
      if (currentSet) { currentSet.dismissedRecos = action.payload.dismissedRecos; }
      return {
        ...state,
        currentSet: currentSet
      };
    }
    case SET_USER_PERSONA: {
      return {
        ...state,
        persona: action.payload.persona,
      };
    }
    case LOAD_SET: {

      console.log("Loading set", action.payload.dismissedRecos);

      return {
        ...state,
        addingCompanyToSet: false,
        currentSet: {
          plans: action.payload.plans,
          attributes: action.payload.attributes,
          versions: action.payload.versions,
          recos: action.payload.recos,
          dismissedRecos: action.payload.dismissedRecos,
          name: action.payload.name || state.currentSet && state.currentSet.name,
          setId: action.payload.setId || state.currentSet && state.currentSet.setId
        }
      };
    }
    case SET_USER_NAME: {
      return {
        ...state,
        userName: action.payload.name,
      };
    }
    case SET_COMPANY_LIST: {
      return {
        ...state,
        companyList: action.payload.list,
        requests: action.payload.requests
      };
    }
    case SET_REQUESTS: {
      return {
        ...state,
        requests: action.payload.requests
      };
    }

    case SET_USER_EMAIL_FREQ: {
      return {
        ...state,
        emailFrequency: action.payload.frequency,
      };
    }

    case SET_USER_WEEKLY_DIGEST: {
      return {
        ...state,
        weeklyDigest: action.payload.weeklyDigest,
      };
    }
    case SET_USER_EMAIL_ALERTS: {
      return {
        ...state,
        emailAlerts: action.payload.emailAlerts,
      };
    }
    case SET_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload.company,
        currentMasters: action.payload.masters,
        currentInstances: action.payload.instances,
      };
    }
    case SET_CURRENT_SET: {
      if (action.payload.setId && state.sets) {
        const set = state.sets.find(s => s.setId === action.payload.setId);
        if (set) {
          return {
            ...state,
            currentSetId: action.payload.setId,
            currentSet: set
          };
        }
      } else {
        console.log("THIS SHOULD NOT HAPPEN!");
        return {
          ...state,
          currentSetId: action.payload.setId,
        };
      }
    }
    case SET_LOGIN_REDIRECT: {
      return {
        ...state,
        loginRedirect: action.payload.loginRedirect,
      };
    }

    case SET_PLAN_TIERS: {
      return {
        ...state,
        planTiers: action.payload.plans,
      };
    }
    case SET_ANLYTICS: {
      return {
        ...state,
        analytics: action.payload.analytics,
        reports: action.payload.reports
      };
    }
    case SET_USER_COMPANY: {

      company = action.payload.company;
      companies = !state.myCompanies ? [] : [...state.myCompanies];
      cIndex = companies.findIndex(c => c.slug === company.slug);

      // console.log(company);
      // console.log(companies);
      // console.log(cIndex);

      if (cIndex >= 0) {
        companies[cIndex] = company;

        console.log("Setting company given index");
      } else {
        companies.push(company);
      }

      return {
        ...state,
        myCompanies: companies
      };
    }

    case SET_MYCOMPANIES_LOADING: {
      return {
        ...state,
        loadingCompanies: action.payload.loadingCompanies,
      };
    }
    case CLEAR_SEARCH_RESULTS: {

      return {
        ...state,
        searchResults: [],
      };
    }

    case SEARCH_RESULTS: {
      const { companies, companiesByEmployees, results, keyword } = action.payload;


      return {
        ...state,
        searchResults: results,
        searchResultsByRelevance: companies,
        searchResultsByEmployees: companiesByEmployees,
        searchKeyword: keyword
      };
    }
    case SEARCH_SORTBY: {
      const { sortBy } = action.payload;
      return {
        ...state,
        searchResults: sortBy === 'employees' ? [...state.searchResultsByEmployees] : [...state.searchResultsByRelevance],
        sortBy: sortBy
      };
    }
    case SET_USER_APPROVED: {
      return {
        ...state,
        approved: action.payload.approved,
      };
    }
    case SET_MYCOMPANIES: {
      console.log(action.payload.user);

      return {
        ...state,
        myCompanies: action.payload.myCompanies ? action.payload.myCompanies : state.myCompanies,
        demo: action.payload.demo ? action.payload.demo : state.demo ? state.demo : [],
        favourites: [] // action.payload.favourites ? action.payload.favourites : state.favourites,
      };
    }
    case SET_USERSETS: {
      console.log("Setting user sets");
      return {
        ...state,
        sets: action.payload.userSets ? action.payload.userSets : []
      };
    }
    case SET_WORKSPACE: {
      return {
        ...state,
        workspace: action.payload.workspace
      };
    }
    case LOAD_USER: {

      // console.log(action.payload.user);

      return {
        ...state,
        currentSetId: action.payload.user.currentSetId ? action.payload.user.currentSetId : '',
        workspaceId: action.payload.user.workspaceId ? action.payload.user.workspaceId : '',
        workspace: action.payload.user.workspace ? action.payload.user.workspace : null,
        // myCompanies: action.payload.user.myCompanies ? action.payload.user.myCompanies : '',
        // demo: action.payload.user.demo ? action.payload.user.demo : [],
        sets: action.payload.user ? action.payload.user.sets : '',
        email: action.payload.user ? action.payload.user.email : '',
        emailFrequency: action.payload.user.emailFrequency ? action.payload.user.emailFrequency : '',
        userCategories: [], // action.payload.user ? action.payload.user.userCategories : [],
        entitlement: action.payload.entitlement ? action.payload.entitlement : '',
        favourites: [], //action.payload.user ? action.payload.user.favs : [],
        invalidated: false,
        lastInvalidated: (new Date()).getTime(),
      };
    }
    case SET_ENTITLEMENT: {

      console.log(action.payload.user);

      return {
        ...state,
        entitlement: action.payload.entitlement ? action.payload.entitlement : state.entitlement,
      };
    }
    case UPDATE_USER_FAVS: {
      return {
        ...state,
        favourites: [] //action.payload.favourites,
      };
    }
    case SET_USER_EMAIL: {
      return {
        ...state,
        email: action.payload.email
      };
    }
    case SET_USER_CATEGORIES: {
      const { userCategories } = action.payload;
      return {
        ...state,
        userCategories: { ...userCategories }
      };
    } case SET_USER_SETTINGS: {
      const { userSettings } = action.payload;
      return {
        ...state,
        userSettings: { ...userSettings }
      };
    }
    default:
      return state;
  }
}
