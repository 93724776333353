import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
});

export function callPutApi(apiUrl, payload, callback, callbackError) {
  return callApi(apiUrl, 'PUT', payload, callback, callbackError);
}

export function callGetApi(apiUrl, callback, callbackError) {
  return callApi(apiUrl, 'GET', null, callback, callbackError);
}

function callApi(apiUrl, method, payload, callback, callbackError) {
  auth0
    .getTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    })
    .then((accessToken) => {
      const params =
        method === 'GET'
          ? {
            method: method,
            mode: 'cors', // no-cors, *cors, same-origin
            headers: { Authorization: 'Bearer ' + accessToken },
          }
          : {
            method: method,
            mode: 'cors', // no-cors, *cors, same-origin
            headers: { Authorization: 'Bearer ' + accessToken },
            body: JSON.stringify(payload),
          };

      let resultStatus = null;
      fetch(apiUrl, params)
        .then((response) => {
          resultStatus = response.ok;
          response.json().then((data) => {
            callback(data, resultStatus);
          });
        })
        .catch((error) => {
          console.error(`Problem calling api: ${error.message}`);
          // throw new Error(`"Problem calling api: ${error.message}`);
        });
    })
    .catch((error) => {
      console.error(`Problem calling api: ${error.message}`);
      if (callbackError) {
        callbackError(`"Problem calling api: ${error.message}`);
      }
    });
}


export function callPublicGetApi(apiUrl, callback, callbackError) {
  return callPublicApi(apiUrl, 'GET', null, callback, callbackError);
}

function callPublicApi(apiUrl, method, payload, callback, callbackError) {
  const params =
    method === 'GET'
      ? {
        method: method,
        mode: 'cors', // no-cors, *cors, same-origin
      }
      : {
        method: method,
        mode: 'cors', // no-cors, *cors, same-origin
        body: JSON.stringify(payload),
      };

  let resultStatus = null;
  fetch(apiUrl, params)
    .then((response) => {
      resultStatus = response.ok;
      response.json().then((data) => {
        callback(data, resultStatus);
      });
    })
    .catch((error) => {
      console.error(`Problem calling api: ${error.message}`);
      // throw new Error(`"Problem calling api: ${error.message}`);
    });
}
