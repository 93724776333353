/* eslint-disable no-unused-vars */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import React, { Component, useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactCompareImage from 'react-compare-image';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassPlusIcon, MagnifyingGlassMinusIcon, ArrowsPointingInIcon, PauseIcon, PlayIcon } from '@heroicons/react/24/solid'
import { getCurrentSetSlugs, setCurrentSet, loadCompany } from '../../redux/actionsUsers';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { getLastSubfolder } from '../../helpers/common';
import { isFreemiumUser } from '../../helpers/common';
import { getSortedHistory, versionToDate } from '../../helpers/common';
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

function convertCommaDelimitedStringToArray(str) {
    if (!str) return [];
    // Remove the opening and closing brackets
    try {
        str = str.replace(/^\[|\]$/g, '');

        // Split the string by commas and remove whitespace
        let arr = str.split(',').map(item => item.trim());

        return arr;
    } catch (e) {
        return str;
    }

}

const getDropdown = (history, setCurrentImage, setCurrentTimestamp, setPEvent) => {

    // console.log(pricingEvents)
    return (
        <Menu as="div" className="relative flex-none">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <p className="text-sm mb-3 text-custom-blue flex flex-row justify-between font-medium">
                    Versions
                    <ChevronDownIcon className="h-5 w-5 text-custom-blue" aria-hidden="true" />
                </p>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 mt-2 w-32 origin-top-right bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" style={{ right: '-100%' }}>
                    {history.map(pEvent => (
                        <Menu.Item key={pEvent.timestamp}>
                            <div
                                onClick={() => {
                                    setCurrentTimestamp(versionToDate(pEvent.timestamp));
                                    setCurrentImage(pEvent.imageUrl);
                                    setPEvent(pEvent)
                                }}
                                className='block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer text-center'
                            >
                                {versionToDate(pEvent.timestamp)}
                            </div>
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );

}

const getBadge = (changeType) => {


    const types = convertCommaDelimitedStringToArray(changeType);

    const priceChangedBadge =
        types.find(t => t === 'PRICE_CHANGED' || t === 'priceIncrease') ?
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                Price Change
            </span>
            : <></>

    const copyChangedBadge =
        types.find(t => t === 'COPY_CHANGE' || t === 'copyChange') ?
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                Copy Change
            </span>
            : <></>

    const featureChangedBadge =
        types.find(t => t === 'FEATURES_CHANGED' || t === 'featureChange' || t === 'pricingMetric') ?
            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                Features Change
            </span>
            : <></>

    const discountChangedBadge =
        types.find(t => t === 'DISCOUNT_STARTED' || t === 'DISCOUNT_ENDED') ?
            <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                Discount Change
            </span>

            : <></>

    const plansChangedBadge =
        types.find(t => t === 'PLAN_NAME_CHANGED' || t === 'planChange') ?
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Plan Name Change
            </span>

            : <></>

    const trialChangedBadge =
        types.find(t => t === 'FREE_TRIAL_CHANGED' || t === 'acqModelChange') ?
            <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 ring-1 ring-inset ring-blue-700/10">
                Trial Changed
            </span>

            : <></>


    const ctaChangedBadge =
        types.find(t => t === 'CTA_CHANGED') ?
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                CTA Change
            </span>
            : <></>


    const designChangedBadge =
        types.find(t => t === 'LAYOUT_OR_DESIGN') ?
            <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                Design Change
            </span>
            : <></>

    const abTestBadge =
        types.find(t => t === 'AB_TEST') ?
            <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-700/10">
                A/B Test
            </span>
            : <></>


    const testimonialsBadge =
        types.find(t => t === 'TESTIMONIALS_CHANGE') ?
            <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-900 ring-1 ring-inset ring-blue-700/10">
                Testimonials
            </span>
            : <></>



    return (<>

        <div className='flex flex-row space-x-3 '>
            {priceChangedBadge}
            {copyChangedBadge}
            {featureChangedBadge}
            {discountChangedBadge}
            {plansChangedBadge}
            {trialChangedBadge}
            {ctaChangedBadge}
            {designChangedBadge}
            {abTestBadge}
            {testimonialsBadge}
        </div>

    </>)
}

// const getSortedHistory = (companies, eventTypeFilter, companiesFilter) => {

//     const allEvents = [];
//     let index = 0;

//     if (companies) {
//         companies.forEach(company => {
//             if (company.history) {
//                 company.history.forEach(h => {

//                     if (h.changeType !== "firstCapture" && !allEvents.find(ae => ae.timestamp === h.timestamp)) {
//                         const compareUrl = (h.oldImageUrl && h.newImageUrl && h.changeType !== 'firstCapture') ? `/diff` : null;
//                         const companyUrl = `https://pricingsaas.com/companies/${company.slug}.html`;
//                         const item = { id: index, slug: company.slug, date: (new Date(h.timestamp)).toDateString(), compareUrl: compareUrl, companyUrl, logoUrl: company.logoUrl, ...h };

//                         item.changeTypeRaw = (item.changeType);
//                         item.changeType = (item.changeType);

//                         if (h.newImageUrl && h.newImageUrl.includes("history")) {
//                             allEvents.push(item);
//                             index++;
//                         }

//                     }
//                 })
//             }
//         })
//     }
//     allEvents.sort((a, b) => b.timestamp - a.timestamp);

//     return allEvents;
// }

let current = false;
const ImageDiff = () => {

    const userReducer = useSelector(state => state.userReducer);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get('slug');
    const timestamp = searchParams.get('timestamp');

    const versions = userReducer && userReducer.currentSet ? userReducer.currentSet.versions : [];
    const diffs = getSortedHistory(versions, [slug]);
    diffs.reverse();

    // console.log(diffs);

    const [pEvent, setPEvent] = useState(diffs.find(d => d.timestamp === timestamp) || diffs[0] || {});
    const [currentImage, setCurrentImage] = useState(pEvent.imageUrl);
    const [fullpage, setFullpage] = useState(true);
    const [pauseSlideshow, setPauseSlideshow] = useState(false);
    const [currentTimestamp, setCurrentTimestamp] = useState(versionToDate(pEvent.version));


    const nextImage = () => {

        const eventIndex = diffs.findIndex(d => d.timestamp === pEvent.timestamp);
        const nextEvent = eventIndex < diffs.length - 1 ? diffs[eventIndex + 1] : diffs[0];
        if (nextEvent) {
            setCurrentImage(nextEvent.imageUrl);
            setCurrentTimestamp(versionToDate(nextEvent.timestamp));
            setPEvent(nextEvent);
        }
    }

    const prevImage = () => {

        const eventIndex = diffs.findIndex(d => d.timestamp === pEvent.timestamp);
        const nextEvent = eventIndex > 0 ? diffs[eventIndex - 1] : diffs[diffs.length - 1];
        if (nextEvent) {
            setCurrentImage(nextEvent.imageUrl);
            setCurrentTimestamp(versionToDate(nextEvent.timestamp));
            setPEvent(nextEvent);
        }
    }

    const enablePrevArrow = () => {
        const eventIndex = diffs.findIndex(d => d.timestamp === pEvent.timestamp);
        return eventIndex > 0;
    }

    const enableNextArrow = () => {
        const eventIndex = diffs.findIndex(d => d.timestamp === pEvent.timestamp);
        return eventIndex < diffs.length - 1;
    }

    // Function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'ArrowLeft') {
            // Handle left arrow key press
            if (enablePrevArrow()) prevImage();
        } else if (event.key === 'ArrowRight') {
            // Handle right arrow key press
            if (enableNextArrow()) nextImage();
        }
    };


    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [diffs]); // Empty dependency array ensures this runs once on mount and unmount


    // console.log(pEvent);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // console.log(pEvent.newImageUrl);

    //         if (!pauseSlideshow) {
    //             current ?
    //                 setCurrentImage(pEvent.imageUrl) :
    //                 setCurrentImage(pEvent.prevImageUrl || pEvent.imageUrl);

    //             current ?
    //                 setCurrentTimestamp(versionToDate(pEvent.timestamp)) :
    //                 setCurrentTimestamp(versionToDate(pEvent.prevTimestamp || pEvent.timestamp));
    //             // // setCurrentImage(pEvent.newImageUrl.replace('viewport', 'fullpage')) :
    //             // // setCurrentImage(pEvent.oldImageUrl.replace('viewport', 'fullpage'));

    //             current = !current;

    //             // console.log(currentImage)

    //             // if (current) {
    //             //     setFullpage(!fullpage);
    //             // }

    //             // if (pEvent.changeType && !pEvent.changeType.includes("AB_TEST")) {
    //             //     setFullpage(true);
    //             // }
    //         }

    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, [pEvent, pauseSlideshow]);

    if (!pEvent) {
        return <></>
    }

    // const diffCompany = myCompanies.find(company => company.slug === slug);
    // if (!diffCompany) {
    //     console.log("Company not found. Loading: ", slug);
    //     loadCompany(slug);
    // }
    // if (!diffCompany || !pEvent) {
    //     return <>Loading...</>
    // }
    // if (!pEvent || !pEvent.newImageUrl) {
    //     console.log(pEvent);
    //     return <>Hmmm... something does not seem right.</>
    // }
    // const versions
    // return (<></>);


    return (
        <>
            <div className="flex items-center  mb-4">
                {isFreemiumUser(userReducer) ?
                    <>  <a href="/set"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-3' alt="Logo"></img></a> </>
                    :
                    <>  <a href="/set"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-3' alt="Logo"></img></a>  </>
                }
                <h1 className="font-bold text-2xl  leading-none text-gray-900 ">
                    <><a href='/set?tab=1' className='text-custom-blue cursor-pointer'>History</a>{' > '} {slug}</>

                </h1>
            </div>

            <h1 className="text-xl text-gray-700 mb-6 border-gray-300 w-full border p-5 bg-gray-50 h-32 overflow-y-auto">
                {/* <p className="text-xs text-gray-500">Change Type:</p> */}

                <div className="tools flex flex-row justify-between ">
                    {/* <p className="text-sm mb-3 text-gray-600 flex flex-row justify-between">
                        {getDropdown(diffs, setCurrentImage, setCurrentTimestamp, setPEvent)}
                    </p> */}
                    {/* <div> {getBadge(pEvent.changeType)}</div> */}
                </div>
                {pEvent.changeDesc}
            </h1>

            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div className="tools flex flex-row justify-between">

                            {/* <div className=" text-gray-800 flex flex-row justify-between">

                                <div className="p-1">
                                    Showing<span className="text-gray-600 font-medium"> {currentTimestamp} </span>version
                                </div>

                                <button onClick={() => { setPauseSlideshow(!pauseSlideshow); }}>
                                    {!pauseSlideshow ?
                                        <PauseIcon className="h-6 w-6 mr-3 text-custom-blue" aria-hidden="true" /> :
                                        <PlayIcon className="h-6 w-6 mr-3 text-custom-blue" aria-hidden="true" />
                                    }

                                </button>
                            </div> */}

                            <div className='flex flex-row '>
                                {
                                    enablePrevArrow() ?
                                        <ArrowLeftIcon title="Acquisition Model" className="cursor-pointer block h-10 w-12 ml-3 mr-3 my-auto text-custom-blue" onClick={() => prevImage()} />
                                        :
                                        <ArrowLeftIcon title="Acquisition Model" className="cursor-pointer block h-10 w-12 ml-3 mr-3 my-auto text-gray-600" />

                                }
                                <span className="my-auto">{currentTimestamp}</span>
                                {
                                    enableNextArrow() ?
                                        <ArrowRightIcon title="Acquisition Model" className="cursor-pointer block h-10 w-12 ml-3 mr-3 my-auto text-custom-blue" onClick={() => nextImage()} />
                                        :
                                        <ArrowRightIcon title="Acquisition Model" className="cursor-pointer block h-10 w-12 ml-3 mr-3 my-auto text-gray-600" />
                                }
                            </div>
                            <div>
                                <button onClick={() => zoomIn()}><MagnifyingGlassPlusIcon className="h-12 w-6 mr-2 text-custom-blue" aria-hidden="true" /></button>
                                <button onClick={() => zoomOut()}><MagnifyingGlassMinusIcon className="h-12 w-6 mr-3 text-custom-blue" aria-hidden="true" /></button>
                                <button onClick={() => resetTransform()}><ArrowsPointingInIcon className="h-12 w-6 mr-3 text-custom-blue" aria-hidden="true" /></button>
                            </div>

                        </div>
                        <div className="  border-gray-300 w-full border p-1">
                            <TransformComponent>
                                {
                                    currentImage &&
                                    <img src={currentImage} alt="background" onError={(ev) => ev.target.src = currentImage.replace("fullpage", "viewport")} />
                                }
                            </TransformComponent>
                        </div>
                    </React.Fragment>
                )}
            </TransformWrapper>

            {/* <ReactCompareImage
                leftImage="https://share.pricingsaas.com/box/history/20230617/fullpage.jpeg"
                rightImage="https://share.pricingsaas.com/box/history/20230617/fullpage.jpeg" />; */}

        </>
    );
};

export default ImageDiff;