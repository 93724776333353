import React from 'react';
import { connect } from 'react-redux';
import AutoComplete from 'react-autocomplete';
import { addUserFav } from '../redux/actionsUsers';

const mapStateToProps = (state) => {
  return {
    companyNames: state.companiesReducer
      ? state.companiesReducer.allCompanyNames
      : [],
  };
};

export class SelectCompanyControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    // console.log(props);
  }

  componentDidMount() {
    // this.refresh();
  }

  addToFavs() {
    console.log('adding', this.state.value);

    if (!this.state.value) {
      alert('You have to select a company first silly!');
      return;
    }

    addUserFav(this.state.value, () => {
      window.location = '/favourites';
    });
  }

  render() {
    if (!this.props.show) return <></>;

    const options = this.props.companyNames
      ? this.props.companyNames.map((c) => ({ id: c, label: c }))
      : [];

    return (
      <>
        <div className="flex w-full justify-center mt-32 text-xl ">
          Select a company to add to your list of favourites...
        </div>
        <div className="flex w-full justify-center mt-9 ">
          <AutoComplete
            items={options}
            className="w-96"
            inputProps={{
              placeholder: 'Select a company...',
              size: '50',
              class: 'text-xl text-center leading-9 border border-gray-300',
            }} //style: {background: "red"}
            shouldItemRender={(item, value) =>
              item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            getItemValue={(item) => item.label}
            wrapperStyle={{ width: '500px' }}
            renderItem={(item, highlighted) => (
              <div
                key={item.id}
                style={{
                  backgroundColor: highlighted ? '#eee' : 'transparent',
                }}
              >
                {item.label}
              </div>
            )}
            value={this.state.value}
            onChange={(e) => this.setState({ value: e.target.value })}
            onSelect={(value) => {
              console.log(value);
              this.setState({ value });
            }}
          />
        </div>
        <div className="flex w-full justify-center mt-9 h-12 ">
          <button
            onClick={() => this.addToFavs()}
            className="border text-white  font-bold  px-2 w-48 text-center mr-10"
            style={{ background: '#008CFF' }}
          >
            Add To Favourites
          </button>
          <button
            onClick={() => this.props.cancelHandler()}
            className="border text-blue-600 px-2 w-48 text-center "
            style={{ background: '#fff' }}
          >
            Cancel
          </button>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(SelectCompanyControl);
