import { store } from './store';
import {
  LOAD_USER,
  SET_USER_COMPANY,
  SET_USER_CATEGORIES,
  LOAD_SET,
  DISMISS_RECO,
  SET_USERSETS,
  SET_USER_EMAIL_FREQ,
  SET_USER_WEEKLY_DIGEST,
  SET_USER_EMAIL_ALERTS,
  SET_REQUESTS,
  SET_CATEGORIES,
  SET_USER_PERSONA,
  SET_USER_NAME,
  SET_MYCOMPANIES,
  SET_COMPANY_LIST,
  SET_USER_EMAIL,
  RESET,
  INVALIDATE,
  IMPERSONATE,
  INIT,
  SET_MYCOMPANIES_LOADING,
  SET_CURRENT_SET,
  SET_CURRENT_COMPANY,
  SET_LOGIN_REDIRECT
} from './actionTypes';
import { callGetApi, callPublicGetApi, callPutApi } from '../helpers/apiHelper';
import { loadSetPageInfo } from './actionsPages';
import { getCurrentSetIds } from "../helpers/common";

export const getCurrentSetSlugs = (userReducer, skipUnversioned = false) => {

  if (!userReducer || !userReducer.currentSet || !userReducer.currentSet.attributes) return [];

  const slugs = userReducer.currentSet.attributes.map(attr => attr.slug);

  slugs.sort((a, b) => a.localeCompare(b));

  // console.log("getCurrentSetSlugs", skipUnversioned, userReducer.currentSet.versions);
  if (skipUnversioned && userReducer.currentSet.versions) {

    return slugs.filter(slug => {
      const slugVersionInfo = userReducer.currentSet.versions.find(v => v.slug === slug);
      if (slugVersionInfo && slugVersionInfo.versions) {
        return slugVersionInfo.versions.length > 0 ? true : false;
      } else {
        return false;
      }
    });
  }

  return slugs;
}

export const setUserCategories = (userCategories) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_USER_CATEGORIES,
      payload: {
        userCategories
      }
    })

    console.log("setUserSettings");
    console.log(userCategories);

    const param = { key: "userCategories", value: userCategories };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      param,
      (data) => { console.log("successfully updated user categories", data) }, (err) => { console.log(err) }
    );
  };

  store.dispatch(fn);
};

export const getCompany = async (slug, publicControl, onSuccessCallback, onFailureCallback) => {

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback = (response, dispatch, state, onSuccessCallback) => {
    console.log(response.data);

    // clean up the response

    let instances = (response.data ? response.data.instances : []) || [];
    instances = instances.filter(i => i && i.version.length === 8 && !isNaN(parseInt(i.version)));

    dispatch({
      type: SET_CURRENT_COMPANY,
      payload: {
        company: response.data && response.data.company ? response.data.company : null,
        masters: response.data && response.data.masters ? response.data.masters : null,
        instances
      }
    })

    safeCallback();
  };

  const fn = (dispatch, getState) => {
    // if (!getState().userReducer || !getState().userReducer.favourites) {

    if (publicControl) {
      // callPublicGetApi(
      //   `${process.env.REACT_APP_ENDPOINT_URL}/search?slug=${slug}`,
      //   (data) => callback(data, dispatch, getState(), onSuccessCallback),
      //   callbackError
      // );
      callPublicGetApi(
        `${process.env.REACT_APP_ENDPOINT_URL}/search?type=company&slug=${encodeURIComponent(slug)}`,
        (data) => callback(data, dispatch, getState()),
        callbackError);


    } else {
      callGetApi(
        `${process.env.REACT_APP_ENDPOINT_URL}/getCompany?slug=${slug}`,
        (data) => callback(data, dispatch, getState(), onSuccessCallback),
        callbackError
      );
    }
  };
  store.dispatch(fn);
};

export const setUserEmailFrequency = (frequency) => {


  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_USER_EMAIL_FREQ,
      payload: {
        frequency
      }
    })

    console.log("setUserSettings");
    // console.log(userCategories);

    const param = { key: "emailFrequency", value: frequency };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      param,
      (data) => { console.log("successfully updated user categories", data) }, (err) => { console.log(err) }
    );
  };

  store.dispatch(fn);
};

export const setWeeklyDigest = (value) => {


  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_USER_WEEKLY_DIGEST,
      payload: {
        weeklyDigest: value
      }
    })

    console.log("setUserSettings");
    // console.log(userCategories);

    const param = { key: "weeklyDigest", value: value };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      param,
      (data) => { console.log("successfully updated user categories", data) }, (err) => { console.log(err) }
    );
  };

  store.dispatch(fn);
};

export const setEmailAlerts = (value) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_USER_EMAIL_ALERTS,
      payload: {
        emailAlerts: value
      }
    })

    console.log("setUserSettings");
    // console.log(userCategories);

    const param = { key: "emailAlerts", value: value };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      param,
      (data) => { console.log("successfully updated user categories", data) }, (err) => { console.log(err) }
    );
  };

  store.dispatch(fn);
};


export const refresh = async (name) => {

  store.dispatch({
    type: RESET
  });
}


export const impersonate = async (userId, onSuccessCallback) => {

  store.dispatch({
    type: IMPERSONATE,
    payload: { impersonate: userId }
  });


  const safeCallback = (data) => {
    if (onSuccessCallback) {
      onSuccessCallback(data);
    }
  };

  loadUser((data) => safeCallback(data));
  // if (onSuccessCallback) {
  //   console.log("calling impersonate callback");
  //   onSuccessCallback();
  // } else {
  //   console.log("no impersonate allback");

  // }
}


export const invalidate = async () => {

  const fn = (dispatch, getState) => {

    const state = getState();

    console.log(state.userReducer);
    if (state.userReducer && !state.userReducer.invalidated) {
      dispatch({
        type: INVALIDATE
      })
    }
  };

  store.dispatch(fn);

}

export const setCurrentSet = async (setId, onSuccessCallback) => {

  console.log("Setting current set", setId);
  setLoadingCompanies(true);

  store.dispatch({
    type: SET_CURRENT_SET,
    payload: { setId }
  });

  loadSetPageInfo(null, () => setLoadingCompanies(false), () => setLoadingCompanies(false));

  return;

  const callback = (data, dispatch) => {

    console.log("successfully updated user current set", data)

    dispatch({
      type: SET_MYCOMPANIES,
      payload: {
        myCompanies: data.myCompanies
      }
    })

    setLoadingCompanies(false);
    if (onSuccessCallback) onSuccessCallback();

  }

  if (setId) {
    const fn = (dispatch, getState) => {

      const param = { key: "currentSetId", value: setId };

      // callPutApi(
      //   `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      //   param,
      //   (data) => { callback(data, dispatch) }, (err) => {
      //     setLoadingCompanies(false);
      //     console.log(err);
      //   }
      // );
    };

    store.dispatch(fn);
  }

}

export const setUserName = async (name) => {

  store.dispatch({
    type: SET_USER_NAME,
    payload: { name }
  });
}


export const setUserPersona = async (persona) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_USER_PERSONA,
      payload: {
        persona
      }
    })

    console.log("setUserPersona");

    const param = { data: persona };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserPersona`,
      param,
      (data) => { console.log("successfully updated user persona", data) }, (err) => { console.log(err) }
    );
  };

  store.dispatch(fn);
}

export const loadUser = async (onSuccessCallback) => {
  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = (data) => {
    if (onSuccessCallback) {
      onSuccessCallback(data);
    }
  };

  const callback = (response, dispatch, state, onSuccessCallback) => {
    console.log(response.data);

    if (response.data) {
      dispatch({
        type: LOAD_USER,
        payload: {
          user: response.data,
          entitlement: response.entitlement
        }
      })

    } else {
      console.error("Did not get proper user data");
      console.error(response);
      // dispatch({ type: RESET });
    }

    safeCallback(response.data);
  };

  const impersonate = store.getState().userReducer && store.getState().userReducer.impersonate;
  const param = impersonate ? encodeURI(`?impersonate=${impersonate}`) : "";
  const url = `${process.env.REACT_APP_ENDPOINT_URL}/getUser2${param}`;
  const fn = (dispatch, getState) => {
    callGetApi(
      url,
      (data) => callback(data, dispatch, getState(), onSuccessCallback),
      callbackError
    );
  };
  console.log("LOading user 2", url);
  store.dispatch(fn);
};


export const loadCompanies = async (onSuccessCallback) => {

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback = (response, dispatch, state, onSuccessCallback) => {
    // console.log(response.data);

    dispatch({
      type: SET_COMPANY_LIST,
      payload: {
        list: response.data,
        requests: response.userRequests
      }
    })

    safeCallback();
  };

  const fn = (dispatch, getState) => {
    // if (!getState().userReducer || !getState().userReducer.favourites) {
    callGetApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/getUserCompanies`,
      (data) => callback(data, dispatch, getState(), onSuccessCallback),
      callbackError
    );
    // }
  };
  store.dispatch(fn);
};

export const loadCompany = async (slug, onSuccessCallback) => {

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback = (response, dispatch, state, onSuccessCallback) => {
    console.log(response);

    if (response.data && response.data.logoUrl) {
      dispatch({
        type: SET_USER_COMPANY,
        payload: {
          company: response.data
        }
      })
    } else {
      console.error("Did not get proper company");
    }

    safeCallback();
  };

  const fn = (dispatch, getState) => {


    const param = { slug: slug };

    console.log(param);

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/getUserFav`,
      param,
      (data) => callback(data, dispatch, getState(), onSuccessCallback),
      callbackError
    );

  };
  store.dispatch(fn);
};


export const setLoginRedirect = async (loginRedirect) => {

  const fn = (dispatch, getState) => {

    const state = getState();

    if (state.userReducer && state.userReducer.loginRedirect === loginRedirect) {
      console.log("loginRedirect already set!");
    } else {
      dispatch({
        type: SET_LOGIN_REDIRECT,
        payload: {
          loginRedirect
        }
      })
    }
  };

  store.dispatch(fn);
};

export const getCompanyCompetitors = async (keyword, onSuccessCallback, onFailureCallback) => {

  const callbackError = (data) => {
    console.log('Error');
    if (onFailureCallback) onFailureCallback();
  };

  const callback = (response, dispatch, state, onSuccessCallback) => {
    console.log(response);

    // if (response.data) {
    //   dispatch({
    //     type: SET_USER_COMPANY,
    //     payload: {
    //       company: response.data
    //     }
    //   })
    // } else {
    //   console.error("Did not get proper company");
    // }

    if (response.message === "Endpoint request timed out") { onFailureCallback("Hmmm... this page is taking longer to process than expected. Try again."); }
    else if (response.errorMessage && onFailureCallback) { onFailureCallback(response.errorMessage); }
    else if (onSuccessCallback) { onSuccessCallback(response.data) }

  };

  const fn = (dispatch, getState) => {


    const param = { data: keyword };

    console.log(param);

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/getCompanyCompetitors`,
      param,
      (data) => callback(data, dispatch, getState(), onSuccessCallback),
      callbackError
    );

  };
  store.dispatch(fn);
};

export const toggleUserFavourite = async (fav, onSuccessCallback) => {
  // if (!fav) {
  //   throw new Error('Fav cannot be empty.');
  // }

  // const callbackError = (data) => {
  //   console.log('Error');
  // };

  // const safeCallback = () => {
  //   if (onSuccessCallback) {
  //     onSuccessCallback();
  //   }
  // };

  // const callback = (response, dispatch, state, onSuccessCallback) => {
  //   console.log(response.data);
  //   safeCallback();
  // };

  // const param = { data: fav };
  // const fn = (dispatch, getState) => {
  //   const userFavourites = getState().userReducer.favourites;
  //   if (userFavourites) {
  //     const exists = userFavourites.find((f) => f === fav);
  //     let updatedFavs = [];

  //     if (exists) {
  //       console.log('calling remove api...');
  //       callPutApi(
  //         `${process.env.REACT_APP_ENDPOINT_URL}/removeUserFav`,
  //         param,
  //         (data) => callback(data, dispatch, getState(), onSuccessCallback),
  //         callbackError
  //       );
  //       updatedFavs = userFavourites.filter((f) => f !== fav);
  //     } else {
  //       console.log('calling add api...');
  //       callPutApi(
  //         `${process.env.REACT_APP_ENDPOINT_URL}/addUserFav`,
  //         param,
  //         (data) => callback(data, dispatch, getState(), onSuccessCallback),
  //         callbackError
  //       );
  //       updatedFavs = userFavourites;
  //       updatedFavs.push(fav);
  //     }

  //     dispatch({
  //       type: UPDATE_USER_FAVS,
  //       payload: {
  //         favourites: updatedFavs,
  //       },
  //     });
  //   }
  // };

  // store.dispatch(fn);
};

export const setLoadingCompanies = (value) => {
  const fn = (dispatch, getState) => {


    dispatch({
      type: SET_MYCOMPANIES_LOADING,
      payload: {
        loadingCompanies: value
      }
    })


  };

  store.dispatch(fn);
}

const mergeCompanies = (localCompanies, remoteCompanies, slugs) => {

  const retValue = [];

  slugs.forEach(slug => {

    let company = remoteCompanies.find(rc => rc.slug === slug);
    if (!company) {
      company = localCompanies.find(lc => lc.slug === slug);
    }

    if (company) {
      retValue.push(company);
    }

  })

  return retValue;

  // localCompanies.forEach( lc => {

  //   if (!remoteCompanies.find( rc => rc.slug === lc.slug)) {
  //     remoteCompanies.push(lc);
  //   }
  // })

  // return remoteCompanies.filter( rc => slugs.find( ss => ss === rc.slug));
}



const reverseMergeCompanies = (localCompanies, remoteCompanies, slugs) => {

  const retValue = [];
  localCompanies.forEach(lc => {

    if (slugs.find(rc => rc.slug === lc.slug)) {
      retValue.push(lc);
    }
  })

  return retValue;
}

export const addUserFav = async (fav, onSuccessCallback) => {
  if (!fav) {
    throw new Error('Fav cannot be empty.');
  }


  try {

    const fn = async (dispatch, getState) => {

      const slugs = getCurrentSetSlugs(getState().userReducer);
      if (!slugs.includes(fav)) {
        slugs.push(fav);
        await loadSetPageInfo(slugs, null);
      }
    }

    await store.dispatch(fn);

  } catch (e) {
    console.log(e);
  }


  //   const callbackError = (data) => {
  //     console.log('Error');
  //   };

  //   const safeCallback = () => {
  //     if (onSuccessCallback) {
  //       onSuccessCallback();
  //     }
  //   };

  //   const callback2 = (data, dispatch, state) => {

  //     console.log(data);
  //     const remoteCompanies = data.myCompanies ? data.myCompanies : [];
  //     const userSets = data.myCompanies ? data.userSets : [];

  //     dispatch({
  //       type: SET_MYCOMPANIES,
  //       payload: {
  //         myCompanies: mergeCompanies(state.userReducer.myCompanies, remoteCompanies, getCurrentSetSlugs(state.userReducer))
  //       }
  //     })

  //     // dispatch({
  //     //   type: SET_USERSETS,
  //     //   payload: {
  //     //     userSets: userSets
  //     //   }
  //     // })

  //     setLoadingCompanies(false);

  //     safeCallback();

  //   }

  //   // const param = { data: fav };
  //   const fn = (dispatch, getState) => {

  //     let currentSetId = getState().userReducer.currentSetId;

  //     const param = {
  //       data: fav,
  //       slug: fav,
  //       setId: currentSetId
  //     };

  //     const myCompanies = getState().userReducer.myCompanies;
  //     const companyList = getState().userReducer.companyList;

  //     if (!myCompanies.find(c => c.slug === fav)) {
  //       const company = companyList.find(c => c.slug === fav);
  //       myCompanies.push(company);
  //     }

  //     dispatch({
  //       type: SET_MYCOMPANIES,
  //       payload: {
  //         myCompanies: myCompanies
  //       }
  //     })

  //     const userSets = getState().userReducer.sets;

  //     if (!currentSetId && userSets && userSets.length > 0) {
  //       currentSetId = userSets[0].setId;
  //     }

  //     if (userSets && currentSetId) {
  //       const currentSetIndex = userSets.findIndex(set => set.setId === currentSetId);

  //       if (currentSetIndex >= 0) {
  //         console.log("Adding locally!");
  //         userSets[currentSetIndex].slugs.push(fav);
  //       }

  //       dispatch({
  //         type: SET_USERSETS,
  //         payload: {
  //           userSets: userSets
  //         }
  //       })
  //     }

  //     // setLoadingCompanies(true);
  // callPutApi(
  //       `${process.env.REACT_APP_ENDPOINT_URL}/addUserFav`,
  //       param,
  //       (data) => callback2(data, dispatch, getState()),
  //       () => { setLoadingCompanies(false); console.log("ERROR!"); }
  //     );
  //   };

  //   store.dispatch(fn);
};

export const addUserSet = async (setName, slugs, publicFlag, onSuccessCallback) => {
  if (!setName) {
    throw new Error('setName cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };
  setLoadingCompanies(true);

  const callback2 = (data, dispatch) => {

    console.log(data);
    // const myCompanies = data.myCompanies ? data.myCompanies : [];


    dispatch({
      type: SET_USERSETS,
      payload: {
        userSets: data.userSets
      }
    })

    const newSet = data.userSets ? data.userSets.find(userSet => userSet.name === setName) : "";

    if (newSet) {

      console.log(newSet);
      store.dispatch({
        type: SET_CURRENT_SET,
        payload: { setId: newSet.setId, slugs }
      });

      dispatch({
        type: LOAD_SET,
        payload: {
          plans: [],
          attributes: [],
          versions: [],
          recos: [],
          dismissedRecos: [],
          name: newSet.name,
          setId: newSet.setId
        }
      })

      // dispatch({
      //   type: SET_MYCOMPANIES,
      //   payload: {
      //     myCompanies: data.myCompanies
      //   }
      // })
    }

    setLoadingCompanies(false);

    if (onSuccessCallback) onSuccessCallback(newSet.setId);
  }

  const fn = (dispatch, getState) => {

    const workspaceId = publicFlag ? getState().userReducer.workspaceId : null;

    const param = { data: setName, slugs, workspaceId, publicFlag };

    // const mySets = getState().userReducer.mySets;
    // mySets.push(setName);

    // dispatch({
    //   type: SET_USERSETS,
    //   payload: {
    //     myCompanies: myCompanies
    //   }
    // })

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/createUserSet`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); setLoadingCompanies(false); }
    );
  };

  store.dispatch(fn);
};

export const updateUserSet = async (setName, onSuccessCallback) => {
  if (!setName) {
    throw new Error('setName cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log("FDSJSDFIOSDJOISDIJF");
    console.log(data);
    // const myCompanies = data.myCompanies ? data.myCompanies : [];

    // dispatch({
    //   type: SET_MYCOMPANIES,
    //   payload: {
    //     myCompanies: myCompanies
    //   }
    // })

    // setLoadingCompanies(false);

    safeCallback();

  }

  const param = { data: setName };
  const fn = (dispatch, getState) => {

    // const mySets = getState().userReducer.mySets;
    // mySets.push(setName);

    // dispatch({
    //   type: SET_USERSETS,
    //   payload: {
    //     myCompanies: myCompanies
    //   }
    // })

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); }
    );
  };

  store.dispatch(fn);
};

export const renameUserSet = async (setId, ownerId, newName, onSuccessCallback) => {
  if (!setId) {
    throw new Error('setId cannot be empty.');
  }

  if (!newName) {
    throw new Error('setName cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);

    dispatch({
      type: SET_USERSETS,
      payload: {
        userSets: data.userSets
      }
    })

    safeCallback();

  }

  const param = {
    setId: setId,
    ownerId,
    key: "name",
    value: newName
  };
  const fn = (dispatch, getState) => {

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); }
    );
  };

  store.dispatch(fn);
};

export const updateExcludeUserSet = async (setId, ownerId, exclude, onSuccessCallback) => {
  if (!setId) {
    throw new Error('setId cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);

    dispatch({
      type: SET_USERSETS,
      payload: {
        userSets: data.userSets
      }
    })

    safeCallback();

  }

  const param = {
    setId: setId,
    ownerId,
    key: "exclude",
    value: exclude
  };
  const fn = (dispatch, getState) => {

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); }
    );
  };

  store.dispatch(fn);
};

export const updateVisibilityeUserSet = async (setId, ownerId, publicFlag, onSuccessCallback) => {
  if (!setId) {
    throw new Error('setId cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);

    dispatch({
      type: SET_USERSETS,
      payload: {
        userSets: data.userSets
      }
    })

    safeCallback();

  }

  const param = {
    setId: setId,
    ownerId: ownerId,
    key: 'public',
    value: publicFlag ? "on" : "off",
  };
  const fn = (dispatch, getState) => {

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); }
    );
  };

  store.dispatch(fn);
};

function getCurrentSet(userReducer) {

  if (!userReducer || !userReducer.sets || !userReducer.currentSetId) return null;

  const currentSetId = userReducer.currentSetId;
  const currentSet = userReducer.sets.find(set => set.setId === currentSetId);

  return currentSet;
}


export const dismissRecommendedCompany = async (slug) => {

  if (!slug) {
    console.log("Called dismissRecommendedCompany. No slug specified, dimissing all recos");
  } else {
    console.log("Called dismissRecommendedCompany. Dismissing", slug);
  }

  const callback2 = (data, dispatch) => {
    console.log(data);
  }

  const currentSetIds = getCurrentSetIds(store.getState().userReducer);
  const currentSet = (store.getState().userReducer.currentSet);

  if (currentSet) {

    let dimissedSlugs = currentSet.dismissedRecos ? currentSet.dismissedRecos : [];

    if (slug && !dimissedSlugs.includes(slug)) {

      dimissedSlugs.push(slug);
    } else {
      dimissedSlugs = currentSet.recos ? currentSet.recos.map(r => r.slug) : [];
    }

    const param = {
      setId: currentSetIds.setId,
      ownerId: currentSetIds.ownerId,
      key: 'dismissedRecos',
      value: dimissedSlugs,
    };

    const fn = (dispatch, getState) => {

      const currentSet = (getState().userReducer.currentSet);
      const currentSetIds = getCurrentSetIds(getState().userReducer);
      let currentDimssed = [];

      if (currentSet) {

        if (slug) {
          currentDimssed = currentSet.dismissedRecos ? currentSet.dismissedRecos : [];

          if (!currentDimssed.includes(slug)) {
            currentDimssed.push(slug);
          }
        } else if (currentSet.recos) {
          currentSet.recos.forEach(r => {
            if (!currentDimssed.includes(r.slug)) {
              currentDimssed.push(r.slug);
            }
          });
        }
      }

      dispatch({
        type: DISMISS_RECO,
        payload: {
          dismissedRecos: currentDimssed,
          setId: currentSetIds.setId,
          ownerId: currentSetIds.ownerId
        }
      });

      callPutApi(
        `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
        param,
        (data) => callback2(data, dispatch),
        () => { console.log("ERROR!"); }
      );
    };

    store.dispatch(fn);
  }
};

export const updateUserEmail = (email) => {


  const fn = (dispatch, getState) => {


    dispatch({
      type: SET_USER_EMAIL,
      payload: {
        email
      }
    })

    console.log("updateUserEmail");
    console.log(email);

    const param = { key: "email", value: email };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserAttribute`,
      param,
      (data) => { console.log("successfully updated user email", data); }, (err) => { console.log(err); }
    );
  };

  store.dispatch(fn);
};

export const removeUserSet = async (setId, ownerId, onSuccessCallback) => {
  if (!setId) {
    throw new Error('setId cannot be empty.');
  }

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);

    dispatch({
      type: SET_USERSETS,
      payload: {
        userSets: data.userSets
      }
    })

    if (data.userSets && data.userSets.length > 0) {
      // console.log(data.userSets[0])
      setCurrentSet(data.userSets[0].setId);
    }

    safeCallback();

  }

  const param = {
    setId: setId,
    ownerId,
    key: "inactive",
    value: true
  };
  const fn = (dispatch, getState) => {

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/updateUserSetAttribute`,
      param,
      (data) => callback2(data, dispatch),
      () => { console.log("ERROR!"); }
    );
  };

  store.dispatch(fn);
};

function parseFav(urlParam) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  let url = urlParam.replace("www.", "");
  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname.slice(0, hostname.indexOf("."));
}

export const generateReport = async (slugs) => {

  console.log("generateReport", slugs);
  if (!slugs || slugs.length <= 0) {
    throw new Error('slugs cannot be empty.');
  }

  const param = { data: slugs, type: "tiers" };

  const retValue = await new Promise((res, rej) => {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateReport`,
      param,
      (data) => res(data),
      rej
    );
  })

  console.log(retValue.data);

  return retValue.data;
};


export const generateCompaniesCsvReport = async (slugs) => {

  console.log("generateReport", slugs);
  if (!slugs || slugs.length <= 0) {
    throw new Error('slugs cannot be empty.');
  }

  const param = { data: slugs, type: "company_csv" };

  const retValue = await new Promise((res, rej) => {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateReport`,
      param,
      (data) => res(data),
      rej
    );
  })

  console.log(retValue.csv);

  return retValue.csv;


};



export const generateHistoryCsvReport = async (slugs) => {

  console.log("generateReport", slugs);
  if (!slugs || slugs.length <= 0) {
    throw new Error('slugs cannot be empty.');
  }

  const param = { data: slugs, type: "history_csv" };

  const retValue = await new Promise((res, rej) => {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateReport`,
      param,
      (data) => res(data),
      rej
    );
  })

  console.log(retValue.csv);

  return retValue.csv;

};


export const generatePlansCsvReport = async (slugs) => {

  console.log("generateReport", slugs);
  if (!slugs || slugs.length <= 0) {
    throw new Error('slugs cannot be empty.');
  }

  const param = { data: slugs, type: "plans_csv" };

  const retValue = await new Promise((res, rej) => {
    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/generateReport`,
      param,
      (data) => res(data),
      rej
    );
  })

  // console.log(retValue.csv);

  return retValue.csv;

};

export const dismissUserRequest = async (timestamp) => {

  console.log("dismissUserRequest");
  if (!timestamp) {
    throw new Error('timestamp cannot be empty.');
  }

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    // if (onSuccessCallback) {
    //   onSuccessCallback();
    // }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);
    const requests = data.userRequests ? data.userRequests : [];

    dispatch({
      type: SET_REQUESTS,
      payload: {
        requests
      }
    })
    safeCallback();
  }

  const fn = (dispatch, getState) => {

    if (getState().userReducer.requests) {
      const currentRequests = getState().userReducer.requests;
      dispatch({
        type: SET_REQUESTS,
        payload: {
          requests: currentRequests.filter(r => r.timestamp !== timestamp)
        }
      })
    }

    const param =
    {
      action: 'dismiss',
      timestamp
    };


    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/submitUserFav`,
      param,
      (data) => callback2(data, dispatch),
      callbackError
    );
  };

  store.dispatch(fn);
}


export const submitUserFav = async (favUrl, onSuccessCallback, onFailureCallback) => {

  console.log("submitUserFav");
  if (!favUrl) {
    throw new Error('Fav cannot be empty.');
  }

  const callbackError = (data) => {
    console.log('Error');
    if (onFailureCallback) {
      onFailureCallback(data);
    }
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const callback2 = (data, dispatch) => {

    console.log(data);

    const requests = data.userRequests ? data.userRequests : [];

    dispatch({
      type: SET_REQUESTS,
      payload: {
        requests
      }
    })
    // dispatch({
    //   type: SET_MYCOMPANIES,
    //   payload: {
    //     myCompanies : myCompanies
    //   }
    // })
    safeCallback();
  }



  const fn = (dispatch, getState) => {

    const fav = parseFav(favUrl);
    const favourites = getState().userReducer.favourites ? getState().userReducer.favourites : [];
    favourites.push(fav);

    dispatch({
      type: SET_MYCOMPANIES,
      payload: {
        favourites
      }
    });

    const currentSetId = getState().userReducer.currentSetId;
    const userSets = getState().userReducer.sets;

    if (userSets && currentSetId) {
      const currentSetIndex = userSets.findIndex(set => set.setId === currentSetId);

      if (currentSetIndex >= 0) {
        console.log("Filtering locally!");
        userSets[currentSetIndex].slugs.push(fav);

        dispatch({
          type: SET_USERSETS,
          payload: {
            userSets: userSets
          }
        })
      }
    }

    const param =
    {
      url: favUrl,
      setId: currentSetId
    };


    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/submitUserFav`,
      param,
      (data) => callback2(data, dispatch),
      callbackError
    );
  };

  store.dispatch(fn);
};


export const removeUserFav = async (fav, onSuccessCallback) => {

  if (!fav) {
    throw new Error('Fav cannot be empty.');
  }

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };



  const callback2 = (data, dispatch, state) => {

    console.log(data);
    const remoteCompanies = data.myCompanies ? data.myCompanies : [];
    const userSets = data.myCompanies ? data.userSets : [];
    const favourites = data.favourites ? data.favourites : [];

    // dispatch({
    //   type: SET_MYCOMPANIES,
    //   payload: {
    //     myCompanies : myCompanies,
    //     favourites : favourites
    //   }
    // })


    loadSetPageInfo();


    dispatch({
      type: SET_MYCOMPANIES,
      payload: {
        myCompanies: mergeCompanies(state.userReducer.myCompanies, remoteCompanies, getCurrentSetSlugs(state.userReducer))
      }
    })

    // dispatch({
    //   type: SET_USERSETS,
    //   payload: {
    //     userSets: userSets
    //   }
    // })
    safeCallback();

  }

  const fn = (dispatch, getState) => {

    const currentSetId = getState().userReducer.currentSetId;

    const param = {
      data: fav,
      slug: fav,
      setId: currentSetId
    };

    const myCompanies = getState().userReducer.myCompanies;
    const favourites = getState().userReducer.favourites;
    const userSets = getState().userReducer.sets;

    if (userSets && currentSetId) {
      const currentSetIndex = userSets.findIndex(set => set.setId === currentSetId);

      if (currentSetIndex >= 0) {
        console.log("Filtering locally!");
        userSets[currentSetIndex].slugs = userSets[currentSetIndex].slugs.filter(slug => slug !== fav);
      }
    }

    if (myCompanies) {
      dispatch({
        type: SET_MYCOMPANIES,
        payload: {
          myCompanies: myCompanies.filter(c => c.slug !== fav),
          favourites: favourites.filter(c => c !== fav)
        }
      })
    }


    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/removeUserFav`,
      param,
      //(data) => callback(data, dispatch, getState(), onSuccessCallback),
      (data) => callback2(data, dispatch, getState()),
      callbackError
    );
  };

  store.dispatch(fn);
}

export const getCheckoutUrl = async (onSuccessCallback) => {

  console.log('calling getCheckoutUrl');

  if (!onSuccessCallback) {
    throw new Error("onSuccessCallback cannot be empty.");
  }

  const callbackError = (data) => {
    console.log("Error");
  };

  const fn = (dispatch, getState) => {
    callGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/getCheckout`, (data) => onSuccessCallback(data.data), callbackError);
  };

  store.dispatch(fn);
}


export const setCategories = (categoriesList) => {
  const categories = [];
  categories.push(...categoriesList);

  return ({
    type: SET_CATEGORIES,
    payload: {
      categories
    }
  });
};
