/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from 'react';
import CompanySelector from '../CompanySelector/CompanySelector';
import ShareModal from '../../controls/shareModal';

const CompareComponent = (props) => {
  const [comparedCompanies, setComparedCompanies] = useState([]);

  const shareModal = props.shareModal;
  const handleShareModal = props.handleShareModal;
  const compareModal = props.compareModal;
  const handleCompareModal = props.handleCompareModal;
  const company = props.company;

  useEffect(() => {
    setComparedCompanies([company]);
  }, [company]);

  const searchCompanies = props.allCompanies.filter((company) => {
    return !comparedCompanies.includes(company);
  });

  function addNewCompanyToTable(selected) {
    setComparedCompanies((prevCompanies) => [...prevCompanies, selected]);
  }

  function removeCompany(index) {
    console.log(index);

    console.log('CLICKED REMOVE!!!');

    if (index > 0) {
      setComparedCompanies((prevCompanies) =>
        prevCompanies.filter((company, i) => {
          return i !== index;
        })
      );
    }

    console.log('FIRST ONE CANT BE DELETED!!!');
  }

  //   function removeLastCompany() {
  //     //TODO: remove last object from array of comparedCompanies
  //     setComparedCompanies(prevCompanies => prevCompanies.splice(0, comparedCompanies.length - 1))
  //   }

  return (
    <>
      <div className="w-full mt-0 mb-10">
        <button
          onClick={() => handleShareModal()}
          className="mb-4 font-bold flex items-center py-3 px-8 ml-auto
                    text-sm text-white border  focus:outline-none"
          style={{ background: '#008CFF' }}
        >
          Share Report
        </button>
        <div className="overflow-x-auto relative" bis_skin_checked="1">
          <table className="w-full text-sm text-left text-gray-900 dark:text-blue-100 divide-gray-300 border-b">
            <thead className="text-xs text-gray-900 uppercase bg-gray-50 dark:text-white border-b-2 ">
              <tr className="">
                <th scope="col" className="py-3 px-6 "></th>
                {comparedCompanies.map((comparedCompany, index) => (
                  <th
                    scope="col"
                    className="py-3 px-6 text-center"
                    key={comparedCompany.companyId}
                  >
                    <div className="flex justify-center">
                      <img
                        alt="logo"
                        className="h-8"
                        src={comparedCompany.logoUrl}
                      />
                      <span className=" ml-3 pt-2 ">
                        {comparedCompany.companyId}
                      </span>
                      {index > 0 ? (
                        <button
                          onClick={() => removeCompany(index)}
                          className="ml-6 bg-gray-300 hover:bg-gray-400 text-gray-900 font-bold
                                                py-2 px-4 justify-content text-center rounded-full text-l "
                          data-modal-toggle="defaultModal"
                        >
                          {' '}
                          -
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </th>
                ))}
                <th scope="col" className="py-3 px-6 text-center ">
                  <div className="">
                    <button
                      onClick={() => handleCompareModal()}
                      className="bg-gray-300 hover:bg-gray-400 text-gray-900 font-bold
                                            py-2 px-4 justify-content text-center rounded-full text-l"
                      data-modal-toggle="defaultModal"
                    >
                      {compareModal ? '-' : '+'}
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="hover:bg-blue-50 ">
                <th
                  scope="row"
                  className=" py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-blue-100"
                >
                  Value Metric
                </th>
                {comparedCompanies.map((comparedCompany) => {
                  return (
                    <td
                      className="py-4 px-6 text-center"
                      key={comparedCompany.companyId}
                    >
                      {comparedCompany.valueMetric}
                    </td>
                  );
                })}
              </tr>
              <tr className="hover:bg-blue-50 ">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-blue-100"
                >
                  Acquisition Model
                </th>
                {comparedCompanies.map((comparedCompany) => {
                  if (
                    !comparedCompany.freemium &&
                    !comparedCompany.freetrial &&
                    !comparedCompany.enterprisePlan
                  ) {
                    return (
                      <td
                        className="py-4 px-6 text-center"
                        key={comparedCompany.companyId}
                      >
                        --
                      </td>
                    );
                  } else {
                    return (
                      //small bug, if its more than one option, you have to add a space between them
                      <td
                        className="py-4 px-6 text-center leading-6"
                        key={comparedCompany.companyId}
                      >
                        {comparedCompany.freemium ? <div>Freemium</div> : ''}
                        {comparedCompany.freetrial ? <div>Free Trial</div> : ''}
                        {comparedCompany.enterprisePlan ? (
                          <div>Paid Plan</div>
                        ) : (
                          ''
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
              <tr className="hover:bg-blue-50 ">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-blue-100"
                >
                  Employees
                </th>
                {comparedCompanies.map((comparedCompany) => {
                  return (
                    <td
                      className="py-4 px-6 text-center"
                      key={comparedCompany.companyId}
                    >
                      {comparedCompany.employees}
                    </td>
                  );
                })}
              </tr>
              <tr className="hover:bg-blue-50">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-blue-100"
                >
                  Plan Names
                </th>
                {comparedCompanies.map((comparedCompany) => {
                  return (
                    <td
                      className="py-4 px-4 text-center"
                      key={comparedCompany.companyId}
                    >
                      {comparedCompany.planNames.length > 0
                        ? comparedCompany.planNames.map((plan, index) => {
                            return (
                              <ul className="inline">
                                <li className="" value={plan} key={index}>
                                  {plan}
                                </li>
                              </ul>
                            );
                          })
                        : '--'}
                    </td>
                  );
                })}
              </tr>
              <tr className="hover:bg-blue-50 ">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-blue-100 "
                >
                  Top Keywords
                </th>
                {comparedCompanies.map((comparedCompany) => {
                  return (
                    //no data for planNames
                    <td
                      className="py-4 px-6 text-center"
                      key={comparedCompany.companyId}
                    >
                      {company.keywords || '--'}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {compareModal ? (
        <CompanySelector
          allCompanies={searchCompanies}
          addNewCompanyToTable={addNewCompanyToTable}
          comparedCompanies={comparedCompanies}
          handleCompareModal={handleCompareModal}
          removeCompany={removeCompany}
        />
      ) : (
        ''
      )}

      {shareModal ? (
        <ShareModal
          comparedCompanies={comparedCompanies}
          handleShareModal={handleShareModal}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default CompareComponent;
