/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { getCheckoutUrl } from "../../redux/actionsUsers";

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/month' },
]


const tiers = [
  {
    name: 'Startup',
    id: 'tier-growth',
    href: { annually: '#', monthly: '#' },
    price: { annually: '$99', monthly: '$125' },
    description: 'Designed for growing PLG SaaS companies to monitor pricing trends and track competitors.',
    features: [
      "Automated Email Alerts",
      "Searchable Database of SaaS Pricing Pages",
      "Custom Plan Comparison",
      "Slack Support",
    ],
    featured: false,
    options: [
      {
        value: '10',
        price: { annually: '$99', monthly: '$125' },
      },
      {
        value: '20',
        price: { annually: '$199', monthly: '$250' },
      },
      {
        value: '30',
        price: { annually: '$299', monthly: '$375' },
      }
    ],
    cta: 'Start a 7-Day Trial',
  },
  {
    name: 'Scaleup',
    id: 'tier-growth',
    href: { annually: '#', monthly: '#' },
    price: { annually: '$99', monthly: '$125' },
    description: 'Designed for leading SaaS companies and pricing consultancies to monitor pricing trends across multiple products and clients.',
    features: [
      "Automated Email Alerts",
      "Searchable Database of SaaS Pricing Pages",
      "Custom Plan Comparison",
      "Slack Support",
      "Unlimited Company Sets",
      "Shared Workspace"
    ],
    featured: false,
    cta: 'Start a 7-Day Trial',
    options: [
      {
        value: '50',
        price: { annually: '$399', monthly: '$500' },
      },
      {
        value: '100',
        price: { annually: '$799', monthly: '$1000' },
      }
    ]
  }


]

const getStripeLink = (tierIndex, optionValue, frequency) => {

  const tier = tiers[tierIndex];
  const option = tier.options.find(o => o.value === optionValue);
  const url = frequency === 'annually' ? option.hrefs.annually : option.hrefs.monthly;

  return url;
}

const getPrice = (tierNum, optionValue, frequency) => {

  const tier = tiers[tierNum];
  const option = tier.options.find(o => o.value === optionValue);
  const price = option.price[frequency];
  return price;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const getDropdown = (options, current, handler) => {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {current} pages
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) =>
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <a
                    onClick={() => handler(option.value)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {option.value} pages
                  </a>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

// TODO: add API that sets local userSettings upon render
const Checkout = () => {
  const [checkoutLinks, setLinks] = useState(null);
  const [frequency, setFrequency] = useState(frequencies[1])
  const [startupPages, setStartupPages] = useState(tiers[0].options[0].value);
  const [scaleupPages, setScaleupPages] = useState(tiers[1].options[0].value);

  tiers[0].currentValue = startupPages;
  tiers[0].handler = setStartupPages;

  tiers[1].currentValue = scaleupPages;
  tiers[1].handler = setScaleupPages;


  const callback = (url) => {
    console.log(url);
    setLinks(url);
  }

  if (!checkoutLinks) {
    getCheckoutUrl(callback);
    return (<>
      <div className="flex h-screen ">
        <div className="m-auto">
          <h3>Loading...</h3>
        </div>
      </div>
    </>);
  }

  // tiers[0].href['monthly'] = "#"; // checkoutLinks.find(cl => cl.name === "Starter" && cl.frequency === "Monthly").link;
  // tiers[0].href['annually'] = "#"; // checkoutLinks.find(cl => cl.name === "Starter" && cl.frequency === "Yearly").link;
  // // tiers[1].href['monthly'] = checkoutLinks.find(cl => cl.name === "Starter" && cl.frequency === "Monthly").link;
  // tiers[1].href['annually'] = checkoutLinks.find(cl => cl.name === "Starter" && cl.frequency === "Yearly").link;
  // tiers[0].href['monthly'] = checkoutLinks.find(cl => cl.name === "Business" && cl.frequency === "Monthly").link;
  // tiers[0].href['annually'] = checkoutLinks.find(cl => cl.name === "Business" && cl.frequency === "Yearly").link;

  // tiers[3].href['monthly'] =
  // tiers[3].href['annually'] = checkoutLinks.find(cl => cl.name === "Business" && cl.frequency === "Yearly").link;

  // const eventTypes = [...new Set(pricingEvents.map())];

  // assign the links to the tiers


  for (let j = 0; j < tiers.length; j++) {
    const tier = tiers[j];
    const tierOptions = tier.options;
    if (tierOptions) {

      for (let i = 0; i < tierOptions.length; i++) {
        const option = tierOptions[i];
        const planName = `${tier.name} ${option.value}`;
        const monthlyOption = checkoutLinks.find(cl => cl.name === planName && cl.frequency === "Monthly");
        const yearlyOption = checkoutLinks.find(cl => cl.name === planName && cl.frequency === "Yearly");
        if (monthlyOption && yearlyOption) {
          option.hrefs = { monthly: monthlyOption.link, annually: yearlyOption.link };
        } else {
          console.log('problem!', planName)
        }


        // const monthlyLink = checkoutLinks.find(cl => cl.name === planName && cl.frequency === "Monthly").link;
        // const yearlyLink = checkoutLinks.find(cl => cl.name === planName && cl.frequency === "Yearly").link;
        // option.href = { monthly: monthlyLink, annually: yearlyLink };
      }

    }
  }


  console.log(tiers);
  return (
    <div className="bg-white pb-24 sm:pb-32">

      <h1 className='font-bold text-2xl  flex flex-row items-center justify-between'>
        <a href="/set"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-9' alt="Logo"></img></a>
      </h1>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Effortless SaaS Pricing Intelligence.
          </p>
        </div>

        <div class="mx-auto max-w-4xl text-center">
          <p class="mt-6 text-xl font-normal tracking-tight text-gray-900 sm:text-xl mb-6">7-day free trial. Simple, scalable pricing.</p>
        </div>


        {/* <div className="mx-auto max-w-5xl text-center mt-3">
          <p className="mt-2 text-base font-medium tracking-tight text-gray-900 sm:text-2xl">
            Get in your competitor’s head
          </p>
        </div> */}

        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1  p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-custom-blue text-white' : 'text-gray-500',
                    'cursor-pointer  px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:px-24 ">
          {tiers.map((tier, tierIndex) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200 text-normal',
                ' p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6 h-24')}>
                {tier.description}
              </p>
              {getDropdown(tier.options, tier.currentValue, tier.handler)}
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-4xl font-bold tracking-tight'
                  )}
                >

                  {getPrice(tierIndex, tier.currentValue, frequency.value)}
                </span>
                {typeof tier.price !== 'string' ? (
                  <span
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {tier.price.annually !== "Custom" && frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <a
                href={getStripeLink(tierIndex, tier.currentValue, frequency.value)}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-gray-700 text-white hover:bg-white/20 focus-visible:outline-white'
                    :
                    tier.cta !== "Current Plan" ? 'bg-custom-blue text-white shadow-sm hover:bg-custom-blue focus-visible:outline-blue-600' :
                      'bg-white text-gray-700 outline outline-1 hover:bg-white/20 focus-visible:outline-white',
                  'mt-6 block  py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                {tier.cta}
              </a>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-1">
                    <CheckIcon
                      className={classNames(tier.featured ? 'text-white' : 'text-blue-600', 'h-6 w-5 flex-none')}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default Checkout;