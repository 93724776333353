/* eslint-disable no-unused-vars */
import Selection from './Selection';
import React, { useState, useEffect } from 'react';
import { FILTERTYPE } from '../../redux/actionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { setFavFilterAttributes, resetFavFilter } from '../../redux/actionsFilters';
import makeAnimated from 'react-select/animated';
import { personaOptions, personaOptionToLabel, getUserPlan } from '../../helpers/common';
import { useAuth0 } from '@auth0/auth0-react';
import { removeUserFav, setUserCategories, addUserFav, submitUserFav, refresh } from '../../redux/actionsUsers';
import Autosuggest from 'react-autosuggest';
import AddCompanyModal from "../../controls/addCompanyModal";

const animeatedComponents = makeAnimated();
const options = personaOptions;
const languages = [
    {
      name: 'Company A',
      year: 1972
    },
    {
      name: 'Elm',
      year: 2012
    }
  ];

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value, companies) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : companies.filter(company =>
        company.slug.toLowerCase().slice(0, inputLength) === inputValue
    ).slice(0, 10);
  };


    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = suggestion => { return suggestion.slug; }

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => {
        return (
            <div className='flex flex-inline gap-x-1 align-middle	'>
                 <img className='h-9 w-9' src={suggestion.logoUrl}></img>{suggestion.slug}
            </div>
        )};

// TODO: add API that sets local userSettings upon render
const Profile = () => {

    const userReducer = useSelector(state => state.userReducer);
    const entitlement = useSelector((state) => state.userReducer ? state.userReducer.entitlement : '');
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [addLogoUrl, setAddLogoUrl] = useState("/icons/glass.png");
    const { logout, user } = useAuth0();

    if (!userReducer || !userReducer.companyList) {return <></>;}

    const myCompanies = userReducer.myCompanies ? userReducer.myCompanies.sort((a,b) => a && a.slug && b && b.slug ? a.slug.localeCompare(b.slug) : false) : [];
    const companies = userReducer.companyList.filter( c => !myCompanies.find( mC => mC.slug === c.slug));//.map(c => c.slug);


    const getMyCompaniesLoading = () => {

        let retValue = [];
        if (userReducer && userReducer.favourites && Array.isArray(userReducer.favourites)) {

            const favs = userReducer.favourites;

            favs.forEach( fav => {

                // Only include not found
                if (!myCompanies.find(c => c.slug === fav)) {
                    retValue.push({
                        slug : fav,
                        lastChange : '--',
                        logoUrl : "/icons/loading.jpeg"
                    })
                }
            })
            // userReducer.myCompanies myCompanies
        }

        retValue.sort((a,b) => a && a.slug && b && b.slug ? a.slug.localeCompare(b.slug) : false);

        return retValue;
    }

    const myCompaniesLoading = getMyCompaniesLoading();

    const onChange = (event, { newValue }) => {
        // console.log(newValue)
        setValue(newValue)
        const company = userReducer.companyList.find( c => c.slug === newValue)
        const logoUrl =  company ? company.logoUrl : "/icons/glass.png";
        setAddLogoUrl(logoUrl);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, companies));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const addChangeHandler = (value) => {
        console.log(value);


    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Type a company name',
        value,
        onChange
      };

    function resetFilter() {
        resetFavFilter();
    }

    function addCompanyCheck() {

        console.log(value);

        if (companies.find(c => c.slug === value)) {
            console.log("Found it!");
            addUserFav(value);
            setValue("");
            setAddLogoUrl("/icons/glass.png");
        } else {
            setShowAddModal(true);
        }
    }


    // useEffect(() => {
    //     resetFilter();
    //     console.log(isEditing);
    // }, [isEditing]);

    return (
        <>
            <div>
                {/* <h1 className="text-left text-3xl mb-6">Profile</h1> */}
                {/* My Companies  */}
                <div className="w-full mb-3 p-4 bg-white border  sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex items-center justify-between mb-12">
                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">My Companies </h5>
                    </div>

                    <div className='flex flex-inline flex-row align-middle w-full gap-x-2'>
                        <img className='h-9 w-9' src={addLogoUrl} alt=""/>
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                        />
                        <div>
                            <button onClick={ () => addCompanyCheck() } className=" h-10 bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Add a Company</button>
                            {/* <button className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 ml-4">Generate Report</button> */}
                        </div>
                    </div>
                    <hr className='my-9'/>

                    <div className="mt-9 flow-root">



                        <ul role="list" className="-my-5 divide-y divide-gray-200">

                            {myCompanies.map(myCompany =>
                                <>
                                    <li className="py-2 cursor-pointer" >
                                        <div className="flex items-center space-x-4 p-2 hover:bg-gray-50">
                                            <div className="flex-shrink-0">
                                                <img className="h-12 w-12" src={myCompany.logoUrl} alt="" />

                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <p> {myCompany.slug}</p>
                                                <p className="truncate text-sm text-gray-500">Last change detected: {myCompany.lastChange}</p>

                                            </div>
                                            <div>
                                                <a onClick={() => removeUserFav(myCompany.slug) } className="inline-flex items-center  bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Remove</a>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            )}

                            {myCompaniesLoading.map(myCompany =>
                                <>
                                    <li className="py-2 cursor-pointer" >
                                        <div className="flex items-center space-x-4 p-2 hover:bg-gray-50">
                                            <div className="flex-shrink-0">
                                                <img className="w-12" src={myCompany.logoUrl} alt="" />

                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <p> {myCompany.slug}</p>
                                                <p className="truncate text-sm text-gray-500">Last change detected: {myCompany.lastChange}</p>

                                            </div>
                                            <div>
                                                <a onClick={() => removeUserFav(myCompany.slug) } className="inline-flex items-center  bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Remove</a>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            )}
                        </ul>

                    </div>
                </div>


                 <AddCompanyModal show={showAddModal} companies={[] } filteredCompanies={[] } closeHandler={() => { setShowAddModal(false); setValue(''); }} defaultValue={value}  />

                <div className='flex flex-reverse-row float-right'>
                    <button
                        className='text-sm font-medium leading-none my-9 text-blue-900 mx-12'
                        onClick={() =>
                            refresh()
                        }
                    >
                        Refresh
                    </button>

                </div>
                {/*
                <div className="w-full max-w-3xl p-4 bg-white border   sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex items-center justify-between mb-4">
                    <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Default Filter</h5>
                    <div>
                    <button className={`text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 ${isEditing ? 'disabled:text-slate-500': ''}`}
                            onClick={()=> setEditing(prevState => !prevState)}
                            >
                        {isEditing ? 'Cancel': 'Edit'}
                    </button>
                    <button className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 ml-4"
                            onClick={()=> handleSubmit()}
                            >
                        Save
                    </button>

                    </div>

                    </div>
                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            {isEditing ? (
                                <>
                                    <Selection title={"Username"} img={"/icons/user.png"} value={userSettings.userName}>
                                        <div className="">
                                        <input
                                            className="text-left text-base text-gray-900 dark:text-white border p-2 w-48"
                                            type="text"
                                            value={localUserName}
                                            onChange={(e) => setLocalUserName(e.target.value)}/>
                                        </div>
                                    </Selection>
                                    <Selection title={"Company Name"} img={"/icons/app-development.png"} value={userSettings.localSizes}>
                                        <div className="">
                                        <input
                                            className="text-left text-base text-gray-900 dark:text-white border p-2 w-48"
                                            type="text"
                                            value={localCompanyName}
                                            onChange={(e) => setLocalCompanyName(e.target.value)}/>
                                        </div>
                                    </Selection>
                                    <Selection title={"Company Sizes"} img={"/icons/building.png"} value={userSettings.localSizes}>
                                        <CompanySizeSelect filter={filter} localSizes={localSizes} styles={'w-48'}/>
                                    </Selection>
                                    <Selection title={"Categories"} img={"/icons/development.png"} value={userSettings.localSizes}>
                                        <CategorySelector
                                        filter={filter}
                                        localCategories={localCategories}
                                        setLocalCategories={setLocalCategories}
                                        styles={userSettings.categories ? 'w-90': 'w-48'}
                                        />
                                    </Selection>

                                </>
                            ) : (
                                <>
                                    <Selection title={"Username"} img={"/icons/user.png"}>
                                        <p>{userSettings.userName}</p>
                                    </Selection>
                                    <Selection title={"Company Name"} img={"/icons/app-development.png"}>
                                        <p>{userSettings.companyName}</p>
                                    </Selection>
                                    <Selection title={"Company Sizes"} img={"/icons/building.png"}>
                                        <CompanySize userSettings={userSettings.localSizes} />
                                    </Selection>
                                    <Selection title={"Categories"} img={"/icons/development.png"}>
                                        {userSettings.categories ?
                                           userSettings.categories.map(category => (
                                            <div className="text-center ml-4 mt-0 bg-blue-500 hover:bg-blue-700 text-white
                                                            font-bold px-3.5 py-1.5 rounded-full text-sm cursor-pointer">
                                                {category.value}
                                            </div>
                                        )): ''}
                                    </Selection>

                                    </>
                            )}
                        </ul>
                    </div>
                </div> */}
            </div>
        </>
    );
};


export default Profile;