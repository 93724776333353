import React from "react";
import { IssueControl } from "../../controls/issuesControl";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { formatDate }  from "../../redux/selector";

const fullComponents = (company, hideFull) => {
  if (hideFull) {
    return <></>;
  }

  if (hideFull) { return <></>}

  return <>

            <div className="flex flex-row w-96 my-2 py-6 items-center text-2xl bg-gray-100 mr-6">
              <ArrowUpRightIcon className="cursor-pointer block h-12 w-12 ml-3 mr-3 my-auto " />
              <div>{company.valueMetirc ? company.valueMetirc : '--'} </div>
            </div>
          </>
}

const OverviewComponent = (props) => {
  const company = props.company;
  const issuesModal = props.issuesModal;
  const handleIssuesModal = props.handleIssuesModal;
  const hideFull =  props.hideFull;
  return (
    <>
      <div className="w-full ">
         <div>
                <button onClick={() => handleIssuesModal()}
                  className="text-sm text-gray-500 float-right"
                >Report an Issue</button>
              </div>
              <div className="my-3 text-sm">Last Updated: {formatDate(company.lastModified)} </div>

              <div>
                {issuesModal ?
                <IssueControl show = {issuesModal} closeHandler = {() => handleIssuesModal()}/> : ''
                }
              </div>
             <div className="flex flex-row mt-3 w-full   content-center flex-wrap ">

              <div className="my-3 text-lg text-gray-900 w-full">
                {company.description}
              </div>
                  <div className="flex flex-row w-96 my-2 py-6 items-center text-2xl bg-gray-100 mr-6">
                    <RectangleGroupIcon className="cursor-pointer block h-12 w-12 ml-3 mr-3 my-auto " />
                    <div>{!company.category ? '--' : company.category}</div>
                  </div>
                  <div className="flex flex-row w-96 my-2 py-6 items-center text-2xl bg-gray-100 mr-6">
                    <ArrowRightCircleIcon title="Acquisition Model" className="cursor-pointer block h-12 w-12 ml-3 mr-3 my-auto " />
                    {/* <div>{selectAcquisitionModel(company)}</div> */}
                    <div>{ company.acquisitionModel === 'Unkonwn' ? '--' : company.acquisitionModel}</div>
                  </div>
                  <div className="flex flex-row w-96 my-2 py-6 items-center text-2xl bg-gray-100 mr-6">
                    <UserGroupIcon className="cursor-pointer block h-12 w-12 ml-3 mr-3 my-auto " />
                    <div>{!company.employees ? '--' : company.employees} Employees</div>
                  </div>
                  <div className="flex flex-row w-96 my-2 py-6 items-center text-2xl bg-gray-100 mr-6">
                    <ArrowUpRightIcon className="cursor-pointer block h-12 w-12 ml-3 mr-3 my-auto " />
                    <div>{company.pricingMetric ? company.pricingMetric : '--'} </div>
                  </div>
                  {/* {fullComponents(company, hideFull)} */}
             </div>
              <div className="flex flex-row my-3">
                <ArrowTopRightOnSquareIcon className="h-6 mr-3"></ArrowTopRightOnSquareIcon>
                <a href={company.pricingPageUrl} target="_blank" className="text-m text-blue-500 ">Pricing Page Url</a>
              </div>
            </div>

    </>
  );
};

export default OverviewComponent;
