/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { versionToDate } from "../../helpers/common.js";
import { getSortedHistory, getCurrentSetCompanies } from '../../helpers/common';
import HistoryDetailsControl from './HistoryDetailsControl';
import SlugFilter from './SlugFilter.jsx';
import { generateHistoryCsvReport } from '../../redux/actionsUsers';
import { getCurrentSetSlugs, downloadCSV } from '../../helpers/common';



export const VIEW = { LIST: 'list', DETAILS: 'details' };

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// TODO: add API that sets local userSettings upon render
const SetHistory = ({ companiesFilter, setCompaniesFilter, view, setView }) => {

    // const [view, setView] = useState(VIEW.LIST);
    const [currentDiff, setCurrentDiff] = useState(null);
    const userReducer = useSelector(state => state.userReducer);

    if (!userReducer || !userReducer.companyList) { return <></>; }

    const currentSlugs = getCurrentSetSlugs(userReducer, true);
    let myCompanies = getCurrentSetCompanies(userReducer);// userReducer && userReducer.currentSet && userReducer.currentSet.attributes ? userReducer.currentSet.attributes : [];


    const getDiffLogo = (diff) => {

        const slug = diff.slug;
        const company = myCompanies.find(c => c.slug === slug);
        return company.logoUrl;

    }
    const pricingpageSort = ((a, b) => {
        return (a.slug && b.slug) ? a.slug.localeCompare(b.slug) : [];
    });

    myCompanies.sort(pricingpageSort);

    if (userReducer.loadingCompanies) {
        {
            return <>
                <div className="flex  ">
                    <div className="m-auto">
                        <div className="text-gray-700 my-32">Loading companies... ⌛</div>
                    </div>
                </div></>
        }
    }

    if (myCompanies.length === 0) {
        return <>
            <div className="flex  ">
                <div className="m-auto">
                    <div className="text-gray-700 my-32">Start by <a className='underline cursor-pointer' href='/set'>adding</a> some companies...</div>
                </div>
            </div></>
    }

    if (!userReducer || !userReducer.currentSet) {
        return <></>
    }

    const versions = userReducer.currentSet.versions;

    const diffs = getSortedHistory(versions, companiesFilter);

    if (diffs.length > 0 && !currentDiff) {
        setCurrentDiff(diffs[0]);
    };

    const callDownloadCSV = async () => {
        const slugs = getCurrentSetSlugs(userReducer, companiesFilter);
        const csvData = await generateHistoryCsvReport(slugs)
        downloadCSV(csvData, 'history.csv');
    }

    let slugVersions = currentDiff ? diffs.filter(d => d.slug === currentDiff.slug) : [];

    return (
        <>
            <div className="flex flex-row justify-between mt-12 mb-6">
                <h1 className="text-2xl font-bold   ">Pricing History</h1>
                <button onClick={() => callDownloadCSV()} className='my-auto text-custom-blue' type="button">Export to CSV</button>
            </div >

            <div className="flex flex-row justify-between">
                <div className="inline-flex shadow-sm mt-3" role="group">
                    <button onClick={() => { setView(VIEW.LIST); setCompaniesFilter([]); setCompaniesFilter(); }} type="button"

                        disabled={view === VIEW.LIST}
                        className={classNames(
                            view === VIEW.LIST ? "px-4 py-2 text-sm font-semibold text-gray-900 bg-white border  border-gray-200 " :
                                "px-4 py-2 text-sm font-normal text-custom-blue bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                        )}
                    >
                        List
                    </button>
                    <button onClick={() => { setView(VIEW.DETAILS); setCompaniesFilter(diffs.length > 0 ? [diffs[0].slug] : []); }} type="button"

                        disabled={view === VIEW.DETAILS}
                        className={classNames(
                            view === VIEW.DETAILS ? "px-4 py-2 text-sm font-semibold text-gray-900 bg-white border  border-gray-200 " :
                                "px-4 py-2 text-sm font-normal text-custom-blue bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                        )}
                    >
                        Details
                    </button>

                </div>
                <div className='my-auto'>
                    {
                        view === VIEW.LIST &&
                        <SlugFilter slugs={currentSlugs} selectHandler={setCompaniesFilter} currentFilter={companiesFilter} />
                    }

                </div>
            </div>
            {/* <SetFilter companiesFilter={companiesFilter} setCompaniesFilter={setCompaniesFilter} /> */}


            {/* <div className='flex flex-row justify-between pt-3 my-3'>
                <h2 className='my-3 font-semibold'>Total events:<span className='mx-3 font-normal'>{diffs.length}</span></h2>

            </div> */}

            {
                view === VIEW.DETAILS ?
                    <>


                        <HistoryDetailsControl companiesFilter={companiesFilter} currentDiff={currentDiff} diffsParam={slugVersions} updateDiffHandler={setCurrentDiff} />
                    </>
                    :
                    <>
                        <div className='my-3'>&nbsp;</div>
                        <div>
                            <table className="min-w-full divide-y divide-gray-300 hidden md:table">
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                        diffs.map(diff => (
                                            <tr>
                                                <div
                                                    onClick={() => {
                                                        if (diff.changeType !== 'firstCapture') {
                                                            setCurrentDiff(diff);
                                                            setCompaniesFilter([diff.slug]);
                                                            setView(VIEW.DETAILS);
                                                        }
                                                    }}
                                                    className="cursor-pointer hover:bg-blue-50 flex flew-row"
                                                >
                                                    <td className="px-3 py-4 text-sm text-gray-700">
                                                        {versionToDate(diff.timestamp)}
                                                    </td>
                                                    <td className="px-3 py-4 text-sm text-gray-700 font-semibold flex flex-row  w-48 ">
                                                        <img className="h-5 w-5 mr-2 flex-none rounded-full bg-gray-50" src={getDiffLogo(diff)} alt="" />
                                                        {diff.slug}
                                                    </td>
                                                    <td className="px-3 py-4 text-sm text-gray-700 w-full text-left">
                                                        {diff.changeType === 'firstCapture' ? (
                                                            <span className='italic text-gray-500'>
                                                                {diff.changeDesc}
                                                            </span>
                                                        ) : (
                                                            <>
                                                                {diff.changeDesc}
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="px-3 py-4 text-sm text-blue-500 w-32">
                                                        {/* Hide the original View Change link */}
                                                    </td>
                                                </div>
                                            </tr>

                                        )
                                        )}

                                </tbody>
                            </table>

                            <ul role="list" className="grid grid-cols-1 md:hidden ">
                                {diffs && diffs.map((diff) => (
                                    <li
                                        key={diff.slug + diff.timestamp}
                                        className="col-span-1 flex flex-col divide-y divide-gray-200 mb-3  bg-white text-center shadow"
                                    >

                                        <div className="flex flex-1 flex-col p-8">
                                            <div className='flex flex-row gap-3  items-center'>
                                                {versionToDate(diff.timestamp)}: <span className='font-semibold'>{diff.slug}</span>
                                            </div>
                                            <hr className='my-2' />
                                            <>
                                                <div className='flex flex-row gap-3 text-left items-center'>
                                                    {diff.changeDesc && diff.changeDesc.length > 100 ? diff.changeDesc.slice(0, 100) + "..." : diff.changeDesc}
                                                </div>
                                                {diff.changeType !== 'firstCapture' &&
                                                    <a className='text-custom-blue mt-5' href={`/diff?timestamp=${diff.timestamp}&slug=${diff.slug}`}>View Change</a>
                                                }
                                            </>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </>
            }
        </>
    );
};

export default SetHistory;