/* eslint-disable no-unused-vars */
import { React, useState, useEffect, useRef } from 'react';

const filterAllCompanies = (allCompanies, newCompany) => {
  if (!newCompany) {
    return allCompanies;
  }

  return allCompanies.filter((company) => {
    const name = company.companyId.toLowerCase();
    return name.includes(newCompany);
  });
};

const CompanySelectorSimple = ({
  comparedCompanies,
  allCompanies,
  addNewCompanyToTable,
  handleCompareModal,
  removeCompany,
}) => {
  const [newCompany, setNewCompany] = useState('');
  const [dropdown, setDropDown] = useState(false);

  const filteredCompanies = filterAllCompanies(allCompanies, newCompany);

  const ref = useRef(null);
  useEffect(() => {
    // 👇️ use document.getElementById()
    const word = document.getElementById('selected-company');
    console.log(word);
  }, []);

  function selectedCompany(word, id) {
    if (word === undefined || word.length === 0) {
      console.log('Empty input');
      return handleCompareModal();
    }

    const selected = allCompanies.find((company) => {
      return company.companyId === word;
    });
    const found = comparedCompanies.some((el) => el.companyId === word);

    if (!found && comparedCompanies.length < 5) {
      addNewCompanyToTable(selected);
    } else {
      console.log('Company already selected');
    }

    if (id === 'confirm') {
      handleCompareModal();
    }
    setNewCompany('');
  }

  function handleDropwDown() {
    setDropDown({
      dropdown: true,
    });
  }

  function selected(selected) {
    let word = document.getElementById('selected-company');
    word.value = selected;
    let chosenWord = ref.current.value;
    setDropDown(false);
    setNewCompany(chosenWord);
    selectedCompany(chosenWord);
  }

  console.log(comparedCompanies);

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform
                            transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="header-modal py-3 px-4 bg-blue-400 text-white font-semibold shadow-lg text-lg">
              Select a Company
            </div>

            <div className={`mt-6 mb-10 ml-2 container`}>
              <div className="flex items-center" autoComplete="off">
                <label htmlFor="voice-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-6 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1
                                        1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>

                  <div className="body-modal m-4 ">
                    <div className="content ml-0 flex mr-4">
                      <input
                        ref={ref}
                        type="text"
                        id="selected-company"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                            focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5
                                            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                            dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search All Companies..."
                        value={newCompany}
                        onInput={(e) => setNewCompany(e.target.value)}
                        onClick={() => handleDropwDown()}
                        required
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-16 mt-0">
                {dropdown ? (
                  <ul className="max-h-24 overlfow-auto overflow-y-auto ">
                    {filteredCompanies.map((company) => (
                      <li
                        className="hover:bg-blue-200 cursor-pointer"
                        onClick={() => selected(company.companyId, 'modal')}
                        key={company.companyId}
                      >
                        {company.companyId}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="footer-modal px-4 pt-2.5 pb-4">
              <div>
                <h1 className="ml-10 mb-4 text-lg font-poppins text-gray-500">
                  Selected Companies:
                </h1>
                {comparedCompanies.map((company, index) => (
                  <button
                    onClick={() => removeCompany(index)}
                    className="ml-8 mt-4 bg-blue-500 hover:bg-blue-700 text-white
                                font-bold py-2 px-4 rounded-full"
                    key={index}
                  >
                    {company.companyId}
                  </button>
                ))}
              </div>
              <div className="flex flex-row-reverse mt-14">
                <button
                  className="ml-2 bg-blue-500 text-white px-8 rounded-lg py-2 hover:bg-blue-600 t"
                  onClick={() => selectedCompany(newCompany, 'confirm')}
                >
                  Confirm
                </button>
                <button
                  className="hover:bg-gray-200 hover:text-gray-900 text-gray-600 px-8 rounded-lg py-2"
                  onClick={() => handleCompareModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySelectorSimple;
