/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import OverviewComponent from "../components/OverviewComponent/OverviewComponent"
import CompareComponent from "../components/CompareComponent/CompareComponent"
import { StarIcon } from "@heroicons/react/24/outline";
import { toggleUserFavourite } from "../redux/actionsUsers";
import { getUserCompanies} from "../redux/actionsCompanies";
import { applyUserFilters, applyUserSort } from "../redux/actionsFilters";
import { getExploreCompanies } from "../redux/actionsCompanies";
import { getPageType, PAGE_TYPE } from "../helpers/helperFunction";

const isFav = (state) => {
  console.log('is Fav called!');
  const queryParams = new URLSearchParams(window.location.search);
  const slug = queryParams.get('slug');

  if (!slug) {
    return false;
  }
  const userFavs =
    state.userReducer && state.userReducer.favourites
      ? state.userReducer.favourites
      : [];

  return userFavs.find((f) => f.toLowerCase() === slug.toLocaleLowerCase());
};

const mapStateToProps = (state) => {
  return {
    companies: getCompanies(state),
    isFavourite : isFav(state),
  }
};

const getCompanies = (state) => {
  const pageType = getPageType();
  console.log(pageType)

  switch (pageType) {
    case PAGE_TYPE.FAVS:
      return applyUserSort(
        applyUserFilters(getUserCompanies(state), state.filtersReducer),
        state.filtersReducer
      );
    case PAGE_TYPE.EXPLORE:
      return getExploreCompanies(state);
    default:
      break;
  }
  return [];
};

const getViewPageUrl = (slug) => {
  const pageType = getPageType();

  switch (pageType) {
    case PAGE_TYPE.FAVS:
      return `/favourites/view?slug=${slug}`;
    case PAGE_TYPE.EXPLORE:
      return `/explore/view?slug=${slug}`;
    default:
      break;
  }
  return `/view?slug=${slug}`;
};

//Showing 1 out of 12 Favourites
const getPageCounterText = (companies, company) => {
  const companyIndex = companies.findIndex(
    (c) => c.companyId === company.companyId
  );
  if (companyIndex < 0) {
    return '';
  }

  const category = company.category ? 'in ' + company.category : '';
  console.log(company);

  switch (getPageType()) {
    case PAGE_TYPE.FAVS:
      return (
        <a href="/favourites">{`Showing ${companyIndex + 1} out of ${
          companies.length
        } Favourites`}</a>
      );
    case PAGE_TYPE.EXPLORE:
      return `Showing ${companyIndex+1} out of ${companies.length}`;
    default:
      break;
  }

  return '';
};

export class ViewControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesModal: false,
      compareModal: false,
      shareModal: false,
      company: {},
      keywords: [],
      nextCompany: {},
      prevCompany: {},
      mode: 'Overview',
      tabs: [
        { name: 'Overview', current: true },
        { name: 'Compare', current: false },
      ],
    };
    this.handleOverview = this.handleOverview.bind(this);
    this.handleCompare = this.handleCompare.bind(this);
    this.handleIssuesModal = this.handleIssuesModal.bind(this);
    this.handleCompareModal = this.handleCompareModal.bind(this);
    this.handleShareModal = this.handleShareModal.bind(this);
    this.toggleTabs = this.toggleTabs.bind(this);
  }

  componentDidMount() {
    this.refresh();

    this.setState({ pageType: getPageType() });
  }

  refresh() {

      // Logic:
      // If this is an Explore company
      // Then
      //  load slug and store it as fullCompany in state control

      // Then fullCompany
      const queryParams = new URLSearchParams(window.location.search);
      const slug = queryParams.get("slug");

      if (this.props.companies) {
        const company = this.props.companies.find(company => company.companyId === slug);
        const companyIndex = this.props.companies.findIndex(company => company.companyId === slug);

        const nextCompany = companyIndex >= 0 && companyIndex < this.props.companies.length ?
          this.props.companies[companyIndex + 1] : null;

        const prevCompany = companyIndex && companyIndex > 0 ?
          this.props.companies[companyIndex - 1] : null;

        if (company) {
          this.setState({ company, nextCompany, prevCompany });
        }
      } else {
        console.log('oooo no dataa')
      }

    const callback = (data) => {
      this.setState({
        keywords: data.data,
      });
    };

    // if (slug) {
    //   callGetApi(process.env.REACT_APP_AUDIENCE + '/scraper/getKeywords?slug=' + slug, callback);
    // }
  }

  handleIssuesModal = () => {
    this.setState({ issuesModal: !this.state.issuesModal });
  };
  handleCompareModal = () => {
    this.setState({ compareModal: !this.state.compareModal });
  };
  handleShareModal = () => {
    this.setState({ shareModal: !this.state.shareModal });
  };

  handleOverview = (name) => {
    this.setState({ mode: name });
  };
  handleCompare = () => {
    this.setState({ mode: 'Compare' });
  };

  toggleTabs = (name) => {
    this.setState((prevState) => {
      const newState = prevState.tabs.map((tab) => {
        return tab.name === name ? (tab.current = true) : (tab.current = false);
      });
      console.log(this.state.tabs);
      console.log(this.state.mode);

      return newState;
    });
    this.handleOverview(name);
  };

  render() {
    const company = this.state.company;
    if (!company) {
      return <>Ooooh... company not found.</>;
    }

    const View = () => {
      if (this.state.mode === 'Overview') {
        return (
          <>
            <div className="w-full">
              <OverviewComponent
                hideFull={this.state.pageType !== PAGE_TYPE.FAVS}
                company={this.state.company}
                issuesModal={this.state.issuesModal}
                handleIssuesModal={this.handleIssuesModal}
                keywords={this.state.keywords}
                prevCompany={this.state.prevCompany}
                nextCompany={this.state.nextCompany}
              />
            </div>
          </>
        );
      } else if (this.state.mode === 'Compare') {
        return (
          <>
            <CompareComponent
              company={this.state.company}
              allCompanies={this.props.companies}
              compareModal={this.state.compareModal}
              handleCompareModal={this.handleCompareModal}
              shareModal={this.state.shareModal}
              handleShareModal={this.handleShareModal}
            />
          </>
        );
      }
    };

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ');
    }

    return (
      <>
        <div>
          <div></div>
          <div className="">
            <div className="flex flex-row justify-between mt-6">
              <div className="flex flex-row">
                <img src={company.logoUrl} className="w-12 h-12 mr-3" />
                <h2 className="flex text-5xl font-bold tracking-tight text-black md:text-5xl">
                  <span className="block"> {company.companyId}</span>
                </h2>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row-reverse	">
                  <div>
                    <button
                      type="button"
                      disabled={!this.state.nextCompany}
                      onClick={() =>
                        (window.location = getViewPageUrl(
                          this.state.nextCompany.companyId
                        ))
                      }
                      className={
                        this.state.nextCompany
                          ? 'border border-teal-500 text-teal-500 rounded-sm font-bold pt-2 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-teal-500'
                          : 'border border-teal-500 text-gray-300 rounded-sm font-bold pt-2 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-teal-500'
                      }
                    >
                      <h5 className="text-base mb-3 font-semibold inline-flex items-center">
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </h5>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      disabled={!this.state.prevCompany}
                      onClick={() =>
                        (window.location = getViewPageUrl(
                          this.state.prevCompany.companyId
                        ))
                      }
                      className={
                        this.state.prevCompany
                          ? 'border border-teal-500 text-teal-500 rounded-sm font-bold pt-2 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-teal-500'
                          : 'border border-teal-500 text-gray-300 rounded-sm font-bold pt-2 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-teal-500'
                      }
                    >
                      <h5 className="text-base mb-3 font-semibold inline-flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                          />
                        </svg>
                        Prev
                      </h5>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      // disabled={!prevCompany}
                      // onClick={() => window.location = '/view?slug=' + prevCompany.companyId}
                      className="border border-teal-500 text-teal-500 rounded-sm font-bold pt-2 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-teal-500"
                    >
                      <h5 className="text-base mb-3 font-semibold inline-flex items-center">
                        {this.props.isFavourite ? (
                          <StarIcon
                            onClick={() =>
                              toggleUserFavourite(company.companyId)
                            }
                            className="cursor-pointer block h-6 w-6 "
                            style={{ fill: 'gold' }}
                          />
                        ) : (
                          <StarIcon
                            onClick={() =>
                              toggleUserFavourite(company.companyId)
                            }
                            className="cursor-pointer block h-6 w-6 my-auto "
                          />
                        )}
                      </h5>
                    </button>
                  </div>
                </div>
                <div className="w-full text-right p-2 text-sm text-gray-600">
                  {getPageCounterText(this.props.companies, this.state.company)}
                </div>
              </div>
            </div>

            <div className="border-b border-gray-200 flex">
              <nav
                className="-mb-px flex space-x-8 cursor-pointer"
                aria-label="Tabs"
              >
                {this.state.pageType === PAGE_TYPE.FAVS &&
                  this.state.tabs.map((tab) => (
                    <div key={tab.name}>
                      <button
                        key={tab.name}
                        onClick={() => this.toggleTabs(tab.name)}
                        className={classNames(
                          tab.current
                            ? 'border-blue-500 text-blue-500'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {tab.name}
                      </button>
                    </div>
                  ))}
              </nav>
              {/* {this.state.mode === 'Compare' ?
                  <button
                  onClick={() => this.handleShareModal()}
                  className=" mt-2 mb-2 font-bold flex items-center py-3 px-8 ml-auto
                            text-sm text-white border  focus:outline-none"
                  style={{ background: '#28CDC2' }}>
                  Share
                  </button>
              : ''} */}
            </div>
          </div>
        </div>
        <div className=" pt-6 pb-9 flex flex-col w-full ">
          <div className="w-full flex ">{View()}</div>
          {this.state.mode === 'Overview' ? (
            <img
              alt="screenshot"
              className="border border-gray-500 px-9 pt-6 pb-9 flex flex-col"
              src={company.pricingPageScreenshotUrl}
            />
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(ViewControl);
