import { store } from './store';
import { setCnP } from './actions';
import {
  SET_CURRENT_SET,
  SET_ENTITLEMENT,
  SET_USERSETS,
  SET_WORKSPACE
} from './actionTypes';
import { callGetApi, callPutApi } from '../helpers/apiHelper';

export const createMagicLink = async (email, onSuccessCallback, onFailedCallback) => {

  // store.dispatch({
  //   type: SET_CURRENT_SET,
  //   payload: { setId }
  // });

  const callback = (data, dispatch) => {

    console.log("successfully createMagicLink");
    console.log(data)

    if (data.workspace) {
      dispatch({
        type: SET_WORKSPACE,
        payload: {
          workspace: data.workspace
        }
      })
    }

    // setLoadingCompanies(false);
    if (onSuccessCallback) onSuccessCallback(data.magicLink);
  }

  // Record on the backend
  const fn = (dispatch, getState) => {

    const userReducer = getState().userReducer;
    if (!userReducer) throw new Error("no reducer");

    const workspaceId = userReducer.workspaceId;
    if (!userReducer) throw new Error("no workspaceId");
    if (!email) throw new Error("no email");

    const param = { email, workspaceId };

    console.log(param);

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/createMagicLink`,
      param,
      (data, status) => {
        if (status) callback(data, dispatch)
        else {
          if (onFailedCallback) onFailedCallback(data.message);
        }
      }, (err) => {
        console.log(err);
      }
    );
  };

  store.dispatch(fn);
}

export const loadWorkspace = async (onSuccessCallback) => {

  const callback = (data, dispatch) => {

    console.log("successfully loadWorkspace");
    console.log(data)

    dispatch({
      type: SET_WORKSPACE,
      payload: {
        workspace: data.workspace
      }
    })
    // setLoadingCompanies(false);
    if (onSuccessCallback) onSuccessCallback();
  }

  // Record on the backend
  const fn = (dispatch, getState) => {
    const param = {};

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/getWorkspace`,
      param,
      (data) => { callback(data, dispatch) }, (err) => {
        console.log(err);
      }
    );
  };

  store.dispatch(fn);
}

export const removeUser = async (userToRemove, onSuccessCallback) => {

  if (!userToRemove) throw new Error("userToRemove is null!");

  const callback = (data, dispatch) => {

    console.log("successfully called removeUser");
    console.log(data)

    if (data.workspace) {
      dispatch({
        type: SET_WORKSPACE,
        payload: {
          workspace: data.workspace
        }
      })
    }

    // setLoadingCompanies(false);
    if (onSuccessCallback) onSuccessCallback();
  }

  // Record on the backend
  const fn = (dispatch, getState) => {
    const param = { userToRemove };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/removeWorkspaceUser`,
      param,
      (data) => { callback(data, dispatch) }, (err) => {
        console.log(err);
      }
    );
  };

  store.dispatch(fn);
}

export const processMagicLink = async (workspaceId, secretKey, onSuccessCallback, onFailedCallback) => {

  if (!workspaceId) throw new Error("workspaceId is null!");
  if (!secretKey) throw new Error("secretKey is null!");

  const callback = (data, dispatch) => {

    console.log("successfully called processMagicLink");
    console.log(data)

    if (data.workspace) {
      dispatch({
        type: SET_WORKSPACE,
        payload: {
          workspace: data.workspace
        }
      })
    }

    if (data.userSets) {
      dispatch({
        type: SET_USERSETS,
        payload: {
          userSets: data.userSets
        }
      })
    }


    if (data.entitlement) {
      dispatch({
        type: SET_ENTITLEMENT,
        payload: {
          entitlement: data.entitlement
        }
      })
    }
    // setLoadingCompanies(false);
    if (onSuccessCallback) onSuccessCallback(data.message);
  }

  // Record on the backend
  const fn = (dispatch, getState) => {
    const param = { workspaceId, secretKey };

    callPutApi(
      `${process.env.REACT_APP_ENDPOINT_URL}/processMagicLink`,
      param,
      (data, status) => {
        console.log(status);
        if (status) callback(data, dispatch);
        else {
          if (onFailedCallback) {
            onFailedCallback(data.message);
          }
        }
      },
      (err) => { console.log(err); }
    );
  };

  store.dispatch(fn);
}