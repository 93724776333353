/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { isFreemiumUser, getTotalLicensesUsed, classNames, getCurrentSetSlugs } from '../../helpers/common';

// import userReducer from '../../redux/reducers/userReducer';

// TODO: add API that sets local userSettings upon render
const UpgradeCTA = () => {

    const userReducer = useSelector(state => state.userReducer);

    if (!isFreemiumUser(userReducer)) {
        return <></>
    }

    // const totalUsed = getTotalLicensesUsed(userReducer);
    const totalUsed = getCurrentSetSlugs(userReducer).length;

    let progress = `w-${totalUsed}/5`;
    let color = totalUsed === 3 ? 'bg-custom-blue' : (totalUsed > 3 ? 'bg-red-600' : 'bg-custom-blue');

    console.log("totalUsed", totalUsed, progress);

    const getProgress = (num) => {

        switch (num) {
            case 0:
                return (<div
                    className={classNames(
                        'h-2.5 rounded-full ',
                        color,
                        'w-0'
                        )}></div>);
            case 1:
                return (<div
                    className={classNames(
                        'h-2.5 rounded-full ',
                        color,
                        'w-1/3'
                    )}></div>);
            case 2:
                return (<div
                    className={classNames(
                        'h-2.5 rounded-full ',
                        color,
                        'w-2/3'
                    )}></div>);

            case 3:
                return (<div
                    className={classNames(
                        'h-2.5 rounded-full ',
                        color,
                        'w-3/3'
                    )}></div>);

            case 4:
                // return (<div
                //     className={classNames(
                //         'h-2.5 rounded-full ',
                //         color,
                //         'w-4/5'
                //     )}></div>);

            case 5:
            default:
                return (<div
                    className={classNames(
                        'h-2.5 rounded-full ',
                        color,
                        'w-5/5'
                    )}></div>);

        }
    }
    // const

    return (
        <>
            <div class="hidden md:block w-1/4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                {getProgress(totalUsed)}
            </div>
            <div class="flex justify-between mb-1">
                <span class="text-sm font-medium mx-3 text-custom-blue dark:text-white">
                    <span className='text-gray-900'>{totalUsed} out of 3 used</span> (<a href="checkout">upgrade</a>)</span>
            </div>
        </>
    );
};

export default UpgradeCTA;