// import { helperLib } from '@buyerson/pricingsaas-pricebook';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

let initalized = false;

if (!initalized) {
  initalized = true;
  TimeAgo.addDefaultLocale(en);
}

const timeAgo = new TimeAgo('en-US');
const helperLib = null;

export function formatDate(timestamp) {
    return  timestamp ? timeAgo.format(timestamp) : '--';
}

export function selectCompanyAttributes(company, pricebook, favourites=[]) {

    const planNames = helperLib.getPlans(pricebook).length !== 0 ? helperLib.getPlans(pricebook) : [];
    const valueMetric = helperLib.getPrimaryValueMetric(pricebook);
    const valueMetricName = valueMetric ? valueMetric.name : '--';

  const freetrial = helperLib.doesPricebookHaveFreeTrial(pricebook);
  const freemium = helperLib.doesPricebookHaveFreePlan(pricebook);
  const lastModified = pricebook.lastModified;
  const lastModifiedFormatted = lastModified
    ? timeAgo.format(lastModified)
    : '--';
  const enterprisePlan = helperLib.doesPricebookHaveEnterprisePlan(pricebook);
  const isFavourite = Boolean(
    favourites.find((favourite) => favourite === company.companyId)
  );

  // console.log(company);

  return {
    ...company,
    valueMetric: valueMetricName,
    planNames: planNames,
    freemium,
    freetrial,
    lastModified,
    lastModifiedFormatted,
    enterprisePlan,
    isFavourite,
  };
  // return { companyId: company.companyId, logoUrl : company.logoUrl, employees: company.employees };
}

export function selectCategoryCompanyAttributes(company, favourites = []) {
  // return { ...company } ;
  return {
    companyId: company.companyId,
    logoUrl: company.logoUrl,
    category: company.category,
    annualRevenue: company.annualRevenue,
    pricingPageScreenshotUrl: company.pricingPageScreenshotUrl,
    pricingPageUrl: company.pricingPageUrl,
    isFavourite: false,
  };
  // return { companyId: company.companyId, logoUrl : company.logoUrl, employees: company.employees };
}

export function selectAcquisitionModel(company) {
  if (!company) {
    return '';
  }

  if (company.freemium && !company.freetrial) {
    return 'Freemium';
  } else if (!company.freemium && company.freetrial) {
    return 'Free Trial';
  } else if (company.freemium && company.freetrial) {
    return 'Reverse Trial';
  } else {
    return 'Demo Only';
  }
}
