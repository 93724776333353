/* eslint-disable no-unused-vars */
import React from 'react';
import { resetReducers } from '../redux/actionsCompanies';


export class ResetControl extends React.Component {


  componentDidMount() {

    resetReducers();
  }
  render() {

    return (
      <>
        <div className="py-16 px-3 lg:px-9 max-w-screen-xl	m-auto">
          Cache cleared successfully. Go to <a href='/set' className='text-custom-blue underline'>dashboard</a>.
        </div>
      </>
    );
  }
}

export default ResetControl;
