/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { filterCnP } from '../redux/actions';
import { toggleUserFavourite } from '../redux/actionsUsers';
import { getCategoryCompanies } from '../redux/actionsCompanies';
import { StarIcon } from '@heroicons/react/24/outline';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

const mapStateToProps = (state) => {
  return {
    companies: getCategoryCompanies(state),
    totalCount: getCategoryCompanies(state).length,
  };
};

export const COLUMNS = {
  EMPLOYEES: 'employees',
  NAME: 'name',
  VALUEMETRIC: 'valuemetric',
  REVENUE: 'revenue',
  FREEMIUM: 'freemium',
  PRICINGPAGE: 'pricingpage',
  TRIAL: 'trial',
  LAST_MODIFIED: 'lastmodified',
  FAVOURITES: 'favourites',
};

export class CategoryControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      slug: '',
      showModal: false,
      searchText: '',
      sortBy: 'lastmodified',
      sortAscending: true,
      passcode: '',
      companies: [],
      tabs: [
        { name: 'List', current: true },
        { name: 'Insights', current: false },
      ],
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const slug = queryParams.get('slug');
    this.setState({ slug });
  }

  getSortFn(sortByArg) {
    const sortBy = sortByArg ? sortByArg : this.state.sortBy;
    const sortAscending = this.state.sortAscending;

    console.log('Sorting by', sortBy);

    if (sortBy) {
      switch (sortBy) {
        case COLUMNS.EMPLOYEES:
          return (a, b) => {
            return sortAscending
              ? parseInt(b.employees) - parseInt(a.employees)
              : parseInt(a.employees) - parseInt(b.employees);
          };
        case COLUMNS.NAME:
          return (a, b) => {
            return sortAscending
              ? b.companyId.localeCompare(a.companyId)
              : a.companyId.localeCompare(b.companyId);
          };

        case COLUMNS.REVENUE:
          return (a, b) => {
            if (!a.annualRevenue || !b.annualRevenue) {
              const x = Boolean(a.annualRevenue);
              const y = Boolean(b.annualRevenue);

              if (sortAscending) {
                return x === y ? 0 : x ? -1 : 1;
              } else {
                return x === y ? 0 : x ? 1 : -1;
              }
            }
            //return sortAscending ?  b.annualRevenue.localeCompare(a.annualRevenue) :  a.annualRevenue.localeCompare(b.annualRevenue)
            return sortAscending
              ? b.annualRevenue - a.annualRevenue
              : a.annualRevenue - b.annualRevenue;
          };
        case COLUMNS.FAVOURITES:
          return (a, b) => {
            const x = a.isFavourite;
            const y = b.isFavourite;
            if (sortAscending) {
              return x === y ? 0 : x ? -1 : 1;
            } else {
              return x === y ? 0 : x ? 1 : -1;
            }
          };
        case COLUMNS.VALUEMETRIC:
          return (a, b) => {
            const vm1 = a.valueMetric;
            const vm2 = b.valueMetric;
            return sortAscending
              ? vm1.localeCompare(vm2)
              : vm2.localeCompare(vm1);
          };
        case COLUMNS.FREEMIUM:
          return (a, b) => {
            const x = a.freemium;
            const y = b.freemium;
            if (sortAscending) {
              return x === y ? 0 : x ? -1 : 1;
            } else {
              return x === y ? 0 : x ? 1 : -1;
            }
          };
        case COLUMNS.TRIAL:
          return (a, b) => {
            const x = a.freetrial;
            const y = b.freetrial;
            if (sortAscending) {
              return x === y ? 0 : x ? -1 : 1;
            } else {
              return x === y ? 0 : x ? 1 : -1;
            }
          };
        case COLUMNS.LAST_MODIFIED:
          return (a, b) => {
            return sortAscending
              ? b.lastModified - a.lastModified
              : a.lastModified - b.lastModified;
          };
        default:
          break;
      }
    }
    const sortFn = (a, b) => {
      return b.employees - a.employees;
    };
    return sortFn;
  }

  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }

  sortByColumn(column) {
    this.setState({ sortBy: column, sortAscending: !this.state.sortAscending });
    const sortedCnP = this.props.companies.sort(this.getSortFn(column));
    this.props.dispatch(filterCnP(sortedCnP));
  }

  filterCompanies(companies) {
    let result = companies;
    const searchText = this.state.searchText;

    if (searchText) {
      result = companies.filter((c) =>
        c.companyId.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return result;
  }

  render() {
    if (this.state.refreshing) {
      return (
        <>
          <div className="w-full text-center mt-24">
            Loading data... this will take a few seconds!
          </div>
        </>
      );
    }

    const companies = this.filterCompanies(this.props.companies);
    companies.sort(this.getSortFn());

    const lightboxImages = companies.map((c) => ({
      src: c.pricingPageScreenshotUrl,
    }));

    return (
      <>
        <div className="w-full my-3">
          <div className="w-full flex flex-wrap flex-row text-gray-600 mb-9">
            {/* <FilterControl showSearch={true}/> */}
            <div className="flex items-center justify-center	mt-3 pr-9">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input
                type="text"
                autoFocus
                className="w-96 border-b-2 border-gray-100  outline-0 outline-white	"
                id="searchText"
                name="researchTextsult"
                value={this.state.searchText}
                placeholder="Search Companies"
                onChange={(event) =>
                  this.setState({ searchText: event.target.value })
                }
              />
              <br />
            </div>

            <div className="flex flex-row items-center justify-center">
              <h1 className="w-full pt-3 pr-4">
                {' '}
                Showing: {companies.length} out of {this.props.totalCount} in{' '}
                <strong>{this.state.slug} </strong>
              </h1>
            </div>
          </div>

          <Lightbox
            open={this.state.showLightbox}
            close={() => this.setState({ showLightbox: false })}
            slides={lightboxImages}
            plugins={[Zoom]}
            on={{
              view: (index) => console.log('slide', index),
              click: (index) => console.log('click', index),
            }}
            zoom={{
              maxZoomPixelRatio: 1,
              zoomInMultiplier: 2,
              doubleTapDelay: 300,
              doubleClickDelay: 500,
              doubleClickMaxStops: 2,
              keyboardMoveDistance: 50,
              wheelZoomDistanceFactor: 100,
              pinchZoomDistanceFactor: 100,
              scrollToZoom: true,
            }}
          />
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  onClick={() => {
                    this.sortByColumn(COLUMNS.NAME);
                  }}
                >
                  Company
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                  onClick={() => {
                    this.sortByColumn(COLUMNS.EMPLOYEES);
                  }}
                >
                  Number of employees
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                  onClick={() => {
                    this.sortByColumn(COLUMNS.REVENUE);
                  }}
                >
                  Annual Revenue
                </th>
                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " onClick={() => { this.sortByColumn(COLUMNS.VALUEMETRIC)}} >
                          Captial Raised
                        </th> */}
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                  onClick={() => {
                    this.sortByColumn(COLUMNS.PRICINGPAGE);
                  }}
                >
                  Pricing Page
                </th>
                {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " onClick={() => { this.sortByColumn(COLUMNS.LAST_MODIFIED)}} >
                          Last modified
                        </th> */}
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                  onClick={() => {
                    this.sortByColumn(COLUMNS.FAVOURITES);
                  }}
                >
                  Favourtie
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {companies.map((company, index) => (
                <tr key={index} className="hover:bg-blue-50 cursor-pointer">
                  {/* onClick={ () => this.setState({showLightbox : true}) }> */}
                  {/* <tr key={index} className="hover:bg-blue-50 cursor-pointer" onClick={ () => window.location="/view?slug=" + company.companyId } > */}
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex flex-row align-middle">
                      <img
                        alt="logo"
                        className="h-8 w-8"
                        src={company.logoUrl}
                      />
                      <a
                        rel="noreferrer"
                        href={'/explore/view?slug=' + company.companyId}
                      >
                        <span className="block ml-3 pt-1">
                          {company.companyId}
                        </span>
                      </a>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.employees > 0 ? company.employees : '--'}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {company.annualRevenue
                      ? '$' + this.nFormatter(company.annualRevenue, 0)
                      : '--'}{' '}
                  </td>
                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.captialRaised ?  "$" + this.nFormatter(company.captialRaised, 0) : '--' } </td> */}
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-row">
                      <a
                        href={company.pricingPageUrl}
                        target="_blank"
                        className="text-blue-600 mr-3"
                      >
                        Pricing Page
                      </a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </div>
                  </td>
                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {
                              company.lastModifiedFormatted
                            }
                          </td> */}
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    onClick={(e) => {
                      toggleUserFavourite(company.companyId);
                      e.stopPropagation();
                    }}
                  >
                    {company.isFavourite ? (
                      <StarIcon
                        onClick={(e) => {
                          toggleUserFavourite(company.companyId);
                          e.stopPropagation();
                        }}
                        className="cursor-pointer block h-6 w-6 m-auto"
                        style={{ fill: 'gold' }}
                      />
                    ) : (
                      <StarIcon
                        onClick={(e) => {
                          toggleUserFavourite(company.companyId);
                          e.stopPropagation();
                        }}
                        className="cursor-pointer block h-6 w-6 m-auto"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(CategoryControl);
