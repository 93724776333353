/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SetCompanies from './SetCompanies';
import SetRecommendations from './SetRecommendations';
import SetGallery from './SetGallery';
import  { VIEW } from './SetHistory';
import { isFreemiumUser } from '../../helpers/common';
import MainHeader from '../Settings/MainHeader';
import { loadSetPageInfo } from '../../redux/actionsPages';
import { loadUser } from '../../redux/actionsUsers';
import CompanyDetailsControl from './CompanyDetailsControl';

const tabs = [
    { name: 'Companies', href: '#', current: true },
    { name: 'History', href: '#', current: false },
    { name: 'Plans', href: '#', current: false },
    { name: 'Gallery', href: '#', current: false },
]

const tabsFreemium = tabs;


const MySet2 = () => {

    const [currentTab, setCurrentTab] = useState(0);
    const [companiesFilter, setCompaniesFilter] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [selectedSlug, setSelectedSlug] = useState('pricingsaas');

    const searchParams = new URLSearchParams(window.location.search);
    const initialTab = searchParams.get('tab');

    const userReducer = useSelector(state => state.userReducer);
    if (!userReducer) {
        return <>Hmm... something is not right. Hit <a href='/reset' className='text-custom-blue underline'>Reset</a></>;
    }

    const mySets = userReducer.sets;
    if (!mySets || mySets.length === 0) {
        return <>Hmm... something is not right. Hit <a href='/reset' className='text-custom-blue underline'>Reset</a></>;
    }

    if (firstLoad) {
        if (initialTab && initialTab !== currentTab) {
            setCurrentTab(parseInt(initialTab));
        }
        loadSetPageInfo(null, null, null, true);
        // loadUser();
        setFirstLoad(false);
    }

    const companyClickHandler = (slug) => {

        setCompaniesFilter([slug]);
        setCurrentTab(1);
    }

    const companyDetailsClickHanlder = (slug) => {

        setCompaniesFilter([slug]);
        setSelectedSlug(slug);
        setCurrentTab(1);
    }

    return (
        <>
            <div className="w-full  p-4 bg-white lg:border  sm:p-8  ">
                <MainHeader hideSet={false} />
                <hr className='mt-9' />
                {(userReducer.loadingCompanies) ?
                    <>
                        <div className="flex full flex-col p-4 bg-white sm:p-8">
                            <a href="/home"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-3' alt="Logo"></img></a>
                            <div className="m-auto">
                                <div className="text-gray-700 my-32">Loading set... </div>
                            </div>
                        </div></>
                    :
                    <div className="w-full">
                        {currentTab === 0 && <>
                            <div className='flex flex-col w-full'>
                                {/* < UserRequests /> */}

                                <SetCompanies
                                    onClickHandler={companyClickHandler}
                                    onShowGallery={() => setCurrentTab(3)}
                                    onClickHandlerDetails={companyDetailsClickHanlder} />
                                < SetRecommendations />



                            </div>
                        </>}
                        {currentTab === 1 &&
                            <>
                                <CompanyDetailsControl slug={selectedSlug} backHandler={() => setCurrentTab(0)} />
                            </>
                        }
                        {currentTab === 3 && <SetGallery backHandler={() => setCurrentTab(0)} companiesFilter={companiesFilter} setCompaniesFilter={setCompaniesFilter} showDemo={false} />}
                    </div>
                }
            </div>

        </>
    );
};

export default MySet2;