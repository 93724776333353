/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { isGrandfatheredFreemiumUser, isFreemiumUser } from '../../helpers/common.js';
import UpgradeCTA from '../Sets/UpgradeCTA.jsx';
import SetsControl from './SetsControl.jsx';
import { Cog8ToothIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { getCurrentSetSlugs } from '../../redux/actionsUsers.js'
import { refresh } from '../../redux/actionsUsers.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PublicMainHeader = (props) => {

    return (
        <>
            <h1 className='font-bold text-2xl  flex flex-col md:flex-row items-center justify-between  gap-y-6	'>
                <div className=' text-2xl flex flex-row items-center w-full '>
                    <a href="https://pricingsaas.com/"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-9' alt="Logo"></img></a>

                </div>
                <div className=' flex md:w-1/2 w-full  justify-end  items-center content-center'>

                    {/* <a href="/settings" className='text-custom-blue font-medium text-base flex items-center'> <Cog8ToothIcon className="cursor-pointer block h-6 w-6 m-auto ml-4 text-gray-600"></Cog8ToothIcon></a> */}
                    <a href="/" className='text-custom-blue font-medium text-base flex items-center'>Login</a>
                </div>


            </h1>
        </>
    )
}

export default PublicMainHeader;