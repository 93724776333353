import { combineReducers } from 'redux';
import cnpReducer from './cnpReducer';
import userReducer from './userReducer';
import companiesReducer from './companiesReducer';
import filtersReducer from './filtersReducer';

export default combineReducers({
  cnpReducer,
  filtersReducer,
  userReducer,
  companiesReducer,
});
