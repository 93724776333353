/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';  // Import useRef and useEffect
import { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { getCurrentSetCompanies } from '../../helpers/common';
import { addSlugToSet, removeSlugFromSet } from '../../redux/actionsPages';
import { versionToDate } from '../../helpers/common';
import SetsControl from '../Settings/SetsControl';

const CompareModal = ({ slug, desc, beforeVersion, afterVersion, beforeUrl, afterUrl, showModal, hideModalHandler, onPrevious, onNext }) => {
    const [loading, setLoading] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(afterUrl);  // Set the current url to the beforeUrl
    const [currentVersion, setCurrentVersion] = useState(afterVersion);  // Set the current version to the beforeVersion
    const [paused, setPaused] = useState(false);  // Add a paused state
    const addButtonRef = useRef(null);  // Create a ref for the button

    useEffect(() => {
        const timer = setInterval(() => {
            if (paused) return;  // If paused, return early
            setCurrentUrl((prevUrl) => prevUrl === afterUrl ? beforeUrl : afterUrl);
            setCurrentVersion((prevVersion) => prevVersion === afterVersion ? beforeVersion : afterVersion);
        }, 1500);

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                console.log('left', beforeUrl);
                setCurrentUrl(beforeUrl);
                setCurrentVersion(beforeVersion);
                setPaused(true);  // Pause the timer
            } else if (event.key === 'ArrowRight') {
                console.log('right', afterUrl);
                setCurrentUrl(afterUrl);
                setCurrentVersion(afterVersion);
                setPaused(true);  // Pause the timer
            }
        };

        if (showModal) {
            document.addEventListener('keydown', handleKeyDown);
            const timer2 = setTimeout(() => {
                addButtonRef.current?.focus();
            }, 100);

            return () => {
                clearTimeout(timer2);
                document.removeEventListener('keydown', handleKeyDown);
                clearInterval(timer); // Clear the interval
            };
        } else {
            setPaused(false);  // Reset the paused state
        }

        return () => {
            clearInterval(timer); // Clear the interval
        };

    }, [showModal, onPrevious, onNext, beforeUrl, afterUrl, paused]);

    const userReducer = useSelector(state => state.userReducer);
    const closeHandler = () => { hideModalHandler(); }

    let myCompanies = getCurrentSetCompanies(userReducer);

    const slugInSet = (myCompanies.find(c => c.slug === slug) ? true : false);


    const addSlug = (slug) => {
        setLoading(true);
        addSlugToSet(slug, () => setLoading(false));
    }

    const removeSlug = (slug) => {
        setLoading(true);
        removeSlugFromSet(slug, () => setLoading(false));
    }

    return (
        <><Transition.Root show={showModal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={closeHandler}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-start justify-center p-4 sm:h-full my-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-y-scroll max-h-max	  bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:h-full" style={{ width: '90vw' }}>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row content-between my-2'>
                                        <div className="flex flex-row my-auto  w-full justify-between text-gray-700">
                                            <span className='font-semibold'>{slug}</span>

                                        </div>
                                        <button
                                            onClick={closeHandler}
                                            className='text-3xl font-bold text-gray-700'
                                        >
                                            &#10005; {/* Unicode for big X */}
                                        </button>
                                    </div>
                                    <hr />

                                    <div className='p-5 text-gray-700 border border-gray-300 text-left w-full bg-gray-100'>
                                        {desc}
                                    </div>

                                    <div className='flex flex-row justify-between mt-6'>
                                        <div className='text-extra-small text-left italic mb-1 text-gray-500'>Showing version: {versionToDate(currentVersion)}</div>
                                        <div className='text-extra-small text-left italic mb-1 text-gray-500'>Use keyboard ← → to move </div>

                                    </div>
                                    <div className="flex justify-center"> {/* Add this div for horizontal center alignment */}

                                        {currentUrl &&
                                            <img className='border border-gray-300' src={currentUrl} alt="Screenshot" style={{ height: '100%' }} />
                                        }

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

        </>
    );
};

export default CompareModal;