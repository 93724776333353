// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Fragment } from 'react'
// import { Menu, Transition } from '@headlessui/react'
// import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
// import EditSetModal from './EditSetModal';
// import { loadWorkspace, createMagicLink, removeUser } from '../../redux/actionsWorkspaces';
// import MagicLinkModal from './MagicLinkModal';

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { generateReport, generateCompaniesCsvReport, getCurrentSetSlugs } from '../../redux/actionsUsers';
import { getCurrentSetCompanies, downloadCSV } from '../../helpers/common';


import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import { impersonate, setCurrentSet } from '../../redux/actionsUsers';

import EditSetModal from './EditSetModal';
import { loadWorkspace, createMagicLink, removeUser } from '../../redux/actionsWorkspaces';
import MagicLinkModal from './MagicLinkModal';

const AdminControl = () => {
    const [firstLoad, setFirstLoad] = useState(true);

    const [fetching, setFetching] = useState(false);
    const userReducer = useSelector(state => state.userReducer);
    // const impersonateOptions = ['nobody', 'user1', 'user2', 'user3']; // Add your impersonate options here
    // const [selectedImpersonate, setSelectedImpersonate] = useState(impersonateOptions[0]);

    if (firstLoad) {
        // do something
    }

    const impersonateOptions = [
        {
            name: 'Nobody',
            value: null
        },
        {
            name: 'johnkohlsmith@gmail.com',
            value: 'google-oauth2|114768987137414002524'
        },
        {
            name: 'martin.lehnhausen@simon-kucher.com',
            value: 'auth0|65a6ddc9f49bbeac5d445641'
        },
        {
            name: 'joshua@gogemini.com',
            value: 'google-oauth2|101471432729039970466'
        },
        {
            name: 'marshall.knauf@cloudbeds.com',
            value: 'google-oauth2|115176395618322081706'
        },
        {
            name: 'david.muhr@meisterlabs.com',
            value: 'google-oauth2|112475945461254612125'
        },
        {
            name: 'zac.sheffer@vendr.com',
            value: 'google-oauth2|115181756078893730077'
        },
        {
            name: 'maciej@valueships.com',
            value: 'auth0|63f397598b63ceec1690a61a'
        },
        {
            name: 'toby@fabulate.com.au',
            value: 'google-oauth2|107741350005056689720'
        },
        {
            name: 'benjamin.morgan@fisglobal.com',
            value: 'auth0|6439470c45094fb681f7175f'
        },
        {
            name: 'calista@withorb.com',
            value: 'google-oauth2|104878085023465751818'
        },
        {
            name: 'john.stetic@ziflow.com',
            value: 'google-oauth2|118300945742236857533'
        },
        {
            name: 'ana.zrno@team.wrike.com',
            value: 'google-oauth2|105419869288739310364'
        },
        {
            name: 'charlie.kline@calendly.com',
            value: 'auth0|643eae0b3c45c24cb0b54cf9'
        },
        {
            name: 'benjamin.morgan@fisglobal.com',
            value: 'auth0|6439470c45094fb681f7175f'
        },
        {
            name: 'aashish@togai.com',
            value: 'google-oauth2|108917941153282632770'
        },
        {
            name: 'john@buyerson.co',
            value: 'google-oauth2|108559125024588636305'
        },
        {
            name: 'Thomas',
            value: 'google-oauth2|100837772328515797346'
        },
        {
            name: 'Andrew - Seismic',
            value: 'auth0|65f1d39a09bed337e47be36b'
        },

        {
            name: 'Rob - Gmail',
            value: 'google-oauth2|117759775385286114680'
        },
        {
            name: 'Jon Manning',
            value: 'google-oauth2|107539026564243456640'
        },
        {
            name: 'Jeff Vendr',
            value: 'google-oauth2|118066163029716894265'
        },
        {
            name: 'mjwarren',
            value: 'auth0|65ca8224d1d6ee1429ce53b6'
        }
        ,
        {
            name: 'francois',
            value: 'google-oauth2|114257819952973393824'
        }
        ,
        {
            name: 'nagus81',
            value: 'google-oauth2|112684518062151411199'
        }
        ,
        {
            name: 'nagus81',
            value: 'google-oauth2|112684518062151411199'
        }
        , {
            name: 'Bill ',
            value: 'google-oauth2|102638481022288773269'
        }
    ]

    impersonateOptions.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
    });

    if (!userReducer) return (<></>);

    const selectedImpersonate = userReducer.impersonate ? userReducer.impersonate : 'Nobody';


    const fetchReport = async (slugs, setLoading) => {

        if (setLoading) setLoading(true);

        // testHTML();
        // return;

        try {
            const url = await generateReport(slugs);
            if (url) {
                window.open(url, '_blank');
            }
            // const csv = await generateCompaniesCsvReport(slugs);

            // downloadCSV(csv, 'companies.csv');
            // console.log(csv);

        } catch (e) {
            console.error(e);
        }

        if (setLoading) setLoading(false);
    }


    const fetchReportAction = () => {

        // const slugs = getCurrentSetCompanies(userReducer, true).map(c => c.slug);
        const slugs = getCurrentSetSlugs(userReducer, true);
        if (slugs.length === 0) {
            alert("To generate a report, first add some companies ...")
        } else {
            fetchReport(slugs, setFetching);
        }
    };

    const setSelectedImpersonate = (option) => {

        impersonate(option, (data) => {
            if (data && data.currentSetId) {
                console.log("Seeting current set to: " + data.currentSetId);
                setCurrentSet(data.currentSetId);
            }
        }
        );
    };

    return (
        <>
            {/* <h2 className='font-medium text-lg mt-9 flex flex-row underline'>Workspace</h2> */}

            {/* <h3 className='font-medium text-sm flex flex-row'>Impersonate Settings</h3> */}
            <div className='flex flex-col'>
                <div className='flex flex-row gap-x-2 my-8'>
                    <div className="relative inline-block text-left">
                        <span className='text-sm font-light'>Currently impersonating: </span>
                        <span className='text-sm font-medium'>
                            <Menu>
                                <Menu.Button className="inline-flex  mx-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                                    {selectedImpersonate}
                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {impersonateOptions.map((option) => (
                                                <Menu.Item key={option.value}>
                                                    {({ active }) => (
                                                        <button
                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                } block w-full text-left px-4 py-2 text-sm`}
                                                            onClick={() => setSelectedImpersonate(option.value)}
                                                        >
                                                            {option.name}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </span>
                    </div>

                </div>
                <div className='mt-9 mb-9'>
                    <span className='text-sm font-light my'>Report Controls</span>

                    <button
                        onClick={() => fetchReportAction()}
                        className="hidden md:block h-10 w-64 mt-3 bg-white px-6 py-1.5 text-baseline font-normal border  text-custom-blue shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300"

                    >
                        {fetching ? "Generating..." : "Generate Report"}
                    </button>
                </div>
            </div>

            {/*
            <div className="relative inline-block text-left">

            </div> */}
        </>
    );
};

export default AdminControl;