/* eslint-disable no-unused-vars */
import React from "react";

const Selection = (props) => {
    return (
        <li className="py-3 sm:py-4 focus:outline-none">
            <div className="flex items-center space-x-4 ">
                <div className="flex-shrink-0">
                    <img className="w-8 h-8" src={props.img} alt="companysizes"></img>
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate ">
                        {props.title}
                    </p>
                </div>
                {props.children}
            </div>
        </li>
    )
}

export default Selection;