/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { useRef, useEffect } from "react";

import { Dialog, Transition } from '@headlessui/react';
import { submitUserFav } from '../redux/actionsUsers';

function isValidUrl(urlString) {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
}

export class AddCompanyModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            pricingUrl: "https://" + this.props.defaultValue,
            hidden: true,
            validUrl: true
        }
    }


    componentDidMount() {
        // console.log('mounted...');
    }

    myChangeHandler(value) {
        // this.setState({pricingUrl : value});
        console.log(value, 'is valid', isValidUrl(value));

        if (value.includes("https://https://")) {
            value = value.replace("https://https://", "https://");
        }

        console.log(value);

        this.setState({ validUrl: isValidUrl(value) });
        this.setState({ pricingUrl: value });
    }

    myCloseHandler() {
        this.setState({ hidden: true });
        this.props.closeHandler();
    }

    addCompanyHandler() {
        if (isValidUrl(this.state.pricingUrl)) {
            submitUserFav(this.state.pricingUrl, () => this.myCloseHandler())
        } else {

            this.setState({ validUrl: false });
        }
    }

    render() {

        // console.log('hidden', this.state.hidden, 'show', this.props.show);

        if (this.state.hidden && this.props.show) {
            // check for reset
            this.setState({ hidden: false, pricingUrl: "https://" + this.props.defaultValue });
        }

        return (
            <Transition.Root show={this.props.show} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => this.myCloseHandler()}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className='px-12 pt-6 pb-12'>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => this.myCloseHandler()}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Request Company
                                            <hr/>
                                        </Dialog.Title>
                                        <div className="mt-3 text-left sm:mt-9">
                                            {/* <p>Ooh, looks like the company is not in our database yet. </p>
                                            <br/> */}
                                            <p className='mt-9 mb-1'>Pricing page URL:</p>

                                            <div className='flex flex-inline'>
                                                <input type="text" tabindex="0" class="border border-gray-300 w-full " id="result" name="result" value={this.state.pricingUrl} onChange={(e) => this.myChangeHandler(e.target.value)} />
                                                <button onClick={() => this.addCompanyHandler()} className="h-8   bg-blue-600 px-5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Add</button>
                                            </div>
                                            {!this.state.validUrl &&

                                                <p className='my-3 text-sm text-red-500 italic'>Please enter a valid URL</p>
                                            }

                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default AddCompanyModal;
