import React, { useEffect, useState } from 'react';
import { versionToDate2, countDays, vertsionToDaySince } from '../helpers/common';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Timeline = ({ versions, current, setCurrent }) => {

    if (!versions || versions.length <= 1) {
        return <></>;
    }

    versions.sort((a, b) => {
        return a.first - b.first;
    });

    const daysBetween = countDays(versions[0].first, versions[versions.length - 1].last);
    console.log(daysBetween);

    console.log(versions);

    for (let i = 0; i < versions.length; i++) {

        const version = versions[i];

        if (i === versions.length - 1) {
            const v1 = versions[i].first;
            const days = vertsionToDaySince(v1);
            const percentage = (days / daysBetween) * 100;
            version.percentage = percentage.toFixed(0);
        } else {
            // Calculate the percentage of the timeline that this version represents
            const v1 = versions[i].first;
            const v2 = versions[i + 1].first;
            const days = countDays(v1, v2);
            const percentage = (days / daysBetween) * 100;
            version.percentage = percentage.toFixed(0);

        }
    }

    const startDate = versionToDate2(versions[0].first);

    const isCurrent = (version) => {
        if (current && current.first === version.first && current.last === version.last) {

            return true;
        }
        return false;
    }

    return (

        <div className='flex flex-col w-full mt-9' >
            <div className="flex flex-row w-full justify-between mb-5">

                <time className=" text-sm font-semibold leading-none text-gray-700 block ">{startDate}</time>
                <time className=" text-sm font-semibold leading-none text-gray-700 block ">Today</time>

            </div>

            <div className="flex flex-row w-full">

                <ol className="items-center flex mb-3 w-full">

                    {versions.map((version, index) => (
                        <>
                            {index >= 0 &&
                                <>
                                    <li className="relative mb-3 md:mb-0" style={{ width: `${version.percentage}%` }} onClick={() => setCurrent(version)}>

                                        <div className="flex items-center">
                                            <div
                                                className=
                                                {classNames(
                                                    isCurrent(version)
                                                        ? 'bg-amber-500 z-50'
                                                        : 'bg-blue-900 hover:bg-custom-blue z-10',
                                                    ' flex items-center justify-center w-3 h-3  rounded-full ring-0  sm:ring-4 shrink-0'
                                                )}
                                            >
                                                <svg className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300 z-10" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">

                                                </svg>
                                            </div>
                                            <div className="flex w-full bg-gray-200 h-0.5  "></div>
                                        </div>

                                    </li>
                                </>
                            }
                        </>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default Timeline;
