/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Fragment } from 'react'
import { useState } from 'react';
import { getLogoUrl } from '../../helpers/common';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { addSlugToSet } from '../../redux/actionsPages';
import SetPreview from './SetPreview';
import { dismissRecommendedCompany, getCurrentSetSlugs } from '../../redux/actionsUsers';
import { getScreenshotUrl, getPricingPageUrl } from '../../helpers/common';

const statuses = {
    error: 'text-red-700 bg-red-50 ring-red-600/20',
    done: 'text-green-700 bg-green-50 ring-green-600/20',
    inprogress: 'text-blue-600 bg-blue-50 ring-blue-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


// TODO: add API that sets local userSettings upon render
const SetRecommendations = ({ onClickHandler }) => {

    const [showPreview, setShowPreview] = useState(false);
    const [previewSlug, setPreviewSlug] = useState('');
    const [showLess, setShowLess] = useState(true);

    const userReducer = useSelector(state => state.userReducer);
    if (!userReducer) { return <></>; }

    const currentSet = userReducer.currentSet;
    const currentSlugs = getCurrentSetSlugs(userReducer);

    if (currentSlugs.length === 0) { return <></>; }

    let recommendations = currentSet && currentSet.recos ? currentSet.recos : [];

    // console.log(recommendations);

    let dismissed = currentSet && currentSet.dismissedRecos ? currentSet.dismissedRecos : [];
    // console.log('dismissed', dismissed);

    // recommendations = [{ slug: "apollo-io" }]
    recommendations = recommendations.filter((company) => !dismissed.find(dCompany => dCompany === company.slug));
    recommendations = recommendations.filter((company) => !currentSlugs.includes(company.slug));
    let showMoreControl = recommendations.length > 2;
    recommendations = showLess ? recommendations.slice(0, 2) : recommendations;

    if (!recommendations || recommendations.length === 0) { return <></>; }

    return (
        <>
            <div className='flex flex-col mt-12 mb-6   '>

                <hr />
                <div className='flex flex-row justify-between  w-full mt-6' >
                    <h2 className="text-normal font-medium tracking-tight mb-3 text-gray-700 sm:text-normal  ">Companies with Similar Pricing</h2>
                    <div className=" mb-3 text-gray-700 hover:text-custom-blue " onClick={() => dismissRecommendedCompany(null)}>Dismiss All</div>
                    {/* <button
                        onClick={(event) => {
                            event.stopPropagation();
                        }}>
                        Dismiss All
                    </button> */}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
                    {
                        recommendations.map((company, setIndex) => (
                            <Fragment key={company.slug}>
                                <div className=" p-6 border hover:bg-blue-50 bg-gray-100 flex flex-row items-center justify-between relative"
                                    onClick={(event) => {
                                        setPreviewSlug(company.slug);
                                        setShowPreview(true);

                                    }}>
                                    <div className='flex flex-row'>
                                        <img className="h-16 w-16 mr-6" src={getLogoUrl(userReducer, company.slug)}></img>
                                        <div className='flex flex-col'>
                                            <h2 className='font-medium text-lg my-auto flex flex-row'>
                                                {company.slug}
                                                {/* {company.overlimit && <><ExclamationTriangleIcon title='Over the limit. You will not receive change alerts.' className="h-7 w-7 mx-1 text-yellow-500" aria-hidden="true" /> </>} */}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='float-right flex flex-row space-x-2'>
                                        <button
                                            onClick={(event) => {
                                                addSlugToSet(company.slug);
                                                event.stopPropagation();
                                            }}
                                            className="hidden md:block h-10 bg-white px-6 py-1.5 text-baseline font-normal border text-gray-700 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300">
                                            Add
                                        </button>
                                        {/* {getContextMenu(() => dismissRecommendedCompany(company.slug))} */}
                                        <button
                                            onClick={(event) => {
                                                dismissRecommendedCompany(company.slug);
                                                event.stopPropagation();
                                            }}
                                            className="hidden md:block h-10 bg-white px-6 py-1.5 text-baseline font-normal border text-gray-700 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-300">
                                            Dismiss
                                        </button>
                                    </div>
                                    {/* X button */}

                                </div>
                            </Fragment>
                        ))
                    }
                </div>
                {showMoreControl &&
                    <>
                        {
                            showLess ?
                                <div className='w-full text-center my-9 font-normal text-normal flex flex-row content-center justify-center hover:text-custom-blue cursor-pointer ' onClick={() => setShowLess(false)}>Show More
                                    <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                                </div>
                                :
                                <div className='w-full text-center my-9 font-normal text-normal flex flex-row content-center justify-center hover:text-custom-blue cursor-pointer ' onClick={() => setShowLess(true)}>Show Less
                                    <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                                </div>
                        }
                    </>
                }


            </div>
            <SetPreview
                slug={previewSlug}
                // onNext={onNextSearchResult}
                // onPrevious={onPrevSearchResult}

                previewUrl={getScreenshotUrl(userReducer, previewSlug)}
                pricingPageUrl={getPricingPageUrl(userReducer, previewSlug)}
                showModal={showPreview}
                hideModalHandler={() => setShowPreview(false)} ></SetPreview>

        </>
    );
};

export default SetRecommendations;