/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';  // Import useRef and useEffect
import { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { getCurrentSetCompanies } from '../../helpers/common';
import SetsControl from '../Settings/SetsControl';

import { addSlugToSet, removeSlugFromSet } from '../../redux/actionsPages';



const SetPreview = ({ slug, previewUrl, pricingPageUrl, showModal, hideModalHandler, onPrevious, onNext }) => {
    const [loading, setLoading] = useState(false);
    const addButtonRef = useRef(null);  // Create a ref for the button

    console.log(previewUrl);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                onPrevious();
            } else if (event.key === 'ArrowRight') {
                onNext();
            }
        };

        if (showModal) {
            document.addEventListener('keydown', handleKeyDown);
            const timer = setTimeout(() => {
                addButtonRef.current?.focus();
            }, 100);

            return () => {
                clearTimeout(timer);
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [showModal, onPrevious, onNext]);


    const userReducer = useSelector(state => state.userReducer);

    const closeHandler = () => { hideModalHandler(); }

    let myCompanies = getCurrentSetCompanies(userReducer);

    const slugInSet = (myCompanies.find(c => c.slug === slug) ? true : false);


    const addSlug = (slug) => {
        setLoading(true);
        addSlugToSet(slug, () => setLoading(false));
    }

    const removeSlug = (slug) => {
        setLoading(true);
        removeSlugFromSet(slug, () => setLoading(false));
    }

    return (
        <><Transition.Root show={showModal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={closeHandler}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-start justify-center p-4 sm:h-full my-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-y-scroll max-h-max	  bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:h-full" style={{ width: '90vw' }}>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row-reverse my-2'>
                                        <div className="flex flex-row my-auto  w-full justify-between text-gray-700">
                                            <span className='font-semibold'>{slug}</span>
                                            <a href={pricingPageUrl} target="_blank" className="flex flex-row text-gray-700">
                                                <span className="mr-2 my-auto">Pricing Page</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 my-auto">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='flex flex-row justify-between mt-2 mb-9'>

                                        <div className='flex flex-row space-x-2'>
                                            <button
                                                ref={addButtonRef}  // Set the ref here
                                                disabled={loading}
                                                className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1.5 px-4 border border-blue-600 hover:border-transparent  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                                                onClick={slugInSet ? () => removeSlug(slug) : () => addSlug(slug)}
                                            >
                                                {loading ? "Working..." : (slugInSet ? "Remove from Set" : "Add to Set")}
                                            </button>

                                        </div>
                                        <div className='flex flex-row space-x-1 text-gray-500 align-middle items-center'>

                                            Current Set: <SetsControl />
                                        </div>
                                        <button
                                            onClick={closeHandler}
                                            className='text-3xl font-bold text-gray-700'
                                        >
                                            &#10005; {/* Unicode for big X */}
                                        </button>
                                    </div>

                                    <div></div>
                                    <div className='text-extra-small text-left italic mb-1 text-gray-500'>
                                        {
                                            onNext ? "Use keyboard ← → to move" : ""
                                        }

                                    </div>
                                    <div className="flex justify-center"> {/* Add this div for horizontal center alignment */}

                                        <img src={previewUrl} alt="Google" style={{ height: '100%' }} />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

        </>
    );
};

export default SetPreview;