import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import makeAnimated from 'react-select/animated';

const animeatedComponents = makeAnimated();


const UpgradeModal = ({ show, closeHandler, selectedCompany }) => {

    // companies={myCompanyList}
    // companiesFilter ={companiesFilter}
    // setCompaniesFilter= {setCompaniesFilter}
    //   const [typeFilter, setTypeFilter] = useState(false);

    const startTrial = () => {
        // console.log("Start trial called");
        window.location.href = "/checkout";
    }

    const myCloseHandler = () => {
        closeHandler();
        console.log("Close handler called");
    }


    let dialogHTML = null;
    if (selectedCompany && selectedCompany.slug) {

        dialogHTML = <>
            <div className='flex flex-col gap-x-1 align-middle justify-center mt-12	'>

                <div className='flex flex-col gap-x-1 align-middle text-center mx-auto'>
                    {/* <img className='h-9 w-9' src={selectedCompany.logoUrl}></img><span className='mt-1 '> {selectedCompany.slug} has <span>7 updates</span> on PricingSaaS.</span> */}
                    <span className='mt-1 '>PricingSaaS has been tracking</span>
                    <div className='flex flex-inline gap-x-1 align-middle text-center mx-auto my-5'>
                        <img className='h-9 w-9' src={selectedCompany.logoUrl}></img><span className='mt-1 font-bold'>{selectedCompany.slug}</span>
                    </div>
                    <span> since April 2023.</span>
                    <hr className='my-9' />
                    <span>  Upgrade to see history and insights.</span>
                </div>


            </div>
        </>
    } else {
        dialogHTML =
            <>
                <div className='flex flex-col gap-x-1 align-middle  text-center justify-center mt-12	'>
                    To continue using PricingSaaS, please select a plan and start a free trial.
                </div>
            </>
    }

    return (
        <>
            <Transition.Root show={show} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={myCloseHandler}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className='px-12 py-6 mb-3'>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => myCloseHandler()}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <Dialog.Title as="h3" className="text-lgfont-medium mb-3 leading-6 text-gray-900 ">
                                            Free 7 Day Trial
                                        </Dialog.Title>
                                        <hr />
                                        {dialogHTML}
                                        <div className='mt-9 flex justify-center gap-x-1 align-middle flex-col'>
                                            <button onClick={() => startTrial()} className=" h-10 bg-custom-blue px-12 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Start a Free 7 Day Trial</button>
                                        </div>


                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default UpgradeModal;
