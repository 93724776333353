export const sizes = ["1-10", "11-50", "51-100", "101-250", "250-500", "501-1000", "1001-5000", "5001-10000"];
export const models = ["PLG", "SLG", "Hybrid", "Unknown"];
export const pricingModels = ["Seat Based", "Usage Subscription", "Usage Pay as You Go", "Usage Drawdown", "Tiered", "Performance", "Flat"];
export const personaOptions = [
  { value: 'pricing', label: 'I am doing pricing research' },
  { value: 'leads', label: 'I am looking for sales leads' },
  { value: 'research', label: 'I am doing competitive research' },
  { value: 'competitive', label: 'I am doing market research' },
  { value: 'other', label: 'Other' }
]


export function getCurrentSetIds(userReducer) {

  if (!userReducer || !userReducer.sets || !userReducer.currentSet) return null;

  const currentSetId = userReducer.currentSet.setId; //.currentSetId;
  const currentSet = userReducer.sets.find(set => set.setId === currentSetId);

  if (!currentSet) return null;

  const ownerId = currentSet.workspaceId || currentSet.userId;

  if (!ownerId) return null;

  return { ownerId, setId: currentSetId };
}

export const getScreenshotUrl = (userReducer, slug) => {

  if (!userReducer || !userReducer.companyList) return "";

  const company = userReducer.companyList.find(c => c.slug === slug);
  if (company && company.screenshotUrl) {
    return company.screenshotUrl;
  }

  return "";

}

export const getPricingPageUrl = (userReducer, slug) => {

  if (!userReducer || !userReducer.companyList) return "";

  const company = userReducer.companyList.find(c => c.slug === slug);
  if (company && company.pricingPageUrl) {
    return company.pricingPageUrl;
  }

  return "";

}
export const downloadCSV = (csvData, filename = 'data.csv') => {
  // Sample CSV data as a string
  // const csvData = "id,name,email\n1,John Doe,john@example.com\n2,Jane Doe,jane@example.com";

  // Creating a Blob from the CSV string
  const blob = new Blob([csvData], { type: 'text/csv' });

  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
}

export const getLogoUrl = (userReducer, slug) => {

  if (!userReducer || !userReducer.companyList) return "";

  const company = userReducer.companyList.find(c => c.slug === slug);
  if (company && company.logoUrl) {
    return company.logoUrl;
  }

  return "";

}

export const getVersionStats = (userReducer, slug) => {

  if (!userReducer || !userReducer.currentSet || !userReducer.currentSet.versions) return [];

  const currentSet = userReducer.currentSet;
  const versions = currentSet.versions.find(v => v.slug === slug) ? currentSet.versions.find(v => v.slug === slug).versions : [];
  const lastVersion = versions && versions.length > 0 ? vertsionToDaySince(versions[versions.length - 1].first || versions[versions.length - 1].version) : null;
  const trackingSince = versions && versions.length > 0 ? versionToDate2(versions[0].first || versions[0].version) : null;

  return { versionCount: versions.length, daysOld: lastVersion, trackingSince: trackingSince };
}


export const getOverlappingVersions = (userReducer, slug, version) => {

  if (!userReducer || !userReducer.currentSet || !userReducer.currentSet.versions) return [];

  const currentSet = userReducer.currentSet;
  const versions = currentSet.versions.find(v => v.slug === slug) ? currentSet.versions.find(v => v.slug === slug).versions : [];
  const currentVersion = versions.find(v => v.version === version);

  const overlappingVersions = [];

  if (currentVersion && currentVersion.first && currentVersion.last) {
    versions.forEach(v => {

      if (v.version !== version) {

        if (v.first && v.last) {
          if ((parseInt(v.first) <= parseInt(currentVersion.last)) &&
            (parseInt(v.last) >= parseInt(currentVersion.first))) {
            overlappingVersions.push(`${v.first}-${v.last}`)
          }
        }

      }
    })
  }

  return overlappingVersions;
}

export const getCompanyAttributes = (userReducer, slug) => {

  const slugs = getCurrentSetCompanies(userReducer);
  return slugs.find(c => c.slug === slug);
}


export const getCurrentSetSlugs = (userReducer, slugsFilter = []) => {

  return getCurrentSetCompanies(userReducer, slugsFilter).map(c => c.slug);
}

export const getCurrentSetCompanies = (userReducer, slugsFilter = []) => {


  if (!userReducer || !userReducer.currentSet || !userReducer.currentSet.attributes) return [];


  let attributes = userReducer.currentSet.attributes; //.slice(0, maxpages);


  if (slugsFilter && slugsFilter.length > 0) {
    return attributes.filter(c => slugsFilter.find(cSlug => cSlug === c.slug));
  } else {
    return attributes;;
  }
}


export const getSortedHistory = (versions, companiesFilter) => {

  // console.log(versions);
  const history = [];
  const filteredVersions = companiesFilter && companiesFilter.length > 0 ? versions.filter(v => companiesFilter.includes(v.slug)) : versions;

  filteredVersions.forEach(v => {

    const slug = v.slug;
    const versions = v.versions;

    versions.forEach(v => {
      history.push({ timestamp: v.first || v.version, changeDesc: v.changeDesc, changeType: null, slug: slug, imageUrl: v.imageUrl, first: v.first, last: v.last });
    });
  });

  history.sort((a, b) => {
    return b.timestamp.localeCompare(a.timestamp);
  });

  for (let i = 0; i < history.length; i++) {
    if (i < history.length - 1) {
      history[i].prevImageUrl = history[i + 1].imageUrl;
      history[i].prevTimestamp = history[i + 1].timestamp;
    } else {
      history[i].prevImageUrl = null;
      history[i].prevTimestamp = null;
    }
  }

  return history;
}

export const vertsionToDaySince = (version) => {
  let date = new Date();
  if (version) {

    if (version.toString().length === 8) {
      var year = version.toString().substring(0, 4);
      var month = version.toString().substring(4, 6);
      var day = version.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date = (new Date(year, month - 1, day));
    }
    else {
      date = new Date(version.slice(0, 8));
    }
  }

  // Get current date and time
  const today = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = today - date;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;

}


export const countDays = (version, version2) => {
  let date = new Date();
  if (version) {

    if (version.toString().length === 8) {
      var year = version.toString().substring(0, 4);
      var month = version.toString().substring(4, 6);
      var day = version.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date = (new Date(year, month - 1, day));
    }
    else {
      date = new Date(version.slice(0, 8));
    }
  }

  // Get current date and time
  let date2 = new Date();

  if (version2) {

    if (version2.toString().length === 8) {
      var year = version2.toString().substring(0, 4);
      var month = version2.toString().substring(4, 6);
      var day = version2.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date2 = (new Date(year, month - 1, day));
    }
    else {
      date2 = new Date(version.slice(0, 8));
    }
  }

  // Calculate the difference in milliseconds
  const diffInMs = date2 - date;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;

}


export const versionToDate = (version) => {

  let date = new Date();

  if (version) {

    if (version.toString().length === 8) {
      var year = version.toString().substring(0, 4);
      var month = version.toString().substring(4, 6);
      var day = version.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date = (new Date(year, month - 1, day));
    }
    else {
      date = new Date(version.slice);
    }
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

export const versionToDate2 = (version) => {
  let date = new Date();

  if (version) {
    if (version.toString().length === 8) {
      var year = version.toString().substring(0, 4);
      var month = version.toString().substring(4, 6);
      var day = version.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date = new Date(year, month - 1, day);
    } else {
      date = new Date(version.slice);
    }
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long', // Changed from '2-digit' to 'long' to display the full month name.
    day: '2-digit'
  });
};


export const versionToDate3 = (version) => {

  let date = new Date();

  if (version) {

    if (version.toString().length === 8) {
      var year = version.toString().substring(0, 4);
      var month = version.toString().substring(4, 6);
      var day = version.toString().substring(6, 8);

      // Subtracting 1 from the month because JavaScript months are 0-indexed.
      date = (new Date(year, month - 1, day));
    }
    else {
      date = new Date(version.slice);
    }
  }

  const monthX = date.toLocaleDateString('en-US', { month: 'short' });
  const dayX = date.toLocaleDateString('en-US', { day: 'numeric' });
  return `${monthX} ${dayX}`;
};

export const personaOptionToLabel = (option) => {

  const o = personaOptions.find(po => po.value === option);
  if (o) { return o.label; }
  else { return ''; }
}

export const isFreemiumUser = (userReducer) => {

  if (!userReducer || !userReducer.entitlement) {
    return null;
  }


  return userReducer.entitlement.plan.toLowerCase() === 'free' ? true : false;

}

export const isOverLimit = (userReducer) => {

  if (!userReducer || !userReducer.entitlement) return null;

  return getTotalLicensesUsed(userReducer) >= userReducer.entitlement.maxpages;

}


export const isGrandfatheredFreemiumUser = (userReducer) => {

  const emails = [
    "pricingprophets@gmail.com"
  ];

  if (!userReducer || !userReducer.email) return false;


  return isFreemiumUser(userReducer) && emails.includes(userReducer.email.toLowerCase());
}

export const categoriesToLabel = (categories) => {

  if (!categories) return '';
  console.log(categories);


  if (Array.isArray(categories)) {
    let s = '';
    categories.forEach(element => {
      s += element.value + " ";
    });


    if (s.length > 20) {
      return s.slice(0, 20) + "...";
    }
    return s;
  } else {
    return categories.value;
  }

}


export function getLastSubfolder(url) {
  if (!url) return "";

  try {


    // Remove any trailing slashes from the URL
    url = url.replace(/\/$/, '');

    // Split the URL into segments
    var segments = url.split('/');

    // The second-to-last segment is the last folder name
    var folderName = segments[segments.length - 2];

    var year = folderName.substring(0, 4);
    var month = folderName.substring(4, 6);
    var day = folderName.substring(6, 8);

    // Subtracting 1 from the month because JavaScript months are 0-indexed.
    const d = (new Date(year, month - 1, day)).toDateString();

    if (d.includes("Invalid")) {
      return "";
    }

    return d;
  } catch (e) {

    return "";
  }

  // return folderName;
}



export function getLastSubfolderTimestamp(url) {
  if (!url) return "";

  try {

    // Remove any trailing slashes from the URL
    url = url.replace(/\/$/, '');

    // Split the URL into segments
    var segments = url.split('/');

    // The second-to-last segment is the last folder name
    var folderName = segments[segments.length - 2];

    var year = folderName.substring(0, 4);
    var month = folderName.substring(4, 6);
    var day = folderName.substring(6, 8);

    // Subtracting 1 from the month because JavaScript months are 0-indexed.
    return (new Date(year, month - 1, day)).getTime();
  } catch (e) {
    return 0;
  }


  // return folderName;
}




export const getUserPlan = (entitlement, currentUsage) => {

  console.log(entitlement);
  if (!entitlement) { return "Loading..."; }

  if (entitlement.plan.toLowerCase() === 'free') {
    return "Upgrade to activate";
  }

  return `${entitlement.pagesUsed} out of ${entitlement.maxpages} pricing pages used (${entitlement.plan} Plan)`;

  // else if (entitlement.fullExport) { return "Grwo"; }
  // else { return "Free"; }
}



export const getTotalLicensesUsed = (userReducer) => {

  if (!userReducer) return 0;
  if (!userReducer.sets) return 0;

  return countAllSlugs(userReducer.sets);
}


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const countAllSlugs = (userSets) => {


  const favs = [];
  if (userSets) {
    userSets.forEach(set => {
      set.slugs.forEach(slug => {
        favs.push(slug);
      });

    });
  }
  return [...new Set(favs)].length;
}


export const loadingHTML = (
  <div className="flex h-screen">
    <div className="m-auto">
      <h3>Loading...</h3>
    </div>
  </div>
);