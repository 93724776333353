/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SetPreview from '../components/Sets/SetPreview';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';
import UpgradeModalPublic from './Settings/UpgradeModalPublic';


const SearchGridControl = ({ text, publicSearch = false }) => {
    const userReducer = useSelector(state => state.userReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [showPreview, setShowPreview] = useState(false);
    const [previewSlug, setPreviewSlug] = useState('');
    const [previewPricingPageUrl, setPreviewPricingPageUrl] = useState('');
    const [previewScreenshotUrl, setPreviewScreenshotUrl] = useState('');
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const searchResults = userReducer && userReducer.searchResults || [];
    useEffect(() => {
        // Perform actions on update of previewSlug or searchResults
    }, [previewSlug, searchResults]);

    // if (!userReducer) {
    //     return <>Hmm... something is not right. Hit <a href='/reset' className='text-custom-blue underline'>Reset</a></>;
    // }

    if (!searchResults) return <></>

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(searchResults.length / itemsPerPage);

    const paginate = (pageNumber) => {
        if (publicSearch) {
            setShowUpgradeModal(true);
        }
        else {
            setCurrentPage(pageNumber);
        }
    }

    const onNextSearchResult = () => {
        // Using findIndex to correctly get the index of the current previewSlug
        const searchResultIndex = searchResults.findIndex(sr => sr.slug === previewSlug);

        console.log('onNextSearchResult', searchResultIndex);

        if (searchResultIndex === -1 || searchResultIndex === searchResults.length - 1) {
            return; // either not found or it is the last item
        }

        const nextSearchResult = searchResults[searchResultIndex + 1];

        setPreviewSlug(nextSearchResult.slug);
        setPreviewScreenshotUrl(nextSearchResult.screenshot);
        setPreviewPricingPageUrl(nextSearchResult.url);
    }

    const onPrevSearchResult = () => {
        // Using findIndex to correctly get the index of the current previewSlug
        const searchResultIndex = searchResults.findIndex(sr => sr.slug === previewSlug);

        console.log('onNextSearchResult', searchResultIndex);
        console.log(searchResults);

        if (searchResultIndex === -1 || searchResultIndex === 0) {
            return; // either not found or it is the last item
        }

        const prevSearchResult = searchResults[searchResultIndex - 1];

        setPreviewSlug(prevSearchResult.slug);
        setPreviewScreenshotUrl(prevSearchResult.screenshot);
        setPreviewPricingPageUrl(prevSearchResult.url);
    }

    if (searchResults.length === 0) {
        return (
            <div className="mt-6 mb-9 text-gray-500 italic ">
                {text}
            </div>
        );
    }

    return (
        <>
            <div className="mt-9 text-gray-700 italic flex justify-between">
                Total results: {searchResults.length}
                {/* <button onClick={() => alert('hi')} className='my-auto w-48 text-right text-custom-blue  lg:visible' type="button">Export to CSV</button> */}

            </div>
            <ul role="list" className="divide-y divide-gray-100">
                {currentItems.map((searchResult) => (
                    <li key={searchResult.slug} className="flex justify-between gap-x-6 py-5"
                        onClick={() => {
                            if (publicSearch) {
                                window.location = `/pcompany?slug=${searchResult.slug}`;
                            } else {
                                setPreviewSlug(searchResult.slug);
                                setPreviewScreenshotUrl(searchResult.screenshot);
                                setPreviewPricingPageUrl(searchResult.url);
                                setShowPreview(true);
                            }

                        }}>
                        <div className="flex min-w-0 gap-x-4">
                            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={searchResult.logoUrl} alt="" />
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{searchResult.slug}
                                    <span className='text-gray-500 ml-2 font-normal'>{searchResult.employees ? `(${searchResult.employees} emp.)` : ''}</span></p>
                                <p className="text-sm mt-1 truncate leading-5 text-gray-700" dangerouslySetInnerHTML={{ __html: searchResult.description.replaceAll("<em>", "<em class='font-bold'>") }}></p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Pagination controls */}
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <a
                        href="#"
                        className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                        onClick={(e) => {
                            if (publicSearch) {
                                setShowUpgradeModal(true);
                            } else {
                                if (currentPage !== 1) {
                                    setCurrentPage(currentPage - 1);
                                }
                            }

                            e.preventDefault();
                        }}>
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                        Previous
                    </a>
                </div>

                {[...Array(totalPages).keys()].map(number => (
                    <div className="hidden md:-mt-px md:flex" key={number + 1} onClick={() => paginate(number + 1)}>
                        <a
                            href="#"
                            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${currentPage === number + 1 ? 'font-bold text-gray-900' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                        >
                            {number + 1}
                        </a>
                    </div>
                ))}

                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <a
                        href="#"
                        className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${currentPage === totalPages ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                        onClick={(e) => {
                            if (publicSearch) {
                                setShowUpgradeModal(true);
                            } else {
                                if (currentPage !== totalPages) {
                                    setCurrentPage(currentPage + 1);
                                }
                            }

                            e.preventDefault();
                        }}>
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
                    </a>
                </div>
            </nav>

            <SetPreview
                slug={previewSlug}
                previewUrl={previewScreenshotUrl}
                pricingPageUrl={previewPricingPageUrl}
                onNext={onNextSearchResult}
                onPrevious={onPrevSearchResult}
                showModal={showPreview}
                hideModalHandler={() => setShowPreview(false)} ></SetPreview>

            <UpgradeModalPublic show={showUpgradeModal}
                closeHandler={() => setShowUpgradeModal(false)} />
        </>
    );
};


export default SearchGridControl;