import { React, useState } from 'react';
import { callPutApi } from '../helpers/apiHelper';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

const ShareModal = ({ comparedCompanies, handleShareModal }) => {
  const [reportURL, setReportURL] = useState('');
  let sharedCompanies = { companies: [] };

  comparedCompanies.map((company, index) => {
    return sharedCompanies.companies.push(company.companyId);
  });

  const callback = async (data) => {
    console.log(data);
    if (data.message !== 'success!!') {
      return callbackError();
    }
    let newLink = await data.url;
    console.log('Compared Companies Report submitted!');
    setReportURL(newLink);
  };

  const callbackError = (data) => {
    console.log('ERROR SHARE MODAL SERVER - CALLBACKERROR');
    alert('A minimum of 2 companies is required to share a report');
    handleShareModal();
  };

  const copyAlert = () => {
    navigator.clipboard
      .writeText(reportURL)
      .then(() => {
        alert('successfully copied');
      })
      .catch(() => {
        alert('something went wrong');
      });
    console.log('copied clicked!');
  };

  callPutApi(
    process.env.REACT_APP_AUDIENCE + '/scraper/generateCompareReport',
    sharedCompanies,
    callback,
    callbackError
  );

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform
                            transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="header-modal py-3 px-4 bg-blue-400 text-white font-semibold shadow-lg text-lg">
              Share
            </div>
            <div className={`mt-6 mb-10 ml-4 container`}>
              <div className="flex items-center" autoComplete="off">
                <div className="relative w-full">
                  <h2 className="flex justify-center text-lg">
                    {reportURL.length === 0 ? (
                      <button
                        disabled
                        type="button"
                        class="py-2.5 px-5 mr-2 text-xl font-medium text-gray-900 bg-white hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          />
                        </svg>
                        Loading...
                      </button>
                    ) : (
                      'Share your report with the link below:'
                    )}
                  </h2>
                  <div className="body-modal p-4 ">
                    <div className="content ml-0 flex mr-4">
                      {reportURL.length === 0 ? (
                        ''
                      ) : (
                        <div className="flex">
                          <div
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                                    focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                                    dark:focus:ring-blue-500 dark:focus:border-blue-500 text-center"
                          >
                            {reportURL}
                          </div>
                          <ClipboardDocumentListIcon
                            className="cursor-pointer block h-6 w-6 m-auto ml-4"
                            style={{ fill: '#e7e5e4' }}
                            onClick={() => copyAlert()}
                          ></ClipboardDocumentListIcon>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-modal px-4 pt-2.5 pb-4">
              <div className="flex flex-row-reverse mt-14">
                <button
                  className="ml-2 bg-blue-500 text-white px-8 rounded-lg py-2 hover:bg-blue-600 t"
                  onClick={() => handleShareModal()}
                >
                  Close
                </button>
                {/* <button
                            className="hover:bg-gray-200 hover:text-gray-900 text-gray-600 px-8 rounded-lg py-2"
                            onClick={() => handleShareModal()}>
                            Cancel
                        </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
