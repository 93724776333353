import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { resetFavFilter } from '../../redux/actionsFilters';
import { addSlugToSet } from '../../redux/actionsPages';
import Autosuggest from 'react-autosuggest';
import AddCompanyModal from "../../controls/addCompanyModal";
import { getCurrentSetSlugs } from '../../redux/actionsUsers';
import { isFreemiumUser, isGrandfatheredFreemiumUser, isOverLimit } from '../../helpers/common';
import UpgradeModal  from './UpgradeModal';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, companies) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let retValue = inputLength === 0 ? [] : companies.filter(company =>
            company.slug.toLowerCase().includes(inputValue.toLowerCase())
        ); // .slice(0, 10);

    retValue.sort((a, b) => {

        if (a.slug === value) {
            // console.log(a, value);
            return -1;
        };
        if (b.slug === value) {
            // console.log(b, value);
            return 1;
        };

        return a.slug.localeCompare(b.slug)
    });
    retValue = retValue.slice(0,10);


    if (value) {
        retValue.push({ slug : value, add : true, logoUrl : `/icons/add.png`} );
    }

    return retValue;
};


// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {

    if (suggestion.add) {
        return (
        <div className='flex flex-inline gap-x-1 align-middle italic '>
             <img className='h-5 w-5 align-middle mr-3' src="/icons/add.png"></img>Add "{suggestion.slug}"

        </div>)
    }

    return (
        <div className='flex flex-inline gap-x-1 align-middle	'>
            <img className='h-9 w-9' src={suggestion.logoUrl}></img><span className='mt-1'> {suggestion.slug}</span>
        </div>
    )
};

// TODO: add API that sets local userSettings upon render
const SearchControl = () => {

    const userReducer = useSelector(state => state.userReducer);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(false);

    const [addLogoUrl, setAddLogoUrl] = useState("/icons/glass.png");

    if (!userReducer || !userReducer.companyList) { return <></>; }

    const myCompanies = userReducer.myCompanies ? userReducer.myCompanies.sort((a, b) => a && a.slug && b && b.slug ? a.slug.localeCompare(b.slug) : false) : [];
    // const companies = userReducer.companyList.filter(c => !myCompanies.find(mC => mC.slug === c.slug));//.map(c => c.slug);
    const currentSlugs = getCurrentSetSlugs(userReducer);
    const companies = userReducer.companyList.filter(c => !currentSlugs.find(cSlug => cSlug === c.slug));//.map(c => c.slug);



    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = suggestion => {

        return suggestion.slug;

    }

    const changeHandler = (event, { newValue, method }) => {
        console.log("changed");
    }


    const getMyCompaniesLoading = () => {

        let retValue = [];
        if (userReducer && userReducer.favourites && Array.isArray(userReducer.favourites)) {

            const favs = userReducer.favourites;

            favs.forEach(fav => {

                // Only include not found
                if (!myCompanies.find(c => c.slug === fav)) {
                    retValue.push({
                        slug: fav,
                        lastChange: '--',
                        logoUrl: "/icons/loading.jpeg"
                    })
                }
            })
            // userReducer.myCompanies myCompanies
        }

        retValue.sort((a, b) => a && a.slug && b && b.slug ? a.slug.localeCompare(b.slug) : false);

        return retValue;
    }

    const myCompaniesLoading = getMyCompaniesLoading();

    const onSelectedHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        const value = suggestionValue;
        // const company = userReducer.companyList.find(c => c.slug === newValue)

        // if (isOverLimit(userReducer) || (isFreemiumUser(userReducer) && !isGrandfatheredFreemiumUser(userReducer))) {

        //     // alert("Upgrade the paid first!");
        //     setSelectedCompany({});
        //     const selectedCompany = companies.find(c => c.slug === value);
        //     if (selectedCompany) setSelectedCompany(selectedCompany);

        //     setShowUpgradeModal(true);
        //     setValue("");

        // } else
        if (companies.find(c => c.slug === value)) {
            console.log("Found it!");
            setValue("");
            addSlugToSet(value, () => setValue(""));

            setAddLogoUrl("/icons/glass.png");
        } else {
            setValue(value.replace("https://", ""));
            setShowAddModal(true);
        }
    }

    const onChange = (event, { newValue, method }) => {

        // console.log(method);
        setValue(newValue)

    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, companies));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const addChangeHandler = (value) => {
        console.log(value);


    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Type a company name to add...',
        value,
        onChange
    };

    function resetFilter() {
        resetFavFilter();
    }

    function addCompanyCheck() {

        console.log(value);

        if (companies.find(c => c.slug === value)) {
            console.log("Found it!");
            addSlugToSet(value);
            setValue("");
            setAddLogoUrl("/icons/glass.png");
        } else {
            setShowAddModal(true);
        }
    }


    // useEffect(() => {
    //     resetFilter();
    //     console.log(isEditing);
    // }, [isEditing]);

    return (
        <>
            <div className='flex flex-inline flex-row align-middle w-full gap-x-2'>
                <img className='h-9 w-9 hidden md:block' src="/icons/glass.png" alt="" />
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={onSelectedHandler}
                    renderSuggestion={renderSuggestion}
                    onChange={changeHandler}
                    inputProps={inputProps}
                />

            </div>
            <AddCompanyModal show={showAddModal} companies={[] } filteredCompanies={[] } closeHandler={() => { setShowAddModal(false); setValue(''); }} defaultValue={value}  />
            {/* <UpgradeModal
                show={showUpgradeModal}
                closeHandler={() => setShowUpgradeModal(false)}
                selectedCompany={selectedCompany}
            >

            </UpgradeModal> */}

        </>
    );
};


export default SearchControl;