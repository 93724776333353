import React from "react";
import { getCheckoutUrl } from "../redux/actionsUsers";
import { loadUser, invalidate } from '../redux/actionsUsers';

export class SuccessControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            loading: false
        }
    }

    componentDidMount() {
        //

        console.log('in in success control');
        invalidate();

        // loadUser();


        // const callback = (url) => {
        //     console.log('url', url);

        //     this.setState({ url, loading: false });
        // }
        // getCheckoutUrl(callback);
    }

    render() {

        return (
            <>
                <div className="flex mt-64">
                    <div className="m-auto text-center">
                        <h3>Congratulations you now have full access to pricingsaas.com!</h3>
                        <div className="text-blue-500 mt-6"><a href="/">Get Started</a></div>
                    </div>
                </div>
            </>
        )
    }
}

export default (SuccessControl);