export function camelize(str) {
    // return str;
    if (!str) { return ""; }
    return str.replace(/(?:^\w|[A-Z]\s+)/g, function (match, index) {
        if (+match === 0) return "";
        return index === 0 ? match.toUpperCase() : match.toUpperCase();
    });
  }


export const PAGE_TYPE = {
    EXPLORE: 'explore',
    FAVS: 'favs',
    DIRECT: 'direct'
  };

export const getPageType = () => {

const pagePath = window.location.pathname;
if (pagePath.includes('explore')) {
    return PAGE_TYPE.EXPLORE;
} else if (pagePath.includes('favourites')) {
    return PAGE_TYPE.FAVS;
}

return PAGE_TYPE.EXPLORE;

}
