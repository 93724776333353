/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentSetSlugs } from '../../redux/actionsUsers';
import 'react-animated-slider/build/horizontal.css';
import 'lightbox.js-react/dist/index.css'
import { SlideshowLightbox, initLightboxJS } from 'lightbox.js-react'

const SetUVP = ({ backHandler, companiesFilter, setCompaniesFilter, showDemo }) => {

    const userReducer = useSelector(state => state.userReducer);

    useEffect(() => {
        initLightboxJS("A75F-6B0F-E76B-B2B5", "individual");
    });

    if (!userReducer || !userReducer.currentSet || !userReducer.currentSet.attributes) { return <></>; }

    const myCompanies = [];

    userReducer.currentSet.attributes.forEach((attr) => {

        // get latest version
        const versionSet = userReducer.currentSet.versions.find(v => v.slug === attr.slug);

        if (versionSet && versionSet.versions) {
            const latestVersion = versionSet.versions[versionSet.versions.length - 1];

            if (latestVersion) {
                myCompanies.push({ slug: attr.slug, pricingPageScreenshotUrl: latestVersion.imageUrl, pricingPageUrl: attr.url });
            }
        }
    });

    console.log(myCompanies);

    if (myCompanies.length === 0) {
        return <>
            <div className="flex  ">
                <div className="m-auto">
                    <div className="text-gray-700 my-32">Start by <a className='underline cursor-pointer' href='/set'>adding</a> some companies...</div>
                </div>
            </div></>
    }

    // const userReducer
    if (userReducer.loadingCompanies) {
        {
            return <>
                <div className="flex  ">
                    <div className="m-auto">
                        <div className="text-gray-700 my-32">Loading set... ⌛</div>
                    </div>
                </div></>
        }
    }

    const openAllTabs = () => {

        const filteredCompanies = myCompanies.filter(c => !c.hide); //myCompanies.filter(c => companiesFilter.find(cf => cf === c.slug));
        console.log(filteredCompanies);
        for (let i = 0; i < filteredCompanies.length; i++) {
            window.open(filteredCompanies[i].pricingPageUrl, '_blank');
        }
    };

    return (
        <>
            {/* <h1 className="text-2xl font-bold mt-12 ">Pricing Page Screenshots</h1> */}
            <div className='flex flex-row justify-between' >
                {backHandler &&
                    <div className='flex flex-row text-gray-700 mt-12  cursor-pointer' onClick={() => backHandler()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        Back
                    </div>
                }

                <div className='pt-9 flex flex-row text-custom-blue'><a onClick={() => openAllTabs()} className=' underline '>Open All Pages</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ml-1 w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>

                </div>
            </div>
            <div className="mt-16">
                <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto' showThumbnails={true} fullScreen={true}>
                    {myCompanies.map((company, index) => (
                        <img className="w-full h-96 " src={company.pricingPageScreenshotUrl.replace("fullpage", "viewport")} />
                    ))}
                </SlideshowLightbox>
            </div>
        </>
    );
};

export default SetUVP;