import {
  LOAD_SET,
  ADDING_COMPANY_TO_SET
} from "./actionTypes";
import { store } from './store';
import { callGetApi, callPutApi } from "../helpers/apiHelper";
import { setLoadingCompanies } from "./actionsUsers";
import { getCurrentSetSlugs, getCurrentSetIds } from "../helpers/common";

export const loadSetPageInfo = async (slugsParam, onSuccessCallback, onFailureCallback, backgroundLoad = false) => {

  console.log('loadSetPlans');

  if (!backgroundLoad) setLoadingCompanies(true);

  const callback = (response, dispatch, state, onSuccessCallback) => {

    console.log(response);
    console.log(response.setName);
    if (response) {

      const ppPlans = response.plans ? JSON.parse(response.plans) : [];
      const ppAttributes = response.ppAttributes ? JSON.parse(response.ppAttributes) : [];
      const ppVersions = response.ppVersions ? JSON.parse(response.ppVersions) : [];
      const setRecos = response.setRecos ? JSON.parse(response.setRecos) : [];
      const setDismissed = response.setDismissed ? JSON.parse(response.setDismissed) : [];

      const setId = response.setId || state.userReducer.currentSetId;
      let setName = response.setName;
      if (!setName) {
        const currentSet = state.userReducer.sets.find(s => s.setId === setId);
        setName = currentSet ? currentSet.name : '';
      }
      // const

      dispatch({
        type: LOAD_SET,
        payload: {
          plans: ppPlans,
          attributes: ppAttributes,
          versions: ppVersions,
          recos: setRecos,
          dismissedRecos: setDismissed,
          name: response.setName,
          setId: response.setId
        }
      });

      if (onSuccessCallback) {

        onSuccessCallback();
      } else {
        console.log("No onSuccessCallback");
      }

      setLoadingCompanies(false);
    }
  }

  const callbackError = (msg) => {
    console.log("Error:", msg)
    if (onFailureCallback) {
      onFailureCallback(msg);
    }
    setLoadingCompanies(false);

  }

  const fn = (dispatch, getState) => {
    if (!getState().userReducer || !getState().userReducer.sets) {
      callbackError("No sets found");
      return;
    }

    // See if they really need to be re-loaded
    if (!getState().userReducer || !getState().userReducer.currentSetId) {
      callbackError("No current set id");
      return;
    } else {
      const currentSetId = getState().userReducer.currentSet ? getState().userReducer.currentSet.setId : getState().userReducer.currentSetId;
      const currentSet = getState().userReducer.sets.find(s => s.setId === currentSetId);
      const setOwner = currentSet ? currentSet.userId : null;

      if (!currentSet || !setOwner) {
        callbackError("Current set not found");
        return;
      }

      const param = slugsParam ? {
        slugs: slugsParam,
        setId: currentSetId,
        ownerId: setOwner
      } :
        {
          setId: currentSetId,
          ownerId: setOwner
        };

      callPutApi(`${process.env.REACT_APP_ENDPOINT_URL}/getPageInfo`,
        param,
        (data) => callback(data, dispatch, getState(), onSuccessCallback),
        () => callbackError()
      );
    }
  };

  store.dispatch(fn);
}

export const removeSlugFromSet = async (slug, onSuccessCallback) => {

  if (!slug) {
    throw new Error('slug cannot be empty.');
  }

  const callbackError = (data) => {
    console.log('Error');
  };

  const safeCallback = () => {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const fn = (dispatch, getState) => {
    const state = getState();

    if (!state.userReducer || !state.userReducer.currentSet) return;

    const currentSet = state.userReducer.currentSet;
    if (currentSet && currentSet.attributes) {

      console.log("Removing locally first");
      currentSet.attributes = currentSet.attributes.filter(a => a.slug !== slug);
      currentSet.plans = currentSet.plans.filter(a => a.slug !== slug);
      currentSet.versions = currentSet.versions.filter(a => a.slug !== slug);

      dispatch({
        type: LOAD_SET,
        payload: {
          plans: currentSet.plans,
          attributes: currentSet.attributes,
          versions: currentSet.versions,
          setId: currentSet.setId,
          name: currentSet.setName,
          recos: currentSet.recos,
          dismissedRecos: currentSet.dismissedRecos
        }
      });

      // const currentSetId = state.userReducer.currentSetId;
      const currentSetIds = getCurrentSetIds(getState().userReducer);

      const slugs = currentSet.attributes.map(a => a.slug).filter(s => s !== slug);
      const param = {
        slugs: slugs,
        setId: currentSetIds.setId,
        ownerId: currentSetIds.ownerId
      };

      console.log(param);

      callPutApi(`${process.env.REACT_APP_ENDPOINT_URL}/getPageInfo`,
        param,
        (data) => safeCallback(),
        () => callbackError()
      );
    }
  }

  store.dispatch(fn);
}


export const addSlugToSet = async (slug, onSuccessCallback) => {
  if (!slug) {
    throw new Error('slug cannot be empty.');
  }

  console.log('addSlugToSet');
  const fn = async (dispatch, getState) => {

    const currentSet = getState().userReducer.currentSet;

    if (currentSet) {

      const modifiedSet = { ...currentSet };
      modifiedSet.recos = modifiedSet.recos.filter(r => r.slug !== slug);
      dispatch({
        type: LOAD_SET,
        payload: modifiedSet
      });

    }

    dispatch({
      type: ADDING_COMPANY_TO_SET,
      payload: {
        loading : true
      }
    })

    const slugs = getCurrentSetSlugs(getState().userReducer);
    if (!slugs.includes(slug)) {
      slugs.push(slug);
      loadSetPageInfo(slugs, onSuccessCallback, null, true);
    }
  }

  return store.dispatch(fn);
}


// export const loadSetRecommendations = async (onSuccessCallback, onFailureCallback) => {

//   console.log('loadSetRecommendations');

//   const callback = (response, dispatch, state, onSuccessCallback) => {

//     console.log(response);
//     if (response) {


//       // dispatch({
//       //   type: LOAD_SET_RECOMMENDATIONS,
//       //   payload: {
//       //     recommendations: response.recommendations
//       //   }
//       // });

//       if (onSuccessCallback) {

//         onSuccessCallback();
//       } else {
//         console.log("No onSuccessCallback");
//       }

//     }
//   }

//   const callbackError = (msg) => {
//     console.log("Error:", msg)
//     if (onFailureCallback) {
//       onFailureCallback(msg);
//     }

//   }

//   const fn = (dispatch, getState) => {
//     if (!getState().userReducer || !getState().userReducer.sets) {
//       callbackError("No sets found");
//       return;
//     }

//     // See if they really need to be re-loaded
//     if (!getState().userReducer || !getState().userReducer.currentSetId) {
//       callbackError("No current set id");
//       return;
//     } else {
//       const currentSetId = getState().userReducer.currentSetId;
//       const currentSet = getState().userReducer.sets.find(s => s.setId === currentSetId);
//       const setOwner = currentSet ? currentSet.userId : null;

//       if (!currentSet || !setOwner) {
//         callbackError("Current set not found");
//         return;
//       }


//       const param = {
//         setId: currentSetId,
//         ownerId: setOwner
//       };

//       callPutApi(`${process.env.REACT_APP_ENDPOINT_URL}/recommendations`,
//         param,
//         (data) => callback(data, dispatch, getState(), onSuccessCallback),
//         () => callbackError()
//       );
//     }
//   };

//   store.dispatch(fn);
// }