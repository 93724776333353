import React, { useState } from 'react';
import { Fragment } from 'react';
import { useRef, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const EditSetModal = ({ show, title, closeHandler, successHandler, removeHandler, currentName, currentExclude, currentPublicFlag }) => {

    const [name, setName] = useState(currentName);
    const [exclude, setExclude] = useState(currentExclude);
    const [publicFlag, setPublicFlag] = useState(currentPublicFlag);
    const [toggle, setToggle] = useState(false);

    if (show && !toggle) {
        setToggle(true);
        setName(currentName);
        setPublicFlag(currentPublicFlag);
        setExclude(currentExclude);

    }

    if (!show && toggle) {
        setToggle(false);
        setName(currentName);
        setPublicFlag(currentPublicFlag);
        setExclude(currentExclude);
    }

    const myCloseHandler = () => {

        // setName(currentName);
        closeHandler();
    }

    return (
        <>
            <Transition.Root show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => myCloseHandler()}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className='px-12 pt-6 pb-12'>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => myCloseHandler()}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            {title}
                                            <hr />
                                        </Dialog.Title>
                                        <>
                                            <div>
                                                <label htmlFor="setName" className="block text-sm font-medium leading-6 text-gray-900 mt-12">
                                                    Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        name="setName"
                                                        id="setName"
                                                        autoFocus={true}
                                                        className="block w-full  border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder={currentName}
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="my-6 flex items-center">
                                                    <input
                                                        id="notifications"
                                                        aria-describedby="comments-description"
                                                        name="notifications"
                                                        type="checkbox"
                                                        onClick={() => setPublicFlag(!publicFlag)}
                                                        checked={publicFlag}
                                                        className="h-4 w-4 mr-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    Public
                                                </div>
                                                <div className="my-6 flex items-center">
                                                    <input
                                                        id="notifications"
                                                        aria-describedby="comments-description"
                                                        name="notifications"
                                                        type="checkbox"
                                                        onClick={() => setExclude(!exclude)}
                                                        checked={exclude}
                                                        className="h-4 w-4 mr-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                    Exclude from Notifications
                                                </div>

                                                <hr />
                                                <div className='w-full mt-9 '>
                                                    {!title.includes("Add") &&
                                                        <button onClick={() => removeHandler(name)} className="h-9 w-32 float-left shadow-sm bg-white px-5 py-1.5 text-sm font-semibold text-red-600  hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                                            Delete</button>
                                                    }
                                                    <button onClick={() => successHandler(name, exclude, publicFlag)} className="h-9 w-32 float-right  bg-custom-blue px-5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                                        {title.includes("Add") ? "Add" : "Update"}</button>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default EditSetModal;