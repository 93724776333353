/* eslint-disable no-unused-vars */
import { React, useState, useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const PagerComponent = ({currentPage, totalPages, itemsPerPage, totalItems, clickHandler, showFilterHandler}) => {

  const myClickHandler = (str) => {

    if (str === "...") {
        console.log("cannot click on ...");
    } else {
        const pageNumber = parseInt(str)-1;


        if (pageNumber === currentPage) {
            console.log("cannot click on current page");
        } else if (pageNumber < 0) {
            console.log("cannot go negative");
        } else if (pageNumber >= totalPages) {
            console.log("cannot go more than number of pages");
        } else {
            clickHandler(pageNumber);
        }
    }
  }

  console.log(totalPages)
  const pagesMap = [];

    if (totalPages <= 10) {
        for (let i=0; i<totalPages; i++) {
            pagesMap.push(i+1);
        }
    } else {

        pagesMap.push(1);

        if (currentPage < 4) {
            pagesMap.push(2);
            pagesMap.push(3);
            pagesMap.push(4);
            pagesMap.push(5);
            pagesMap.push("...");
            pagesMap.push(totalPages);
        } else if (currentPage >= totalPages-5) {
            pagesMap.push("...");
            pagesMap.push(totalPages-3);
            pagesMap.push(totalPages-2);
            pagesMap.push(totalPages-1);
            pagesMap.push(totalPages);
        } else {
            pagesMap.push("...");
            pagesMap.push(currentPage-1);
            pagesMap.push(currentPage);
            pagesMap.push(currentPage+1);
            pagesMap.push(currentPage+2);
            pagesMap.push(currentPage+3);
            pagesMap.push("...");
            pagesMap.push(totalPages);
        }
    }

  const showingStart = totalItems > 0 ? currentPage * itemsPerPage + 1 : 0;
  const showingEnd = Math.max(Math.min(totalItems, showingStart+itemsPerPage-1),0);

  return (
    <>
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          onClick={() => myClickHandler( currentPage )}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </a>
        <button onClick={showFilterHandler}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
              </svg>
          </button>
        <a
          onClick={() => myClickHandler( currentPage+2 )}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex flex-row align-middle">
          <p className=" text-gray-700">
            Showing <span className="font-medium">{showingStart}</span> to <span className="font-medium">{showingEnd}</span> of{' '}
            <span className="font-medium">{totalItems}</span> companies
          </p>
          <div className="flex flex-row ml-3 text-blue-500">

              <button onClick={showFilterHandler}
              className="flex flex-row"
            >
              Change Filter
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 ml-1 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
              </svg>
            </button>
          </div>

          {/* <div className="flex flex-row items-center justify-center mt-3 pr-5">
            <button onClick={() => this.resetFilter()} className="text-m text-blue-500">Reset</button>
          </div> */}
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a
              href="#"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" onClick={() => myClickHandler( currentPage )}/>
            </a>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

            { pagesMap.map( (i, index) => (
                i === (currentPage+1) ?
                    <a onClick={() => myClickHandler(i)}
                    className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                    >{i}</a>
                :
                    <a onClick={() => myClickHandler(i)}
                    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                    >{i}</a>
            ))
            }
            <a
              href="#"
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" onClick={() => myClickHandler( currentPage+2 )} />
            </a>
          </nav>
        </div>
      </div>
    </div>

    </>
  )
};

export default PagerComponent;

