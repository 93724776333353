import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import EditSetModal from './EditSetModal';
import { loadWorkspace, createMagicLink, removeUser, processMagicLink } from '../../redux/actionsWorkspaces';
import MagicLinkModal from './MagicLinkModal';

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

const InviteControl = () => {

    const [text, setText] = useState("Working on joining workspace...");
    const [firstLoad, setFirstLoad] = useState(true);
    const userReducer = useSelector(state => state.userReducer);
    const queryParams = new URLSearchParams(window.location.search);

    if (!userReducer) return (<></>);

    // fe1a4b35-5a71-4f83-b7fa-d0dd4e4b090a
    const workspaceId = queryParams.get('workspaceId'); // `58eec26e-5cd6-4c6c-9b99-0ed72326960e`;
    const secretKey = queryParams.get('secretKey'); // `d1310f50-fcd3-44e0-b1a9-81147975c629`;

    if (firstLoad) {

        setFirstLoad(false);
        if (!workspaceId || !secretKey) {
            setText("Invalid invite URL. Did you copy and paste it incorrectly?");
        } else {
            processMagicLink(workspaceId, secretKey, (msg) => setText(msg), (msg) => setText(msg));
        }
    }



    return (
        <>
            {text}
            { text.includes("Successfully") &&
            <div className='my-9 w-full'><a href='/set' className='text-custom-blue underline '>Go to Dashboard</a> </div>}
        </>
    );
};

export default InviteControl;