/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { isGrandfatheredFreemiumUser, isFreemiumUser } from '../../helpers/common';
import UpgradeCTA from '../Sets/UpgradeCTA';
import SetsControl from './SetsControl';
import { Cog8ToothIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { getCurrentSetSlugs } from '../../redux/actionsUsers.js'
import { refresh } from '../../redux/actionsUsers';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MainHeader = (props) => {
    const userReducer = useSelector(state => state.userReducer);

    if (!userReducer) return <></>;

    console.log(JSON.stringify(props));

    const companiesCount = getCurrentSetSlugs(userReducer).length;

    return (
        <>
            <h1 className='font-bold text-2xl  flex flex-col md:flex-row items-center justify-between  gap-y-6	'>
                <div className=' text-2xl flex flex-row items-center w-full '>
                    <a href="/set"><img src="https://pricingsaas.com/logo.png" className='h-11 w-11 mr-9' alt="Logo"></img></a>

                    <div className='flex flex-row gap-x-2'>
                        <a href="/set"
                            className={classNames(
                                window.location.href.includes('/set') && ! window.location.href.includes('/settings')? 'font-semibold' : 'font-light',
                                'text-custom-blue'
                            )}
                        >Sets
                        </a>
                        <span className='font-light'>|</span>
                        <a href="/search"
                            className={classNames(
                                window.location.href.endsWith('/search') ? 'font-semibold' : 'font-light',
                                'text-custom-blue'
                            )}
                        >Search</a>

                    </div>

                </div>
                <div className=' flex md:w-1/2 w-full  justify-end  items-center content-center'>

                    {isGrandfatheredFreemiumUser(userReducer) ?
                        <UpgradeCTA /> :
                        <>
                            {!isFreemiumUser(userReducer)  && !props.hideSet? <SetsControl /> : <> </>}
                        </>
                    }
                    {/* <button
                        className='text-sm font-medium leading-none  text-custom-blue'
                        onClick={() => refresh()}>
                        <ArrowPathIcon className='h-5 w-5 text-custom-blue'></ArrowPathIcon>

                    </button> */}
                    <a href="/settings" className='text-custom-blue font-medium text-base flex items-center'> <Cog8ToothIcon className="cursor-pointer block h-6 w-6 m-auto ml-4 text-gray-600"></Cog8ToothIcon></a>
                </div>


            </h1>
        </>
    )
}

export default MainHeader;