/* eslint-disable no-unused-vars */
import Selection from './Selection';
import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserPlan } from '../../helpers/common';
import { useAuth0 } from '@auth0/auth0-react';
import { setWeeklyDigest, setEmailAlerts } from '../../redux/actionsUsers';
import AddCompanyModal from "../../controls/addCompanyModal";
import { isFreemiumUser } from '../../helpers/common';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { resetReducers } from '../../redux/actionsCompanies';
import MainHeader from '../Settings/MainHeader';
import WorkspaceControl from './WorkspaceControl';
import AdminControl from './AdminControl';
import { loadUser } from '../../redux/actionsUsers';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const myTabs = [
    { name: 'Settings', href: '#', current: true },
    { name: 'Workspace', href: '#', current: false }
];


const getAlerts = (value) => {
    return value === false ? "Off" : "On";
}

const getDigest = (value) => {
    return value === false ? "Off" : "On";
}
// TODO: add API that sets local userSettings upon render

const countAllSlugs = (userSets) => {


    const favs = [];
    if (userSets) {
        userSets.forEach(set => {
            set.slugs.forEach(slug => {
                favs.push(slug);
            });

        });
    }
    return [...new Set(favs)].length;
}

const Settings = () => {

    const [currentTab, setCurrentTab] = useState(0);

    const userReducer = useSelector(state => state.userReducer);
    const entitlement = useSelector((state) => state.userReducer ? state.userReducer.entitlement : '');
    const [value, setValue] = useState('');
    const [initLoad, setInitLoad] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const { logout, user } = useAuth0();

    if (!userReducer || !userReducer.companyList) { return <></>; }

    const isFree = isFreemiumUser(userReducer);

    const isAdmin = userReducer.workspace ? userReducer.workspace.isAdmin : true;
    const isAdminMessage = userReducer.workspace ? ` managed by ${userReducer.workspace.ownerEmail}` : '';

    if (user && (user.sub === 'google-oauth2|107496574604144355840' || user.sub === 'google-oauth2|117759775385286114680')) {

        if (!myTabs.find((tab) => tab.name === 'Admin')) {
            myTabs.push({ name: 'Admin', href: '#', current: false });
        }
    }

    if (initLoad) {

        loadUser();
        setInitLoad(false);
    }

    return (
        <>
            <div>

                <div className="w-full mb-3 p-4 bg-white border  sm:p-8 ">
                    <MainHeader />

                    {/* tabs begin */}
                    <div className='mt-12'>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                                id="tabs"
                                name="tabs"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                defaultValue={myTabs.find((tab) => tab.current).name}
                                onChange={(event) => setCurrentTab(parseInt(event.target.value))}
                            >
                                {myTabs.map((tab, tabIndex) => (
                                    <option value={tabIndex} key={tab.name} > {tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {myTabs.map((tab, tabIndex) => (
                                        <a
                                            key={tab.name}
                                            href="#"
                                            onClick={() => setCurrentTab(tabIndex)}
                                            className={classNames(
                                                tabIndex === currentTab
                                                    ? 'border-blue-500 text-custom-blue'
                                                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                                'flex whitespace-nowrap border-b-2 py-4 px-1 text-lg font-medium  '
                                            )}
                                            aria-current={tabIndex === currentTab ? 'page' : undefined}
                                        >
                                            {tab.name === 'Companies' ? `${tab.name}` : tab.name}
                                            {tab.count ? (
                                                <span
                                                    className={classNames(
                                                        tabIndex === currentTab ? 'bg-blue-100 text-custom-blue' : 'bg-gray-100 text-gray-900',
                                                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                                    )}
                                                >
                                                    {tab.count}
                                                </span>
                                            ) : null}
                                        </a>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="flow-root mt-16">

                        {currentTab === 0 &&
                            <ul className="divide-y divide-gray-200 ">
                                <>

                                    {isFree ?
                                        <>
                                            <Selection title={getUserPlan(entitlement)} img={"/icons/credit-card.png"}>
                                                {/* <p className='text-sm font-medium leading-none text-gray-900'>{getUserPlan(entitlement)}</p> */}
                                                <div>
                                                    <div className='py-3'>
                                                        <a href='/checkout' className="inline-flex items-center    bg-custom-blue px-6 py-2.5 text-xs font-bold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-600 cursor-pointer">&nbsp;Upgrade&nbsp;</a>
                                                    </div>
                                                </div>
                                            </Selection>
                                        </> :
                                        // <> <div className='text-sm leading-none text-gray-900 my-9 w-full py-9'> This is account belongs to a workspace {isAdminMessage}.</div></>
                                        <>

                                            <p className='text-sm font-medium my-9 leading-none text-gray-900'>{getUserPlan(entitlement)}</p >
                                        </>
                                    }
                                    {isAdmin &&
                                        <Selection title="Billing" img={"/icons/change.png"}>
                                            {/* <p className='text-sm font-medium leading-none text-gray-900'>{getUserPlan(entitlement)}</p> */}
                                            <div>
                                                {/* <button className="text-sm font-medium text-blue-900 hover:underline dark:text-blue-900 ml-4"
                                            onClick={() => { window.location = 'https://pricingsaas.com/pricing.html' }}
                                        >
                                            Upgrade
                                        </button> */}
                                                <div className='py-3'>
                                                    <a href="https://billing.stripe.com/p/login/6oE03d1Ho3mtfYI5kk" target="_blank" className="inline-flex items-center    bg-white    px-6 py-2.5 text-xs font-bold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 cursor-pointer">&nbsp;&nbsp;Manage&nbsp;&nbsp;</a>
                                                </div>

                                            </div>
                                        </Selection>
                                    }
                                    <Selection title={"Daily Email Alerts"} img={"/icons/mail.png"}>
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                    {getAlerts(userReducer.emailAlerts)}
                                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => setEmailAlerts(true)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    On
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => setEmailAlerts(false)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    Off
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </Selection>
                                    <Selection title={"Weekly Pricing Digests"} img={"/icons/mail.png"}>
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                    {getDigest(userReducer.weeklyDigest)}
                                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => setWeeklyDigest(true)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    On
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => setWeeklyDigest(false)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    Off
                                                                </a>
                                                            )}
                                                        </Menu.Item>


                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </Selection>

                                </>
                            </ul>
                        }
                        {currentTab === 1 &&
                            <div className='mt-8'>

                                {!isAdmin && <div className='text-sm leading-none text-gray-900  w-full '> This is account belongs to a workspace {isAdminMessage}.</div>}
                                <WorkspaceControl />
                            </div>
                        }
                        {currentTab === 2 &&
                            <div className='mt-8'>
                                <AdminControl />
                            </div>
                        }
                        {isAdmin && <hr />}
                        <div className='flex flex-reverse-row float-right'>

                            <button
                                className='text-sm font-medium leading-none my-9 text-blue-900'
                                onClick={() => {
                                    resetReducers();
                                    logout({ returnTo: window.location.origin })
                                }

                                }
                            >
                                Log Out
                            </button>
                        </div>
                    </div>

                </div>
                <AddCompanyModal show={showAddModal} companies={[]} filteredCompanies={[]} closeHandler={() => { setShowAddModal(false); setValue(''); }} defaultValue={value} />
            </div>
        </>
    );
};

export default Settings;