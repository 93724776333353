import { ACQUISITION_FILTER,
  SORT_BY_ORDER,
  SORT_BY_ATTRIBUTE,
  COMPANYSIZE_FILTER,
  GENERIC_FILTER,
  SET_FAV_FILTER_ATTRIBUTE,
  RESET_FAV_FILTER,
  FILTERTYPE,
  SET_FAV_SORT_ORDER,
  SORT_BY_EXPLORE_SORT_ORDER,
  SET_EXPLORE_SORT_ORDER,
  LOAD_PRICING_METRICS,
  SORT_BY_EXPLORE_ATTRIBUTE,
  SET_USER_FILTER,
  RESET,
  INIT,
  SET_EXPLORE_FILTER} from "../actionTypes";


const initialState = {
    favFilter :
    {
      'acquisition' : ACQUISITION_FILTER.ALL,
      'companysize' : COMPANYSIZE_FILTER.ALL,
      'valuemetric' : GENERIC_FILTER.ALL,
      'planNames' : GENERIC_FILTER.ALL,
      'pricingMetrics' : GENERIC_FILTER.ALL,
      'explore_category': GENERIC_FILTER.ALL,
    },
    exploreFilter :
    {
      "categories": [],
      "models": [],
      "pricingModels": [],
      "pricingMetrics": [],
      "sizes": [],
      "sortyByExploreAttribute": "lastmodified",
      "sortyByExploreOrder": true
  }
};

export default function filtersReducer(state = initialState, action) {
  const result = { ...state };

  if (!result.exploreFilter) {
    result.exploreFilter = {};
  }

  switch (action.type) {
    case RESET: {
      return null;
    }
    case INIT: {
      return initialState;
    }
    case RESET_FAV_FILTER:
      result[FILTERTYPE.ACQUISITION] = ACQUISITION_FILTER.ALL;
      result[FILTERTYPE.COMPANYSIZE] = COMPANYSIZE_FILTER.ALL;
      result[FILTERTYPE.VALUEMETRIC] = [];
      result[FILTERTYPE.PLANNAMES] = [];
      result[FILTERTYPE.COMPANYNAME] = '';
      result[FILTERTYPE.PRICINGMETRIC] = '';
      result.userFilter = {};

      return result;
    case SET_FAV_SORT_ORDER:
      const { sortBy, ascending } = action.payload;
      result[SORT_BY_ATTRIBUTE] = sortBy;
      result[SORT_BY_ORDER] = ascending;
      return result;
    case SET_EXPLORE_SORT_ORDER:
        result.exploreFilter[SORT_BY_EXPLORE_ATTRIBUTE] = action.payload.sortBy;
        result.exploreFilter[SORT_BY_EXPLORE_SORT_ORDER] = action.payload.ascending;
        return result;

    case SET_EXPLORE_FILTER:
      const { filter } = action.payload;
      return {
        ...state,
        exploreFilter : filter
      }
    case SET_USER_FILTER:
        console.log( action.payload.filter);
        return {
          ...state,
          userFilter :  action.payload.filter
        }
    case LOAD_PRICING_METRICS:
      return {
        ...state,
        pricingMetrics :  action.payload.pricingMetrics
      }
    case SET_FAV_FILTER_ATTRIBUTE: {
      const { filterType, value } = action.payload;
      switch (filterType) {
        case FILTERTYPE.ACQUISITION: {
          result[FILTERTYPE.ACQUISITION] = value;
          return result;
        }
        case FILTERTYPE.COMPANYSIZE: {
          result[FILTERTYPE.COMPANYSIZE] = value;
          return result;
        }
        case FILTERTYPE.VALUEMETRIC: {
          result[FILTERTYPE.VALUEMETRIC] = value;
          return result;
        }
        case FILTERTYPE.PLANNAMES: {
          result[FILTERTYPE.PLANNAMES] = value;
          return result;
        }
        case FILTERTYPE.COMPANYNAME: {
          result[FILTERTYPE.COMPANYNAME] = value;
          return result;
        }
        case FILTERTYPE.CATEGORYTYPE: {
          result[FILTERTYPE.CATEGORYTYPE] = value;
          return result;
        }
        default: {
          throw new Error('unknown filter type');
        }
      }
    }

    default:
      return state;
  }
}
