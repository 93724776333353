/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainHeader from './Settings/MainHeader';
import SearchGridControl from './SearchGridControl';
import { searchCompanies, clearSearchResults } from '../redux/actionsCompanies';
import UpgradeModal from './Settings/UpgradeModal';
import { isFreemiumUser, isGrandfatheredFreemiumUser } from '../helpers/common';

const SearchControl = ({ autenticated=true }) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [searching, setSearching] = useState(false);
    const [instruction, setInstruction] = useState('E.g. "Project Management"');
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const userReducer = useSelector(state => state.userReducer);

    const handleInputChange = (event) => {
        setInstruction('E.g. "Project Management"')
        setSearchTerm(event.target.value);
    };

    const handleSearchClick = () => {

        if (searchTerm.trim().length === 0) {
            alert("Please enter a search term");
        } else {
            setSearching(true);
            searchCompanies(searchTerm, (results) => {
                if (!results || !results.length) {
                    setInstruction("No results found");
                }

                if (isFreemiumUser(userReducer) && !isGrandfatheredFreemiumUser(userReducer)) {
                    setShowUpgradeModal(true);
                }

                setSearching(false);
            });
        }

    };

    useEffect(() => {
        clearSearchResults();
    }, []);

    return (
        <>
            <div className="w-full p-4 bg-white lg:border sm:p-8">
                <MainHeader hideSet={true} />
                <div className='mt-12 pb-24'>

                    <div className='flex flex-row gap-x-1'>
                        <input
                            type="text"
                            className="flex-grow mt-2 block border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Enter search term"
                        />
                        <button
                            disabled={searching}
                            className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1.5 px-4 border border-blue-600 hover:border-transparent  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                            onClick={handleSearchClick}
                        >
                            {searching ? "Searching..." : "Search"}
                        </button>

                    </div>
                    <SearchGridControl text={instruction} />
                </div>
            </div>
            <UpgradeModal
                show={showUpgradeModal}
                closeHandler={() => window.location = '/'}
            />
        </>
    );
};

export default SearchControl;