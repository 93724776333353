import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import EditSetModal from './EditSetModal';
import { loadWorkspace, createMagicLink, removeUser } from '../../redux/actionsWorkspaces';
import MagicLinkModal from './MagicLinkModal';

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

const WorkspaceControl = () => {


    const [showModal, setShowModal] = useState(false);
    const [magicLinkUrl, setMagicLinkUrl] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);

    const userReducer = useSelector(state => state.userReducer);

    if (firstLoad) {
        loadWorkspace(() => setFirstLoad(false));
    }

    if (!userReducer) return (<></>);
    if (!userReducer.workspace) return (<div className='my-9'>This plan does not support workspaces. Contact us to upgrade.</div>);

    const workspace = userReducer.workspace;

    if (!workspace.isAdmin) {
        return (<>Not an admin</>);
    }

    if (!userReducer.entitlement.workspaces) {
        return (<>This plan does not support workspaces.</>);
    }

    const showModalScript = (secretKey) => {

        const url = (window.location.hostname !== 'localhost') ?
            `https://client.pricingsaas.com/workspaceInvite?workspaceId=${workspace.workspaceId}&secretKey=${secretKey}` :
            `http://localhost:3000/workspaceInvite?workspaceId=${workspace.workspaceId}&secretKey=${secretKey}`;

        setMagicLinkUrl(url);
        setShowModal(true);
    }

    const createMagicLinkScript = () => {

        const email = prompt("Enter email address:");


        if (email === null) {
            console.log("Cancelled");
        } else if (!email) {
            alert("Please sepcify a valid email address");
        } else {
            createMagicLink(email,
                (magicLink) => { alert(`Successfully invited ${email} to your workspace!`) },
                (msg) => { alert(`Error! ${msg}`) });
        }
    }

    return (
        <>
            {/* <h2 className='font-medium text-lg mt-9 flex flex-row underline'>Workspace</h2> */}

            <h3 className='font-medium text-sm flex flex-row'>Owner</h3>
            <span className='text-sm my-3'>{workspace.ownerEmail ? workspace.ownerEmail : workspace.ownerUserId}</span>

            <h3 className='font-medium text-sm mt-9 flex flex-row'>Members</h3>

            {workspace.members && workspace.members.map((member, rowIndex) => (
                <li
                    key={member.userId}
                    className="col-span-1 text-sm my-3 flex flex-col divide-y divide-gray-200 mb-3  bg-white shadow"
                >
                    <div className='flex flex-row justify-between'>
                        {member.email}
                        <div className='flex flex-row justify-between'>
                            <a onClick={() => removeUser(member.userId)} className="text-red-600 text-xs font-semibold cursor-pointer">Remove</a>
                        </div>
                    </div>
                </li>
            ))}

            {/* <h3 className='font-medium text-sm mt-9 flex flex-row'>Invites</h3> */}

            {workspace.invites && workspace.invites.map((invite, rowIndex) => (
                <li
                    key={invite}
                    className="col-span-1  text-sm my-6 flex flex-col divide-y divide-gray-200 mb-3  bg-white shadow"
                >
                    <div className='flex flex-row justify-between'>
                        {invite.email}
                        <div className='flex flex-row justify-between'>

                            <a onClick={() => showModalScript(invite.secretKey)} className="text-gray-600 mr-6 text-xs font-semibold cursor-pointer">Magic Link</a>
                            <a onClick={() => removeUser(invite.email)} className="text-red-600 text-xs font-semibold cursor-pointer">&nbsp;Cancel&nbsp;</a>
                        </div>
                    </div>
                </li>
            ))}

            {workspace.invites.length === 0 && workspace.members.length === 0 && <span className=' text-sm my-3 '>No members yet.</span>}

            <div className='py-3'>
                {/* <a onClick={() => createMagicLink("test@gmail.com")} className="inline-flex items-center    bg-custom-blue px-6 py-2.5 text-xs font-bold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-600 cursor-pointer">&nbsp;Magic Link&nbsp;</a> */}
                <a onClick={() => createMagicLinkScript()} className="inline-flex items-center    bg-custom-blue px-6 py-2.5 text-xs font-bold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-500 cursor-pointer">Add a Member</a>
            </div>

            <MagicLinkModal show={showModal} closeHandler={() => setShowModal(false)} magicLink={magicLinkUrl} />
        </>
    );
};


export default WorkspaceControl;