/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { versionToDate, versionToDate3, } from '../../helpers/common';
import { getCompany } from '../../redux/actionsUsers';
import CompareModal from './CompareModal';
import Timeline from '../TimelineControl';
import { downloadCSV } from '../../helpers/common';
import { generateHistoryCsvReport } from '../../redux/actionsUsers';
import UpgradeModal from '../Settings/UpgradeModal';
import UpgradeModalPublic from '../Settings/UpgradeModalPublic';

import { isFreemiumUser, isGrandfatheredFreemiumUser } from '../../helpers/common';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function isDateInQuarter(startDate, endDate, quarter) {

    if (!startDate || !endDate || !quarter) { return false; }

    // Parse the start and end dates
    const startYear = parseInt(startDate.substring(0, 4));
    const startMonth = parseInt(startDate.substring(4, 6));
    const startDay = parseInt(startDate.substring(6, 8));

    const endYear = parseInt(endDate.substring(0, 4));
    const endMonth = parseInt(endDate.substring(4, 6));
    const endDay = parseInt(endDate.substring(6, 8));

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    // Parse the quarter
    const [q, qYear] = quarter.split(" ");
    const quarterYear = parseInt(qYear);
    const quarterNum = parseInt(q.substring(1));

    // Determine the quarter start and end months
    let quarterStartMonth, quarterEndMonth;
    switch (quarterNum) {
        case 1:
            quarterStartMonth = 1;
            quarterEndMonth = 3;
            break;
        case 2:
            quarterStartMonth = 4;
            quarterEndMonth = 6;
            break;
        case 3:
            quarterStartMonth = 7;
            quarterEndMonth = 9;
            break;
        case 4:
            quarterStartMonth = 10;
            quarterEndMonth = 12;
            break;
        default:
            throw new Error("Invalid quarter");
    }

    const quarterStartDate = new Date(quarterYear, quarterStartMonth - 1, 1);
    const quarterEndDate = new Date(quarterYear, quarterEndMonth, 0); // Last day of the quarter

    // Check if there is any overlap
    return (start <= quarterEndDate && end >= quarterStartDate);
}

// Example usage
const CompanyDetailsControl = ({ slug, publicControl, backHandler, currentDiff, diffsParam, updateDiffHandler }) => {

    const searchParams = new URLSearchParams(window.location.search);
    // const slug = searchParams.get('slug');

    const [showCompare, setShowCompare] = useState(false);
    const [beforeUrl, setBeforeUrl] = useState('');
    const [afterUrl, setAfterUrl] = useState('');
    const [beforeVersion, setBeforeVersion] = useState('');
    const [afterVersion, setAfterVersion] = useState('');
    const [desc, setDesc] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [currentMasterVersion, setCurrentMasterVersion] = useState('');
    const [currentInstanceVersion, setCurrentInstanceVersion] = useState('');
    const [currentQuarter, setCurrentQuarter] = useState('');
    const [sliderValue, setSliderValue] = useState(5);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showFullImage, setShowFullImage] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);


    const userReducer = useSelector(state => state.userReducer);
    const company = userReducer.currentCompany;

    useEffect(() => {

        // console.log('useEffect');

        if (firstLoad) {

            setFirstLoad(false);

            if (isFreemiumUser(userReducer) && !isGrandfatheredFreemiumUser(userReducer)) {
                setShowUpgradeModal(true);
            }

            if (publicControl) {
                setShowUpgradeModal(true);
            }

            getCompany(slug, publicControl, () => setLoading(false), () => setLoading(false));

        }

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                const newValue = Math.max(0, parseInt(sliderValue) - 1);
                handleSetSliderValue(newValue.toString());
            } else if (event.key === 'ArrowRight') {
                const newValue = Math.min(instances.length - 1, parseInt(sliderValue) + 1);
                console.log(sliderValue, newValue);
                handleSetSliderValue(newValue.toString());
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [sliderValue]);



    if (!slug) {
        return <>Hmmm... something is off</>
    }

    if (loading) {
        return <><div className="my-9">Loading...</div></>
    }

    if (!company || !company.logoUrl) {
        console.log(company)
        return <>Company not found {slug}</>
    }

    const masters = (userReducer.currentMasters || []);
    const instances = (userReducer.currentInstances || []).sort((a, b) => { return parseInt(b.version) - parseInt(a.version) });
    const pricingPageUrl = company.url;
    const lastUpdate = versionToDate(company.trackingSince);
    const description = company.description;

    if (!currentImageUrl && instances.length > 0) {
        setCurrentImageUrl(instances[0].imageUrl);
        setCurrentMasterVersion(instances[0].version);

    }


    if (!currentQuarter) {
        const quarters = getQuarters();
        console.log(quarters);
        setCurrentQuarter(quarters.length > 0 ? quarters[quarters.length - 1] : null);
        setSliderValue(instances.length - 1);
    }


    const getBeforeUrl = (master) => {

        masters.sort((a, b) => { return b.first - a.first });
        console.log(master);

        if (master.parent) {
            const parent = masters.find(m => m.id === master.parentId) || masters.find(m => m.version === master.parent);
            console.log('found parent');
            return parent.imageUrl;
        } else {

            const index = masters.findIndex(m => m.version === master.version);
            if (index === masters.length - 1) {
                console.log('returning asme url');
                return master.imageUrl;
            } else {
                return masters[index + 1].imageUrl;
            }
        }
    }

    const setCurrentQuarterFromMaster = (master) => {
        const quarter = convertToQuarter(master.first);
        setCurrentQuarter(quarter);
    }

    const setCurrentQuarterFromInstance = (instance) => {
        const quarter = convertToQuarter(instance.version);
        setCurrentQuarter(quarter);
    }

    const getBeforeVersion = (master) => {

        if (master.parent) {
            const parent = masters.find(m => m.version === master.parent) || masters.find(m => m.id === master.parentId);
            return parent.last;
        } else {

            const index = masters.findIndex(m => m.version === master.version);
            if (index === masters.length - 1) {
                return master.last;
            } else {
                return masters[index + 1].last;
            }
        }
    }

    const handleShowMaster = (master) => {
        console.log(master);
        setBeforeUrl(getBeforeUrl(master));
        setAfterUrl(master.imageUrl);
        setBeforeVersion(getBeforeVersion(master));
        setAfterVersion(master.version);
        setDesc(master.changeDesc)
        setShowCompare(true)
    }

    const handleSetSliderValue = (value) => {

        setSliderValue(value);
        instances.sort((a, b) => { return parseInt(a.version) - parseInt(b.version) });
        setCurrentImageUrl(instances[value].imageUrl);
        setCurrentInstanceVersion(instances[value].version);
        setCurrentQuarterFromInstance(instances[value]);

        const instanceMaster = masters.find(m => m.instances.includes(instances[value].version));

        if (instanceMaster) {
            setCurrentMasterVersion(instanceMaster.version);
        }
        //

        // console.log(instances);

    };

    const getVersionDesc = () => {

        // check if there are multiple versions in this quareter



        let changeCompareHTML = [];
        let line1HTML = <></>
        let line2HTML = <></>

        // const newVersions = masters.filter(m => isDateInQuarter(m.first, m.first, currentQuarter));
        const firstVersion = masters.length > 0 ? masters.sort((a, b) => { return a.first - b.first })[0] : null;
        const newVersions = masters.filter((m, mi) => m.id !== firstVersion.id && isDateInQuarter(m.first, m.first, currentQuarter));


        newVersions.sort((a, b) => { return a.first - b.first });


        // Use cases:
        // Running A/B test
        // Last change

        // 2 changes detected this quarter (01/04/2023 and 03/05/2024).
        // a/b test detected (this version overlaps with version1, version2).
        // This version is active. It has been running for x days.
        // This version is no longer active. It was last detected on 03/05/2024.

        // Current version: version 3.

        const getVersionHTML = (master, last = false) => {
            const instanceIndex = instances.findIndex(inst => inst.version === master.first);
            console.log(sliderValue, instanceIndex);
            if (sliderValue === instanceIndex) {
                return <><span className="font-medium italic">{versionToDate3(master.first)}</span><span>{last ? '' : ',  '}</span></>
            }
            return <><span className="text-custom-blue italic " onClick={() => handleSetSliderValue(instanceIndex)}>{versionToDate3(master.first)}</span><span>{last ? '' : ',  '}</span></>
        }

        if (newVersions.length > 0) {

            line1HTML = <div>{newVersions.length} new changes detected this quarter ({
                newVersions.map((master, mIndex) => getVersionHTML(master, mIndex === newVersions.length - 1))
            }) </div>

        } else {
            line1HTML = <div>No new changes detected this quarter.</div>
        }

        const mastersInQuarter = masters.filter(m => isDateInQuarter(m.first, m.last, currentQuarter));
        mastersInQuarter.sort((a, b) => { return a.first - b.first });
        console.log(mastersInQuarter);


        const currentMasterInstance = getCurrentMaster(); // masters.find(m => m.version === currentMasterVersion);
        console.log(currentMasterInstance);

        if (currentMasterInstance) {
            line2HTML = <div className="flex flex-row justify-between mt-6">

                <div className="bg-blue-100 w-full py-1 text-sm text-gray-500 italic text-left" >
                    Image timestamp: <span className="font-medium">{versionToDate(currentInstanceVersion)}</span>
                </div>
                <div className="bg-blue-100 w-full py-1 text-sm text-gray-500 italic text-right" >
                    Currently showing version:  {currentMasterInstance.displayVersion} {currentMasterInstance.displayVersion != 'v1' && <>(<span onClick={() => handleShowMaster(currentMasterInstance)} className="font-normal text-custom-blue">See Details</span>).</>}
                </div>
            </div>

        } else {
            line2HTML = <div className="bg-blue-100 w-full mt-6 py-1 text-sm text-gray-500 italic text-left" >
                Image timestamp: <span className="font-medium">{versionToDate(currentInstanceVersion)}</span>
            </div>
        }

        const overlappingMasters = [];
        for (let i = 0; i < mastersInQuarter.length; i++) {
            const master = mastersInQuarter[i];
            for (let j = i + 1; j < mastersInQuarter.length; j++) {
                const master2 = mastersInQuarter[j];
                let overlapArray = [];
                if (parseInt(master.last) < parseInt(master2.first)) {
                    overlapArray.push(master2.version);
                }

                if (overlapArray.length > 0) {
                    overlappingMasters.push({ master: master, overlap: overlapArray });
                }
            }
        }

        console.log(overlappingMasters);

        return <><div className="flex flex-col bg-blue-100 text-sm px-6 pt-3 w-full text-gray-700">{line1HTML}{line2HTML} </div></>;

    }

    const getSlider = () => {
        return <>
            <div className="mt-6 mb-3">
                <input
                    id="minmax-range"
                    type="range"
                    min="0"
                    max={instances.length - 1}
                    value={sliderValue}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) => handleSetSliderValue(e.target.value)}
                />
            </div>
        </>
    }


    const callDownloadCSV = async () => {
        // const slugs = getCurrentSetSlugs(userReducer, companiesFilter);
        const slugs = [slug];
        const csvData = await generateHistoryCsvReport(slugs)
        downloadCSV(csvData, 'history.csv');
    }

    const getQuatersTabs = () => {
        const quarters = getQuarters();


        // console.log(quarters);
        return <>
            <div className="flex flex-row  gap-x-3 mt-12">
                {quarters.map((quarter, index) => {
                    const firstVersion = masters.length > 0 ? masters.sort((a, b) => { return a.first - b.first })[0] : null;
                    const newVersions = masters.filter((m, mi) => m.id !== firstVersion.id && isDateInQuarter(m.first, m.first, quarter));

                    // console.log(quarter, newVersions);

                    if (quarter === currentQuarter) {

                        return <div key={index} className="flex flex-col w-32 h-14 text-center bg-blue-100 border-t border-y-custom-blue pt-2">
                            <div className="mx-auto pb-2">
                                {newVersions.length > 0 ?
                                    <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                    :
                                    <div className="w-2 h-2 rounded-full"></div>
                                }
                            </div>
                            <div>{quarter} </div>
                        </div>

                    } else {
                        return (
                            <div key={index} className="flex flex-col w-32 h-14 text-center cursor-pointer bg-gray-50 pt-2"
                                onClick={() => {

                                    updateImageUrl(quarter);
                                    setCurrentQuarter(quarter);

                                }}>
                                <div className="mx-auto pb-2">
                                    {newVersions.length > 0 ?
                                        <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                        :
                                        <div className="w-2 h-2 rounded-full"></div>
                                    }
                                </div>
                                <div>{quarter}</div>
                            </div>
                        );
                    }
                })}

            </div>
        </>
    }

    const getCurrentMaster = () => {

        const instanceMaster = masters.find(m => m.instances.includes(currentInstanceVersion));
        return instanceMaster;
    }

    const updateImageUrl = (quarter) => {

        console.log(quarter);
        const quarters = getQuarters();
        quarters.sort((a, b) => { return b - a });
        let instance = null;

        for (let i = 0; i < quarters.length; i++) {

            instance = instances.find(inst => inst && inst.version && quarters[i] === convertToQuarter(inst.version)) || instance;

            console.log(instance);
            if (quarters[i] === quarter) {
                break;
            }
        }


        console.log(instance);
        if (instance) {
            // I think this should return the master this instances belongs version
            const instanceMaster = masters.find(m => m.instances.includes(instance.version));
            setCurrentMasterVersion(instanceMaster ? instanceMaster.version : null);

            setCurrentImageUrl(instance.imageUrl);

            const instanceIndex = instances.findIndex(inst => inst.version === instance.version);
            setSliderValue(instanceIndex);
            setCurrentInstanceVersion(instance.version);
        }


    }

    const getMastersTable = () => {

        if (masters.length === 1) {
            return <></>
        }

        masters.sort((a, b) => { return b.first - a.first });

        return <>

            <table className="w-full mt-12 text-sm">
                <thead>
                    <tr>
                        <th className="w-fit font-medium text-left"></th>
                        <th className="w-32 px-1 font-medium text-left hidden md:table-cell">First Detected</th>
                        <th className="w-32 px-1 font-medium text-left hidden md:table-cell">Last Detected</th>
                        <th className="w-24 px-1 font-medium text-left hidden md:table-cell">A/B Tests</th>
                        <th className="text-left font-medium invisible md:visible">Overview</th>
                        <th className="w-48 text-middle font-medium"></th>
                    </tr>
                </thead>
                <tbody>
                    {masters.map((master, index) => {
                        const desc = index === masters.length - 1 ? 'Tracking started' : master.changeDesc;
                        const handleClick = () => {
                            setCurrentMasterVersion(master.version);
                            setCurrentImageUrl(master.imageUrl);
                            setCurrentQuarterFromMaster(master);
                            setCurrentInstanceVersion(master.version);
                            setSliderValue(instances.findIndex(inst => inst.version === master.version));
                        };

                        if (index > 5 && !showMore) { return null; }

                        const overlappingMasters = masters.filter(m => m.first < master.first && m.last > master.first).filter(m => m.id !== master.id);
                        const abText = overlappingMasters.length > 0 ? overlappingMasters.map(m => m.displayVersion).join(', ') : '--';

                        return (
                            <tr key={index} className={classNames(master.version === currentMasterVersion ? 'bg-yellow-50' : '', '')}>
                                <td className="border px-4 py-2" onClick={() => { handleClick(); }}>
                                    {master.displayVersion}
                                </td>
                                <td className="border px-1 py-2 hidden md:table-cell" onClick={() => { handleClick(); }}>
                                    {versionToDate(master.first)}
                                </td>
                                <td className="border px-1 py-2 hidden md:table-cell" onClick={() => { handleClick(); }}>
                                    {versionToDate(master.last)}
                                </td>
                                <td className="border px-4 py-2 hidden md:table-cell" onClick={() => { handleClick(); }}>
                                    {abText}
                                </td>
                                <td className="border px-4 py-2" onClick={() => { handleClick(); }}>
                                    {desc}
                                </td>
                                <td className="border px-4 py-2">
                                    <a className="text-blue-500 cursor-pointer" onClick={() => { handleShowMaster(master); }}>
                                        {index < masters.length - 1 && 'View Details'}
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>


            {masters.length > 5 &&
                <>
                    <div className="w-full text-center cursor-pointer mt-3 mb-9 text-blue-500" onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less...' : 'Show More...'}</div>
                </>
            }
        </>

    }

    // console.log(diffsParam);

    function convertToQuarter(dateString) {
        // Extract year and month from the input string
        const year = dateString.substring(0, 4);
        const month = parseInt(dateString.substring(4, 6), 10);

        // Determine the quarter
        let quarter;
        if (month >= 1 && month <= 3) {
            quarter = 1;
        } else if (month >= 4 && month <= 6) {
            quarter = 2;
        } else if (month >= 7 && month <= 9) {
            quarter = 3;
        } else if (month >= 10 && month <= 12) {
            quarter = 4;
        } else {
            console.log(dateString);
            throw new Error("Invalid month in date string" + dateString);
        }

        // Return the formatted string
        return `Q${quarter} ${year}`;
    }

    function getQuarters() {

        if (instances.length === 0) { return []; }


        instances.sort((a, b) => { return parseInt(a.version) - parseInt(b.version) });

        if (instances.length === 0) { return []; }
        const startQuarter = convertToQuarter(instances[0].version);

        const quarters = [];
        const [startQ, startYear] = startQuarter.split(' ');
        let currentYear = parseInt(startYear, 10);
        let currentQuarter = parseInt(startQ.replace('Q', ''), 10);

        const today = new Date();
        const currentMonth = today.getMonth() + 1; // getMonth() returns month index from 0-11
        const currentYearNow = today.getFullYear();
        const currentQuarterNow = Math.ceil(currentMonth / 3);

        while (currentYear < currentYearNow || (currentYear === currentYearNow && currentQuarter <= currentQuarterNow)) {
            quarters.push(`Q${currentQuarter} ${currentYear}`);
            currentQuarter++;
            if (currentQuarter > 4) {
                currentQuarter = 1;
                currentYear++;
            }
        }

        quarters.sort((a, b) => { return b - a });

        return quarters;
    }


    return (
        <>
            <div className="flex flex-col mt-6" >

                <div className='flex flex-row justify-between items-center w-full' >
                    <div className='flex flex-row text-gray-700  cursor-pointer' onClick={() => backHandler()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        Back
                    </div>
                    <div className='flex flex-row items-center'>
                        <img src={company.logoUrl} className="h-12 w-12 mr-1" />
                        <div className='ml-3 font-semibold'>{company.slug}</div>
                    </div>

                    <div className="w-32">

                        {pricingPageUrl &&
                            <div className="flex flex-row my-auto">
                                <a href={pricingPageUrl} className="text-gray-700 mr-2" target="_blank">Pricing Page</a>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mt-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                </svg>
                            </div>
                        }
                    </div>
                </div>

                <div className='mx-3'>
                    <Timeline versions={diffsParam} current={currentDiff} setCurrent={updateDiffHandler} />
                </div>


                <div className='flex flex-col'>

                    <div >
                        {getSlider()}
                        {getQuatersTabs()}
                        {getVersionDesc()}


                        <div className="flex flex-col justify-center bg-blue-100 p-1">
                            <div className={classNames(showFullImage ? '' : 'h-[800px] overflow-hidden', 'w-full')}>
                                <img
                                    src={currentImageUrl}
                                    className=" border border-gray-300 mx-auto"
                                    onClick={() => setShowFullImage(!showFullImage)}
                                />
                            </div>
                            <div onClick={() => setShowFullImage(!showFullImage)} className="my-3 text-center text-custom-blue italic">
                                {showFullImage ? 'Collapse...' : 'Expand...'}
                            </div>
                        </div>

                        {
                            masters.length > 0 &&
                            <>
                                <div className="flex flex-row justify-between mt-12 mb-6">
                                    <h1 className="text-2xl font-bold   ">Change Log</h1>
                                    <button onClick={() => callDownloadCSV()} className='my-auto text-custom-blue' type="button">Export to CSV</button>
                                </div >

                                {getMastersTable()}
                            </>
                        }


                    </div>
                </div>
            </div>
            <CompareModal
                slug={slug}
                desc={desc}
                beforeVersion={beforeVersion}
                afterVersion={afterVersion}
                beforeUrl={beforeUrl}
                afterUrl={afterUrl}

                showModal={showCompare}
                hideModalHandler={() => { setShowCompare(false) }} />

            {publicControl ?

                <UpgradeModalPublic
                    show={showUpgradeModal}
                    closeHandler={() => window.location = '/psearch'}
                /> :
                <UpgradeModal
                    show={showUpgradeModal}
                    closeHandler={() => window.location = '/'}
                />
            }
        </>
    );
};

export default CompanyDetailsControl;