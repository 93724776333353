/* eslint-disable no-unused-vars */
import React from "react";
import { SORT_ATTRIBUTES } from "../redux/actionTypes";
import { StarIcon } from '@heroicons/react/24/outline'
import { toggleUserFavourite } from "../redux/actionsUsers";
// import { setFavSortOrder } from "../redux/actionsFilters";
import { formatDate } from "../redux/selector";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import { getPageType, PAGE_TYPE } from "../helpers/helperFunction";


export class ListControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortAscending: true,
    };
  }

  sortByColumn(column){
    this.setState({  sortAscending: !this.state.sortAscending });
    this.props.sortOrderHandler(column, this.state.sortAscending);
    // setFavSortOrder(column, this.state.sortAscending);
  }

  suggestCompany() {
    window.location.href = '/add';
  }

  render() {

    // const companies = (this.props.companies);
    const filteredCompanies = (this.props.filteredCompanies);
    const lightboxImages = filteredCompanies.map( c => ({ src: c.pricingPageScreenshotUrl, title: c.companyId, description: c.description }))

    const detailsUrl = getPageType() === PAGE_TYPE.EXPLORE ? '/explore/view?slug=' : '/favourites/view?slug=';

    return (
      <>
        <div className="w-full my-3 overflow-x-scroll">
             <Lightbox
                open={this.props.showLightbox}
                // close={() => this.setState({showLightbox : false})}
                close={ () => this.props.hideLightbox( )}
                slides={lightboxImages}
                plugins={[Zoom, Captions]}
                on={{
                  view: (index) => console.log('slide', index),
                  click: (index) => { window.location.href = detailsUrl + filteredCompanies[index].companyId }
                }}
                zoom={{
                  maxZoomPixelRatio: 1,
                  zoomInMultiplier: 2,
                  doubleTapDelay: 300,
                  doubleClickDelay: 500,
                  doubleClickMaxStops: 2,
                  keyboardMoveDistance: 50,
                  wheelZoomDistanceFactor: 100,
                  pinchZoomDistanceFactor: 100,
                  scrollToZoom: true
                }}
              />
            <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.NAME)}} >
                          Company
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.CATEGORY)}} >
                          Category
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "  onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.ACQUISITION_MODEL)}} >
                          <>Acquisition Model</>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "  onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.EMPLOYEES)}} >
                          <>Employees</>
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.FOUNDED)}} >
                          <>Pricing Model</>
                          {/* {
                            getPageType() === PAGE_TYPE.EXPLORE ?
                              <>Founded</>
                              :
                              <>Pricing Metric</>
                          } */}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "  onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.FOUNDING)}} >
                        <>Funding</>
                        {/* {
                            getPageType() === PAGE_TYPE.EXPLORE ?
                              <>Founding</>
                              :
                              <>Acquisition Model</>
                          } */}
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "  onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.PRICINGMETRIC)}} >
                          <>Pricing Metric</>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " >
                          <>Pricing Page</>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 " onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.LAST_MODIFIED)}} >
                          Last Updated
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"  onClick={() => { this.sortByColumn(SORT_ATTRIBUTES.FAVOURITES)}} > */}
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900" >
                          Favourites
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filteredCompanies.map((company, index) => (
                        <tr key={index} className="hover:bg-blue-50 cursor-pointer" onClick={ () => window.open(`https://pricingsaas.com/companies/${company.companyId}.html`)   }>
                        {/* <tr key={index} className="hover:bg-blue-50 " title={company.description}> */}
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex flex-row align-middle">
                            <img alt='logo' className="h-8 w-8" src={company.logoUrl}/> <span className="block ml-3 pt-1">{company.companyId}</span>
                            {/* <img alt='logo' className="h-8 w-8" src={company.logoUrl}/><a  rel="noreferrer" target="_blank" href={ detailsUrl + company.companyId }><span className="block ml-3 pt-1">{company.companyId}</span></a> */}
                          </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ company.category }</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ company.acquisitionModel === 'Unknown' ? '--' : company.acquisitionModel}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ company.employees }</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.pricingModel }
                            {/* { getPageType() === PAGE_TYPE.EXPLORE ? company.yearFounded : company.pricingMetric } */}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{company.captialRaised }
                            {/* { getPageType() === PAGE_TYPE.EXPLORE ? company.captialRaised : company.acquisitionModel } */}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{ company.pricebookPublished ? company.pricingMetric : '' }</td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="flex flex-row">
                            <a href={ company.pricingPageUrl } target="_blank">Link

                            </a>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4  ml-3">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                            </svg>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                              onClick={ (e) =>  { window.open(
                                `http://admin.pricingsaas.com/editCompany?slug=${company.companyId}`,
                                '_blank' // <- This is what makes it open in a new window.
                              );  e.stopPropagation(); } }>
                            {
                              formatDate(company.lastModified)
                            }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 "  onClick={(e) =>  { toggleUserFavourite(company.companyId);   e.stopPropagation();} }>
                            {
                              company.isFavourite ?
                              <StarIcon onClick={(e) =>  { toggleUserFavourite(company.companyId);   e.stopPropagation();} } className="cursor-pointer block h-6 w-6 m-auto" style={{ fill: 'gold' }} /> :
                              <StarIcon onClick={(e) =>  { toggleUserFavourite(company.companyId);   e.stopPropagation();} } className="cursor-pointer block h-6 w-6 m-auto" />
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

          {/* <button className="text-sm float-right text-blue-500" onClick={() => this.refresh()}>
                {this.state.refreshing ? "Refreshing" : "Refresh"}
              </button> */}
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps)(ListControl)
export default ListControl;
