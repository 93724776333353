import React from "react";
import { Chart } from "react-google-charts";
import { setFavFilterAttributes } from "../redux/actionsFilters";
import { ACQUISITION_FILTER, COMPANYSIZE_FILTER, FILTERTYPE, OPTIONTYPE } from "../redux/actionTypes";
import { camelize } from "../helpers/helperFunction";

import { sizes, models } from "../helpers/common";

const callback = [{
    eventName: "select",
    callback({ chartWrapper }) {
        return;
        const index = chartWrapper.getChart().getSelection()[0].row;
        const data = chartWrapper.getDataTable().cache;
        const dataTitle = chartWrapper.m.title;
        let dataName;
        for (let i = 0; i < data.length; i++) {
            if (i === index) {
                dataName = data[i][0].Me;
            }
        }
        if (dataName === "Others" || dataName === "None") {
            return;
        }
        else {
            const state = matchOptions(dataName, dataTitle);
            const filterType = determineFilterType(dataTitle);
            setFavFilterAttributes(filterType, state);
        }
    }
}]

function matchOptions(dataName, dataTitle) {
    let match;
    if (dataName === "Paid Only") {
        match = ACQUISITION_FILTER.DEMO_ONLY;
    }
    else if (dataName === "Freemium") {
        match = ACQUISITION_FILTER.FREEMIUM_ONLY;
    }
    else if (dataName === "Free Trial & Freemium") {
        match =  ACQUISITION_FILTER.REVERSE_TRIAL;;
    }
    else if (dataName === "Free Trial Only") {
        match = ACQUISITION_FILTER.FREETRIAL_ONLY;;
    }
    else if (dataName === "50 or less") {
        match = COMPANYSIZE_FILTER.LESSTHAN50;
    }
    else if (dataName === "Between 50 and 250") {
        match = COMPANYSIZE_FILTER.BETWEEN50AND250;
    }
    else if (dataName === "Between 250 and 1000") {
        match = COMPANYSIZE_FILTER.BETWEEN250AND1000;
    }
    else if (dataName === "Over 1000") {
        match = COMPANYSIZE_FILTER.OVER1000;
    }
    if (dataTitle === "Pricing Metric Breakdown" || dataTitle === "Plan Breakdown") {
        match = [{
            value: dataName,
            label: dataName,
        }];
    }
    return match;
}

function determineFilterType(title) {
  let type;
  if (title === 'Acquisition Model') {
    type = FILTERTYPE.ACQUISITION;
  } else if (title === 'Company Size') {
    type = FILTERTYPE.COMPANYSIZE;
  } else if (title === 'Plan Breakdown') {
    type = FILTERTYPE.PLANNAMES;
  } else if (title === 'Pricing Metric Breakdown') {
    type = FILTERTYPE.VALUEMETRIC;
  }
  return type;
}

export class InsightsControl extends React.Component {

    sortValueMetric() {
        const companies = this.props.companies;
        const valueMetrics = [];
        const sortValueMetrics = [];
        const temp = [];
        for (let i = 0; i < companies.length; i++) {
            if (companies[i].pricingMetric && companies[i].pricingMetric !== "--") {
                valueMetrics.push(companies[i].pricingMetric);
            } else {
                valueMetrics.push('None');
            }
        }
        const unique = [...new Set(valueMetrics)];
        for (let i = 0; i < valueMetrics.length; i++) {
            let num = valueMetrics.filter((element) => (element === unique[i])).length;
            temp.push(num);
        }
        for (let i = 0; i < unique.length; i++) {
            sortValueMetrics.push({
                key: unique[i],
                num: temp[i],
            });
        }
        function compare(a, b) {
            if (a.num < b.num) {
                return -1;
            }
            if (a.num > b.num) {
                return 1;
            }
            return 0;
        }
        sortValueMetrics.sort(compare);
        sortValueMetrics.reverse();
        return sortValueMetrics;
    }

  getTopTenValueMetric() {
    const sortValueMetrics = this.sortValueMetric();
    const sliced = sortValueMetrics.slice(0, 10);
    return sliced;
  }

    getValueMetricData() {
        const topValueMetrics = this.getTopTenValueMetric();
        let data = [];
        let otherNum = [];
        for (let i = 0; i < topValueMetrics.length; i++) {
            data.push([
                topValueMetrics[i].key, topValueMetrics[i].num
            ]);
        }
        data.unshift([
            "Plan Name count", "number of companies with a plan name",
        ]);
        const getOthers = this.sortValueMetric();
        const sliceLast = getOthers.slice(topValueMetrics.length, getOthers.length);
        for (let i = 0; i < sliceLast.length; i++) {
            otherNum.push(sliceLast[i].num);
        }
        const initialSum = 0;
        const sumWIthInitial = otherNum.reduce(
            (prev, current) => prev + current, initialSum
        );
        data.push([
            "Others", sumWIthInitial
        ]);
        return data;
    }

  sortPlanNames() {
    const companies = this.props.companies;
    const plans = [];
    const sortPlans = [];
    const temp = [];
    for (let i = 0; i < companies.length; i++) {
      for (let j = 0; j < companies[i].planNames.length; j++) {
        let capitalizeFirst = camelize(companies[i].planNames[j]);
        plans.push(capitalizeFirst);
      }
    }
    const unique = [...new Set(plans)];
    for (let i = 0; i < plans.length; i++) {
      let num = plans.filter((element) => element === unique[i]).length;
      temp.push(num);
    }
    for (let i = 0; i < unique.length; i++) {
      sortPlans.push({
        key: unique[i],
        num: temp[i],
      });
    }
    function compare(a, b) {
      if (a.num < b.num) {
        return -1;
      }
      if (a.num > b.num) {
        return 1;
      }
      return 0;
    }
    sortPlans.sort(compare);
    sortPlans.reverse();
    return sortPlans;
  }

  getTopPlans() {
    const sortPlans = this.sortPlanNames();
    const sliced = sortPlans.slice(0, 10);
    return sliced;
  }

    getPlanNamesData() {
        const topplans = this.getTopPlans();
        let data = [];
        let otherNum = [];
        for (let i = 0; i < topplans.length; i++) {
            data.push([
                topplans[i].key, topplans[i].num
            ]);
        }
        data.unshift([
            "Plan Name count", "number of companies with a plan name",
        ]);
        const getOthers = this.sortPlanNames();
        const sliceLast = getOthers.slice(topplans.length, getOthers.length);
        for (let i = 0; i < sliceLast.length; i++) {
            otherNum.push(sliceLast[i].num)
        }
        const initialSum = 0;
        const sumWithInitial = otherNum.reduce(
            (prev, current) => prev + current, initialSum
        );
        data.push([
            "Others", sumWithInitial
        ])
        return data;
    }

    render() {
        return (
            <>
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-2/4 lg:w-2/4">
                        <Chart
                            chartType="PieChart"
                            data={[
                                ["Plan Type", "Freemium vs NonFreemium"],
                                [models[0], this.props.companies.filter(company => company.acquisitionModel === models[0]).length],
                                [models[1], this.props.companies.filter(company => company.acquisitionModel === models[1]).length],
                                [models[2], this.props.companies.filter(company => company.acquisitionModel === models[2]).length],
                                [models[3], this.props.companies.filter(company => company.acquisitionModel === models[3]).length],
                                [models[4], this.props.companies.filter(company => company.acquisitionModel === models[4]).length],

                                // ["Free Trial Only", this.props.companies.filter(company => company.freetrial && !company.freemium).length],
                                // ["Free Trial & Freemium", this.props.companies.filter(company => company.freetrial && company.freemium).length],
                                // ["Paid Only", this.props.companies.filter(company => !company.freemium && !company.freetrial).length],
                            ]}
                            width={"100%"}
                            height={"400px"}
                            options={{
                                title: "Acquisition Model",
                            }}
                            chartEvents={callback}
                            legendToggle
                        />
                        {/* <Chart
                            chartType="PieChart"
                            data={[
                                ["Plan Type", "Freemium vs NonFreemium"],
                                ["Freemium", this.props.companies.filter(company => company.freemium && !company.freetrial).length],
                                ["Free Trial Only", this.props.companies.filter(company => company.freetrial && !company.freemium).length],
                                ["Free Trial & Freemium", this.props.companies.filter(company => company.freetrial && company.freemium).length],
                                ["Paid Only", this.props.companies.filter(company => !company.freemium && !company.freetrial).length],
                            ]}
                            width={"100%"}
                            height={"400px"}
                            options={{
                                title: "Acquisition Model",
                            }}
                            chartEvents={callback}
                            legendToggle
                        /> */}
                    </div>
                    <div className="w-full md:w-2/4 lg:w-2/4">
                        <Chart
                            chartType="PieChart"
                            data={[
                                ["Employee count", "number of employees"],
                                [sizes[0], this.props.companies.filter(company => company.employees === sizes[0]).length],
                                [sizes[1], this.props.companies.filter(company => company.employees === sizes[1]).length],
                                [sizes[2], this.props.companies.filter(company => company.employees === sizes[2]).length],
                                [sizes[3], this.props.companies.filter(company => company.employees === sizes[3]).length],
                                [sizes[4], this.props.companies.filter(company => company.employees === sizes[4]).length],
                                [sizes[5], this.props.companies.filter(company => company.employees === sizes[5]).length],
                                [sizes[6], this.props.companies.filter(company => company.employees === sizes[6]).length],
                                [sizes[7], this.props.companies.filter(company => company.employees === sizes[7]).length],
                            //    ["50 or less", this.props.companies.filter(company => company.employees < 50).length],
                            //     ["Between 50 and 250", this.props.companies.filter(company => company.employees >= 50 && company.employees < 250).length],
                            //     ["Between 250 and 1000", this.props.companies.filter(company => company.employees >= 250 && company.employees < 1000).length],
                            //     ["Over 1000", this.props.companies.filter(company => company.employees >= 1000).length],
                            ]}
                            width={"100%"}
                            height={"400px"}
                            options={{ title: "Company Size" }}
                            chartEvents={callback}
                            legendToggle
                        />
                    </div>
                    {/* <div className="w-full md:w-2/4 lg:w-2/4">
                        <Chart
                            chartType="PieChart"
                            data={this.getPlanNamesData()}
                            width={"100%"}
                            height={"400px"}
                            options={{ title: "Plan Breakdown" }}
                            chartEvents={callback}
                            legendToggle
                        />
                    </div> */}
                    <div className="w-full md:w-2/4 lg:w-2/4">
                        <Chart
                            chartType="PieChart"
                            data={this.getValueMetricData()}
                            width={"100%"}
                            height={"400px"}
                            options={{ title: "Pricing Metric Breakdown" }}
                            chartEvents={callback}
                            legendToggle
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default InsightsControl;
