import {  INIT, SORT_ATTRIBUTES, SET_FAV_FILTER_ATTRIBUTE, SET_EXPLORE_SORT_ORDER, SET_FAV_SORT_ORDER, ACQUISITION_FILTER, COMPANYSIZE_FILTER, RESET_FAV_FILTER, SET_EXPLORE_FILTER, SET_USER_FILTER } from "./actionTypes";
import { store } from './store';

const planNameFinder = (companyPlanNames, filterPlanNames) => {
  for (let i = 0; i < companyPlanNames.length; i++) {
    for (let j = 0; j < filterPlanNames.length; j++) {

      if (companyPlanNames[i] && filterPlanNames[j] && companyPlanNames[i].toLowerCase().includes(filterPlanNames[j].toLowerCase())) {
        return true;
      }
    }
  }
  return false;
}

const filterCheck = (company, filtersReducer)  => {

  let result = true;

  if (filtersReducer && filtersReducer.userFilter)  {

    const filter = filtersReducer.userFilter;

     // Apply category filter
     if (filter.categories && filter.categories.length > 0) {
      if (!filter.categories.find( category => category.value === company.category)) {
        return false;
      }
    }

     // Apply acquisition model filter filter
     if (filter.models && filter.models.length > 0) {
      if (!filter.models.find( model => model === company.acquisitionModel)) {
        return false;
      }
     }

    // Apply sizes filter
    if (filter.sizes && filter.sizes.length > 0) {
      if (!filter.sizes.find( size => size === company.employees)) {
        return false;
      }
    }

    if (filter.pricingMetrics && filter.pricingMetrics.length > 0) {
      if (!filter.pricingMetrics.find( pricingMetric => pricingMetric === company.pricingMetric)) {
        return false;
      }
    }

    // Company Names filter
    const companynameFilter = filtersReducer.companyname;

    if (companynameFilter) {
      if (!company.companyId.toLowerCase().includes(companynameFilter.toLowerCase())){
        return false;
      }
    }

    return true;

    // // Pricing Metric filter
    // const pricingMetricsFilter = filtersReducer.valuemetric;
    // if (company.valueMetric && pricingMetricsFilter && pricingMetricsFilter.length > 0) {

    //   let found = false;
    //   for (let i=0; i<pricingMetricsFilter.length; i++) {
    //     const filterPM = pricingMetricsFilter[i].value;

    //     if (company.valueMetric.toLowerCase().includes(filterPM.toLowerCase())) {
    //       console.log("Matrched pricing Metrics!", company.valueMetric);
    //       found = true;
    //       break;
    //     }
    //   }

    //   if (!found) { return false; }
    // }

    // // Acquisition Model
    // const acquisitionFilter = filtersReducer.acquisition;

    // if (acquisitionFilter) {

    //   switch (acquisitionFilter) {
    //     case ACQUISITION_FILTER.ALL:
    //       break;
    //     case ACQUISITION_FILTER.FREEMIUM_ONLY:
    //       return company.freemium && !company.freetrial;
    //     case ACQUISITION_FILTER.FREETRIAL_ONLY:
    //       return !company.freemium && company.freetrial;
    //     case ACQUISITION_FILTER.REVERSE_TRIAL:
    //       return company.freemium && company.freetrial;
    //     case ACQUISITION_FILTER.DEMO_ONLY:
    //       return !company.freemium && !company.freetrial;
    //     default:
    //       break;
    //   }
    // }

    //  // Plan Names filter
    //  const plansFilter = filtersReducer.plannames;

    //  if (company.planNames && plansFilter && plansFilter.length > 0) {
    //    let found = planNameFinder(company.planNames, plansFilter.map( p => p.value));
    //    if (!found) { return false; }
    //  }

    //  // Company Size
    //  const companySizeFilter = filtersReducer.companysize;
    //  if (companySizeFilter) {
    //   if (companySizeFilter === COMPANYSIZE_FILTER.LESSTHAN50) {
    //     result = (company.employees < 50);
    //   }
    //   else if (companySizeFilter === COMPANYSIZE_FILTER.BETWEEN50AND250) {
    //     result = (company.employees >= 50 && company.employees < 250);
    //   }
    //   else if (companySizeFilter === COMPANYSIZE_FILTER.BETWEEN250AND1000) {
    //     result = (company.employees >= 250 && company.employees < 1000);
    //   }
    //   else if (companySizeFilter === COMPANYSIZE_FILTER.OVER1000) {
    //     result = ( company.employees >= 1000);
    //   }
    //  }

    //   // Company Names filter
    //   const companynameFilter = filtersReducer.companyname;

    //   if (companynameFilter) {
    //     let found = company.companyId.toLowerCase().includes(companynameFilter.toLowerCase());
    //     if (!found) { return false; }
    //   }

  }

  return result;
}

export const applyUserFilters = (companies, filtersReducer)  => {

  const results = [];

  if (companies) {

    companies.forEach(company => {

      if (filterCheck(company, filtersReducer)) {
        results.push(company);
      }
    })
  }

  // console.log(results);
  return results;
}

export const applyUserSort = (companies, filtersReducer) => {

  const results = [];
  const sortyBy = filtersReducer.sortyByAttribute;
  const ascending = filtersReducer.sortyByOrder;

  console.log(sortyBy, ascending)

  companies.sort(getSortFn(sortyBy, ascending));

  return companies;

}

export const applyUserFavs = (companies, userReducer) => {
  // apply favourites
  const favs = userReducer && userReducer.favourites ? userReducer.favourites : [];
  console.log("applyUserFavs");
  console.log(favs);
  companies.forEach(company => {
    if (favs.find( fav => fav === company.companyId)) {
      company.isFavourite = true;
      console.log("Updating user fav!!");
    } else {
      company.isFavourite = false;
    }
  })

  return companies;
};

function getSortFn(sortBy, sortAscending) {

  if (sortBy) {
    switch (sortBy) {
      case SORT_ATTRIBUTES.EMPLOYEES:

        return ( a, b) => {
                            const x = a.employees === null ? 0 : parseInt(a.employees);
                            const y = b.employees === null  ? 0 : parseInt(b.employees);
                            return sortAscending ? x-y : y-x;
                           }
      case SORT_ATTRIBUTES.NAME:
        return ( a, b) => { return sortAscending ?  b.companyId.localeCompare(a.companyId) :  a.companyId.localeCompare(b.companyId) }
      case SORT_ATTRIBUTES.VALUEMETRIC:
        return ( a, b) => {
          const vm1 = a.valueMetric;
          const vm2 = b.valueMetric;
          return sortAscending ? vm1.localeCompare(vm2) : vm2.localeCompare(vm1);
        }
      case SORT_ATTRIBUTES.FREEMIUM:
        return ( a, b) => {
          const x = a.freemium;
          const y = b.freemium;
          if (sortAscending) {
            return (x === y)? 0 : x? -1 : 1;
          } else {
            return (x === y)? 0 : x? 1 : -1;
          }
        }

      case SORT_ATTRIBUTES.TRIAL:
        return ( a, b) => {
          const x = a.freetrial;
          const y = b.freetrial;
          if (sortAscending) {
            return (x === y)? 0 : x? -1 : 1;
          } else {
            return (x === y)? 0 : x? 1 : -1;
          }
        }
      case SORT_ATTRIBUTES.ACQUISITION_MODEL:
        return ( a, b) => { return sortAscending ?  b.acquisitionModel.localeCompare(a.acquisitionModel) :  a.acquisitionModel.localeCompare(b.acquisitionModel); };
      case SORT_ATTRIBUTES.PRICINGMETRIC:
        return ( a, b) => { return sortAscending ?  b.pricingMetric.localeCompare(a.pricingMetric) :  a.pricingMetric.localeCompare(b.pricingMetric); };
        return ( a, b) => { return sortAscending ?  b.pricingMetric.localeCompare(a.pricingMetric) :  a.pricingMetric.localeCompare(b.pricingMetric); };
      case SORT_ATTRIBUTES.LAST_MODIFIED:
        return ( a, b) => { return sortAscending ?
          (b.lastModified) - (a.lastModified)
          :
          (a.lastModified) - (b.lastModified); }
      case SORT_ATTRIBUTES.FOUNDING:
        return ( a, b) => {
          if (a.captialRaised && b.captialRaised) return sortAscending ?  b.captialRaised.localeCompare(a.captialRaised) :  a.captialRaised.localeCompare(b.captialRaised);
          else if (!a.captialRaised && !b.captialRaised) return 0;
          else return a.captialRaised ? (a.captialRaised ? -1 : 1) : (a.captialRaised ? 1 : -1);
          // else return 0;
        };

      case SORT_ATTRIBUTES.FOUNDED:
        return ( a, b) => {
          const x = a.yearFounded === null ? 0 : parseInt(a.yearFounded);
          const y = b.yearFounded === null  ? 0 : parseInt(b.yearFounded);
          return sortAscending ? x-y : y-x;
        }
      default:
        break;
    }
  }
  return ( a, b) => { return b.employees - a.employees; }
}

export const setFavSortOrder = (sortBy, ascending) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_FAV_SORT_ORDER,
      payload: {
        sortBy,
        ascending
      }
    });
  };

  store.dispatch(fn);
}


export const setExploreSortOrder = (sortBy, ascending, onSuccessCallback) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_EXPLORE_SORT_ORDER,
      payload: {
        sortBy,
        ascending
      }
    });

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  store.dispatch(fn);
}



export const setExploreFilter = async (filter, onSuccessCallback) => {

  const fn = (dispatch, getState) => {

    dispatch({
      type: SET_EXPLORE_FILTER,
      payload: {
        filter
      }
    });

    if (onSuccessCallback) { onSuccessCallback(); }
  };

  store.dispatch(fn);
}


export const appendExploreFilter = async (type, value, onSuccessCallback) => {

  const fn = (dispatch, getState) => {

    if (!getState().filtersReducer || !getState().filtersReducer.exploreFilter) return;

    const filter = getState().filtersReducer.exploreFilter
    filter[type] = Array.isArray(value) ? value : [value];

    console.log(filter);

    dispatch({
      type: SET_EXPLORE_FILTER,
      payload: {
        filter
      }
    });

    if (onSuccessCallback) { onSuccessCallback(); }
  };

  store.dispatch(fn);
}


export const resetFilter = async (onSuccessCallback) => {

  store.dispatch({
    type: INIT,
  });
  if (onSuccessCallback) { onSuccessCallback(); }
  // const fn = (dispatch, getState) => {

  //   if (!getState().filtersReducer || !getState().filtersReducer.exploreFilter) return;

  //   const filter = getState().filtersReducer.exploreFilter
  //   filter['sizes'] = [];
  //   filter['models'] = [];
  //   filter['categories'] = [];
  //   filter['pricingModels'] = [];

  //   console.log(filter);

  //   dispatch({
  //     type: SET_EXPLORE_FILTER,
  //     payload: {
  //       filter
  //     }
  //   });

  //   if (onSuccessCallback) { onSuccessCallback(); }
  // };

  // store.dispatch(fn);
}



export const setUserFilter = async (filter, onSuccessCallback) => {

  const fn = (dispatch, getState) => {


    dispatch({
      type: SET_USER_FILTER,
      payload: {
        filter
      }
    });

    if (onSuccessCallback) { onSuccessCallback(); }
  };

  store.dispatch(fn);
}

export const setFavFilterAttributes = (filterType, value) => {

    const fn = (dispatch, getState) => {

      dispatch({
        type: SET_FAV_FILTER_ATTRIBUTE,
        payload: {
          filterType,
          value: value
        }
      });
    };

    store.dispatch(fn);
}


export const initFilters = ( ) => {
  const fn = (dispatch, getState) => {
    dispatch({
      type: INIT
    });
  };

  store.dispatch(fn);
};

export const resetFavFilter = () => {

  const fn = (dispatch) => {

    dispatch({
      type: RESET_FAV_FILTER,
    });
  };

  store.dispatch(fn);
}