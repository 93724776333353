/* eslint-disable no-unused-vars */
import React from "react";
import { addUserFav } from "../redux/actionsUsers";

export class AddFavControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: '',
      loading: ''
    }
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);
    const slug = queryParams.get("slug");

    this.setState({ slug });
    this.setState({loading : false});

    console.log(slug);


    // addUserFav(slug, () => { window.location = '/'; } );
    addUserFav(slug, () => { window.close(); } );

    // addUserFav(slug );
  }


  render() {
     return (<>Adding {!this.state.loading && this.state.slug}...</>)
  }
}

export default AddFavControl;
