/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import {
  FILTERTYPE,
  ACQUISITION_FILTER,
  COMPANYSIZE_FILTER,
  GENERIC_FILTER,
} from '../redux/actionTypes';
import {
  setFavFilterAttributes,
  resetFavFilter,
} from '../redux/actionsFilters';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { camelize } from '../helpers/helperFunction';

const animeatedComponents = makeAnimated();

const getPricingMetrics = (state) => {
  const pricingMetrics = [];
  const userCompanies =
    state.companiesReducer && state.companiesReducer.userCompanies
      ? JSON.parse(state.companiesReducer.userCompanies)
      : [];
  if (userCompanies) {
    userCompanies.forEach((c) => {
      pricingMetrics.push(c.valueMetric);
    });
  }
  return [...new Set(pricingMetrics)]
    .sort((a, b) => a.localeCompare(b))
    .map((pm) => ({ value: pm, label: pm, num: 1 }));
};

const getPlanNames = (state) => {
  const planNames = [];
  const userCompanies =
    state.companiesReducer && state.companiesReducer.userCompanies
      ? JSON.parse(state.companiesReducer.userCompanies)
      : [];
  if (userCompanies) {
    userCompanies.forEach((c) => {
      if (c.planNames) {
        for (let j = 0; j < c.planNames.length; j++) {
          planNames.push(camelize(c.planNames[j]));
        }
      }
    });
  }
  return [...new Set(planNames)]
    .sort((a, b) => a.localeCompare(b))
    .map((pm) => ({ value: pm, label: pm, num: 1 }));
};

const mapStateToProps = state => {

    return {
        freemiumFilterandTrialFilter: state.filtersReducer ? state.filtersReducer.acquisition : ACQUISITION_FILTER.ALL,
        companysizeFilter: state.filtersReducer ? state.filtersReducer.companysize : COMPANYSIZE_FILTER.ALL,
        pricingMetricFilter: state.filtersReducer ? state.filtersReducer.valuemetric : [],
        planNameFilter: state.filtersReducer ? state.filtersReducer.plannames : [],
        pricingMetricsData: getPricingMetrics(state),
        planNameData: getPlanNames(state),
    }
};


export class FilterModal extends React.Component {
  filter(state, filterType) {
    setFavFilterAttributes(filterType, state);
  }

  resetFilter() {
    resetFavFilter();
  }

  render() {
    return (
      <Transition.Root show={this.props.show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={this.props.closeHandler}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className='px-12 py-6'>
                                        <div style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => this.props.closeHandler()}
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Filter Companies
                                        </Dialog.Title>
                                        <div className="mt-3 text-left sm:mt-5">
                                            <div className="w-full flex flex-wrap flex-col text-gray-600">
                                                <div className="flex flex-row  items-center justify-center mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                    </svg>
                                                    <label className="w-64  pl-2  " htmlFor="valuemetrics">Pricing Metric:</label>
                                                    <Select
                                                        id="valuemetrics"
                                                        className="w-full p-1 mr-6"
                                                        closeMenuOnSelect={false}
                                                        components={animeatedComponents}
                                                        value={this.props.pricingMetricFilter}
                                                        isMulti
                                                        // options={this.props.valuemetricsData}
                                                        options={this.props.pricingMetricsData}
                                                        onChange={((event) => this.filter(event, FILTERTYPE.VALUEMETRIC))}
                                                    />
                                                </div>
                                                <div className="flex flex-row items-center justify-center mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                    </svg>
                                                    <label htmlFor="freemium" className="w-64 pl-2">Acquisition Model:</label>
                                                    <select onChange={(event) => this.filter(event.target.value, FILTERTYPE.ACQUISITION)}
                                                        value={this.props.freemiumFilterandTrialFilter}
                                                        id="freemium"
                                                        className="w-full p-1.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none h-10 mr-3">
                                                        <option value={ACQUISITION_FILTER.ALL}>All</option>
                                                        <option value={ACQUISITION_FILTER.FREEMIUM_ONLY}>Freemium</option>
                                                        <option value={ACQUISITION_FILTER.FREETRIAL_ONLY}>Free Trial</option>
                                                        <option value={ACQUISITION_FILTER.REVERSE_TRIAL}>Reverse Trial</option>
                                                        <option value={ACQUISITION_FILTER.DEMO_ONLY}>Demo only</option>
                                                    </select>
                                                </div>
                                                <div className="flex flex-row  items-center justify-center mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                    </svg>
                                                    <label className="w-64 pl-2" htmlFor="valuemetrics">Plan Names:</label>
                                                    <Select
                                                        id="planname"
                                                        className="w-full p-1 mr-6"
                                                        closeMenuOnSelect={false}
                                                        components={animeatedComponents}
                                                        value={this.props.planNameFilter}
                                                        isMulti
                                                        options={this.props.planNameData}
                                                        onChange={((event) => this.filter(event, FILTERTYPE.PLANNAMES))}
                                                    />
                                                </div>
                                                <div className="flex flex-row items-center justify-center mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                    </svg>
                                                    <label htmlFor="freemium" className="w-64 pl-2">Company Size:</label>
                                                    <select onChange={(event) => this.filter(event.target.value, FILTERTYPE.COMPANYSIZE)}
                                                        value={this.props.companysizeFilter}
                                                        id="freemium"
                                                        className="w-full p-1.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none h-10 mr-3"
                                                    >
                                                        <option value={COMPANYSIZE_FILTER.ALL}>All</option>
                                                        <option value={COMPANYSIZE_FILTER.LESSTHAN50}>50 or less</option>
                                                        <option value={COMPANYSIZE_FILTER.BETWEEN50AND250}>Between 50 and 250</option>
                                                        <option value={COMPANYSIZE_FILTER.BETWEEN250AND1000}>Between 250 and 1000</option>
                                                        <option value={COMPANYSIZE_FILTER.OVER1000}>Over 1000</option>
                                                    </select>
                                                </div>
                                                {/* <div className="flex flex-row items-center justify-center mt-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                    </svg>
                                                    <label htmlFor="freemium" className="w-64 pl-2">Favorites:</label>
                                                    <select onChange={(event) => this.filter(event.target.value, FILTERTYPE.FAVOURITESTYPE)} value={this.props.favouritesFilter}
                                                        id="freemium"
                                                        className="w-full p-1.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none h-10 mr-3 ">
                                                        <option value={""}>All</option>
                                                        <option value={OPTIONTYPE.ONLYFAVOURITES}>Favorites Only</option>
                                                    </select>
                                                </div> */}
                                                <div className="flex flex-row mt-3 pr-5">
                                                    <button onClick={() => this.resetFilter()} className="text-m text-blue-500 float-left">Reset</button>
                                                </div>
                                                <div className="flex flex-row">
                                                    <h1 className="w-96 pt-3"> Showing: {this.props.filteredCompanies.length} out of {this.props.companies.length} </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default connect(mapStateToProps)(FilterModal);
