import {
  SET_CNP,
  FILTER_CNP,
  SET_FILTER_ATTRIBUTE,
  FILTERTYPE,
  OPTIONTYPE,
  SET_USER_APPROVED,
  UPDATE_FAVS,
} from './actionTypes';
// import { helperLib } from '@buyerson/pricingsaas-pricebook';
import { store } from '../redux/store';
import { addUserFav, removeUserFav } from '../redux/actionsCompanies';
import { selectCompanyAttributes } from './selector';

export const setCnP = (content, favourites) => {
  console.log('in set cnp');
  console.log(content);
  console.log(favourites);

  const companies = [];

  for (let i = 0; i < content.length; i++) {
    const company = content[i].company;
    const pricebook = content[i].pricebook;
    companies.push(selectCompanyAttributes(company, pricebook, favourites));

    // const planNames = helperLib.getPlans(pricebook).length !== 0 ? helperLib.getPlans(pricebook) : [];

    // const valueMetric = helperLib.getPrimaryValueMetric(pricebook);
    // const valueMetricName = valueMetric ? valueMetric.name : '--';

    // const freetrial = helperLib.doesPricebookHaveFreeTrial(pricebook);
    // const freemium = helperLib.doesPricebookHaveFreePlan(pricebook);
    // const lastModified = pricebook.lastModified;
    // const lastModifiedFormatted = lastModified ? timeAgo.format(lastModified) : '--';
    // const enterprisePlan = helperLib.doesPricebookHaveEnterprisePlan(pricebook);
    // const isFavourite = Boolean(favourites.find(favourite => favourite === company.companyId));

    // companies.push({ ...company, valueMetric: valueMetricName, planNames: planNames, freemium, freetrial, lastModified, lastModifiedFormatted, enterprisePlan, isFavourite })
  }

  return {
    type: SET_CNP,
    payload: {
      companies,
    },
  };
};

export const toggleUserFavourite = (slug) => {
  console.log(slug);
  // addUserFav(slug);
  // removeUserFav(slug);

  return;

  const fn = (dispatch, getState) => {
    const state = getState();

    const companiesOriginal = state.cnpReducer.companiesOriginal;
    let companiesFiltered = state.cnpReducer.companies;

    let favouritesFilter = state.cnpReducer.favouritesFilter;

    const companyOriginal = companiesOriginal.find((c) => c.companyId === slug);
    const companyFiltered = companiesFiltered.find((c) => c.companyId === slug);

    if (companyOriginal) {
      companyOriginal.isFavourite = !companyOriginal.isFavourite;

      if (companyFiltered) {
        companyFiltered.isFavourite = companyOriginal.isFavourite;
        console.log('companyFiltered.isFavourite', companyFiltered.isFavourite);
      }
      console.log('companyOriginal.isFavourite', companyOriginal.isFavourite);
    }

    if (favouritesFilter === OPTIONTYPE.ONLYFAVOURITES) {
      companiesFiltered = companiesFiltered.filter((c) => c.isFavourite);
    }

    // if (companyFiltered) {
    //   companyFiltered.isFavourite = !companyFiltered.isFavourite;
    //   console.log('companyFiltered.isFavourite', companyFiltered.isFavourite);
    // }

    dispatch({
      type: UPDATE_FAVS,
      payload: {
        companiesOriginal,
        companiesFiltered,
      },
    });
  };

  store.dispatch(fn);
};

export const setFilterAttributes = (filterType, value) => {
  const fn = (dispatch, getState) => {
    const state = getState();

    let enterpriseFilter = state.cnpReducer.enterpriseFilter;
    let freemiumandtrialFilter = state.cnpReducer.freemiumandtrialFilter;
    let valueMetricFilter = state.cnpReducer.valueMetricFilter;
    let companyFilter = state.cnpReducer.companyFilter;
    let planNameFilter = state.cnpReducer.planNameFilter;
    let companysizeFilter = state.cnpReducer.companysizeFilter;
    let favouritesFilter = state.cnpReducer.favouritesFilter;

    switch (filterType) {
      case FILTERTYPE.FAVOURITESTYPE: {
        favouritesFilter = value;
        break;
      }
      case FILTERTYPE.FREEMIUMANDFREETRIALTYPE: {
        freemiumandtrialFilter = value;
        break;
      }
      case FILTERTYPE.VALUEMETRIC: {
        valueMetricFilter = value.map((element) => element.value);
        break;
      }
      case FILTERTYPE.PLANNAMES: {
        planNameFilter = value.map((element) => element.value);
        break;
      }
      case FILTERTYPE.COMPANYTYPE: {
        companyFilter = value;
        break;
      }
      case FILTERTYPE.COMPANYSIZE: {
        companysizeFilter = value;
        break;
      }
      case FILTERTYPE.RESETTYPE: {
        break;
      }
      default: {
        throw new Error('unknown filter type');
      }
    }

    let filteredCompanies = state.cnpReducer.companiesOriginal;
    if (filterType !== OPTIONTYPE.RESETALL) {
      if (valueMetricFilter) {
        if (valueMetricFilter.length === 0) {
          filteredCompanies = state.cnpReducer.companiesOriginal;
        } else {
          filteredCompanies = [];

          if (valueMetricFilter[0].num) {
            valueMetricFilter = valueMetricFilter.map(
              (element) => element.value
            );
          } else {
            valueMetricFilter.forEach((valueMetric) => {
              filteredCompanies = filteredCompanies.concat(
                state.cnpReducer.companiesOriginal.filter(
                  (pair) =>
                    pair.valueMetric.includes(valueMetric) &&
                    !filteredCompanies.find((e) =>
                      e.valueMetric.includes(valueMetric)
                    )
                )
              );
            });
          }
        }
      }
      const planNameFinder = (companyPlanNames, filterPlanNames) => {
        for (let i = 0; i < companyPlanNames.length; i++) {
          for (let j = 0; j < filterPlanNames.length; j++) {
            if (
              companyPlanNames[i] &&
              filterPlanNames[j] &&
              companyPlanNames[i]
                .toLowerCase()
                .includes(filterPlanNames[j].toLowerCase())
            ) {
              return true;
            }
          }
        }
        return false;
      };
      if (planNameFilter) {
        if (planNameFilter.length === 0) {
          planNameFilter.forEach((planName) => {
            filteredCompanies = filteredCompanies.filter((company) =>
              planNameFinder(company.planNames, planNameFilter)
            );
          });
        } else {
          if (planNameFilter[0].label) {
            planNameFilter = planNameFilter.map((element) => element.value);
          } else {
            planNameFilter.forEach((planName) => {
              filteredCompanies = filteredCompanies.filter((company) =>
                planNameFinder(company.planNames, planNameFilter)
              );
            });
          }
        }
      }
      if (freemiumandtrialFilter === OPTIONTYPE.ONLYFREEMIUM) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.freemium && !company.freetrial
        );
      }
      if (freemiumandtrialFilter === OPTIONTYPE.NONE) {
        filteredCompanies = filteredCompanies.filter(
          (company) => !company.freemium && !company.freetrial
        );
      }

      if (freemiumandtrialFilter === OPTIONTYPE.ONLYFREETRIAL) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.freetrial && !company.freemium
        );
      }
      if (freemiumandtrialFilter === OPTIONTYPE.BOTH) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.freetrial && company.freemium
        );
      }

      if (enterpriseFilter === OPTIONTYPE.ONLYENTERPRISE) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.enterprisePlan
        );
      }
      if (enterpriseFilter === OPTIONTYPE.NONENTERPRISE) {
        filteredCompanies = filteredCompanies.filter(
          (company) => !company.enterprisePlan
        );
      }

      if (companysizeFilter === OPTIONTYPE.LESSTHAN50) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.employees < 50
        );
      }
      if (companysizeFilter === OPTIONTYPE.BETWEEN50AND250) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.employees > 50 && company.employees < 250
        );
      }
      if (companysizeFilter === OPTIONTYPE.BETWEEN250AND1000) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.employees > 250 && company.employees < 1000
        );
      }
      if (companysizeFilter === OPTIONTYPE.OVER1000) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.employees > 1000
        );
      }
      if (favouritesFilter === OPTIONTYPE.ONLYFAVOURITES) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.isFavourite
        );
      }
      if (companyFilter) {
        filteredCompanies = filteredCompanies.filter((company) =>
          company.companyId.includes(companyFilter)
        );
      }
    }

    const dispatchFilterAttribute = (type, value) => {
      dispatch({
        type: SET_FILTER_ATTRIBUTE,
        payload: {
          filterType: type,
          value: value,
        },
      });
    };

    if (filterType === OPTIONTYPE.RESETALL) {
      filteredCompanies = state.cnpReducer.companiesOriginal;
      freemiumandtrialFilter = OPTIONTYPE.ALLFREEMIUMANDTRIAL;
      companysizeFilter = OPTIONTYPE.ALLCOMPANYSIZE;
      favouritesFilter = '';
      valueMetricFilter = [];
      planNameFilter = [];
      companyFilter = '';

      dispatchFilterAttribute(FILTERTYPE.COMPANYTYPE, companyFilter);
      dispatchFilterAttribute(FILTERTYPE.PLANNAMES, planNameFilter);
      dispatchFilterAttribute(FILTERTYPE.VALUEMETRIC, valueMetricFilter);
      dispatchFilterAttribute(
        FILTERTYPE.FREEMIUMANDFREETRIALTYPE,
        freemiumandtrialFilter
      );
      dispatchFilterAttribute(FILTERTYPE.COMPANYSIZE, companysizeFilter);
      dispatchFilterAttribute(FILTERTYPE.FAVOURITESTYPE, favouritesFilter);
    } else {
      dispatchFilterAttribute(filterType, value);
    }

    dispatch({
      type: FILTER_CNP,
      payload: {
        content: filteredCompanies,
      },
    });
  };
  store.dispatch(fn);
};

export const filterCnP = (content) => ({
  type: FILTER_CNP,
  payload: {
    content,
  },
});

export const setUserApproved = (value) => {
  const fn = (dispatch, getState) => {
    dispatch({
      type: SET_USER_APPROVED,
      payload: {
        approved: value,
      },
    });
  };

  store.dispatch(fn);
};
