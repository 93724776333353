import {
  LOAD_COMPANIES,
  LOAD_CATEGORIES,
  CLEAR_SEARCH_RESULTS,
  RESET,
  LOAD_CATEGORY_COMPANIES, SEARCH_RESULTS, SEARCH_SORTBY, LOAD_USER_COMPANIES, LOAD_EXPLORE_COMPANIES, SET_EXPLORE_COMPANIES_LOADING, LOAD_PRICING_METRICS
} from "./actionTypes";
import { store } from './store';
import { callGetApi, callPutApi, callPublicGetApi } from "../helpers/apiHelper";
import { selectCompanyAttributes, selectCategoryCompanyAttributes } from "./selector";

export const getCategoryCompanies = (state) => {

  let companies = [];

  if (state.companiesReducer && state.companiesReducer.categoryCompanies) {
    companies = JSON.parse(state.companiesReducer.categoryCompanies);

    if (state.userReducer && state.userReducer.favourites) {

      const favourites = state.userReducer.favourites;
      companies.forEach(company => {
        company.isFavourite = Boolean(favourites.find(f => f === company.companyId));
      })
    }

  }

  return companies;
}

export const getExploreCompanies = (state) => {

  let companies = [];
  if (state.companiesReducer && state.companiesReducer.exploreCompanies) {
    companies = (state.companiesReducer.exploreCompanies);

    if (state.userReducer && state.userReducer.favourites) {

      const favourites = state.userReducer.favourites;
      companies.forEach(company => {
        company.isFavourite = Boolean(favourites.find(f => f === company.companyId));
      })
    }
  }

  companies.forEach(company => {
    if (company.yearFounded && company.yearFounded.length > 4) {
      company.yearFounded = company.yearFounded.substr(company.yearFounded.length - 4);
    }

    if (isNaN(company.yearFounded)) {
      company.yearFounded = '--';
    }

  })

  return companies;
}

export const getUserCompanies = (state) => {

  let companies = [];
  let results = [];

  if (state.companiesReducer && state.companiesReducer.userCompanies) {
    companies = JSON.parse(state.companiesReducer.userCompanies);

    if (state.userReducer && state.userReducer.favourites) {

      const favourites = state.userReducer.favourites;
      console.log(favourites)
      companies.forEach(company => {
        if (Boolean(favourites.find(f => f === company.companyId))) {
          company.isFavourite = true;
          results.push(company);
        }
      })
    }
  }

  return results;
}

export const resetReducers = async () => {

  const fn = (dispatch, getState) => {
    dispatch({ type: RESET });
  };

  store.dispatch(fn);
};

export const loadCategories = async () => {

  const callback = (response, dispatch, state) => {

    console.log(response);
    dispatch({
      type: LOAD_CATEGORIES,
      payload: {
        categories: response.data
      }
    });

    if (response.pricingMetrics) {
      dispatch({
        type: LOAD_PRICING_METRICS,
        payload: {
          pricingMetrics: response.pricingMetrics
        }
      });
    }

  }

  const callbackError = (data) => {
    console.log("Error")
  }

  const fn = (dispatch, getState) => {
    const state = getState();
    // if (state.companiesReducer && (!state.companiesReducer.categories || !state.companiesReducer.categories.length === 0)) {
    if (true) {
      callGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/getCategories`, (data) => callback(data, dispatch, getState()), callbackError);
    }
  };

  store.dispatch(fn);
}

export const clearCategory = async () => {

  const fn = (dispatch, getState) => {
    dispatch({
      type: LOAD_CATEGORY_COMPANIES,
      payload: {
        companies: []
      }
    });
  };

  store.dispatch(fn);
}

export const loadCategory = async (slug, onSuccess) => {

  const callback = (response, dispatch, state, onSuccessCallback) => {

    const safeCallback = () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }

    const data = (!response || !response.data) ? [] : (response.data);

    const companies = data.map(d => selectCategoryCompanyAttributes(d.company));

    dispatch({
      type: LOAD_CATEGORY_COMPANIES,
      payload: {
        companies: companies
      }
    });

    safeCallback();

  }

  const callbackError = (data) => {
    console.log("Error")
  }

  const fn = (dispatch, getState) => {
    callGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/getCategoryCompanies?` + new URLSearchParams({ slug }), (data) => callback(data, dispatch, getState(), onSuccess), callbackError);
  };

  store.dispatch(fn);
}

export const loadUserCompanies = async (onSuccess) => {
  console.log("Loading user companies")

  const callback = (response, dispatch, state, onSuccessCallback) => {

    const safeCallback = () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }

    const data = (!response || !response.data) ? [] : (response.data);

    const companies = data.map(d => selectCompanyAttributes(d.company, d.pricebook));

    companies.forEach(company => {
      if (company.yearFounded && company.yearFounded.length > 4) {
        company.yearFounded = company.yearFounded.substr(company.yearFounded.length - 4);
      }

      if (isNaN(company.yearFounded)) {
        company.yearFounded = '--';
      }
    })

    dispatch({
      type: LOAD_USER_COMPANIES,
      payload: {
        companies: companies
      }
    });

    safeCallback();
  }

  const callbackError = (data) => {
    console.log("Error");
  }

  const fn = (dispatch, getState) => {
    callGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/getUserCompanies`, (data) => callback(data, dispatch, getState(), onSuccess), callbackError);
  };

  store.dispatch(fn);
}

const temp =
  [
    {
      "name": "Users",
      "count": 30
    },
    {
      "name": "Location",
      "count": 1
    },
    {
      "name": "shipments",
      "count": 1
    },
    {
      "name": "Creator",
      "count": 1
    },
    {
      "name": "Individuals",
      "count": 1
    },
    {
      "name": "Account",
      "count": 1
    },
    {
      "name": "Revenue",
      "count": 3
    },
    {
      "name": "Server Capacity",
      "count": 1
    },
    {
      "name": "Seats",
      "count": 4
    },
    {
      "name": "Attorneys",
      "count": 1
    },
    {
      "name": "Interview",
      "count": 1
    },
    {
      "name": "Visitors",
      "count": 1
    },
    {
      "name": "Bandwidth",
      "count": 1
    },
    {
      "name": "Active Rows",
      "count": 1
    },
    {
      "name": "Vehicles",
      "count": 1
    },
    {
      "name": "Contacts",
      "count": 1
    },
    {
      "name": "Mailbox",
      "count": 1
    },
    {
      "name": "Leads",
      "count": 1
    },
    {
      "name": "Members",
      "count": 3
    },
    {
      "name": "Query",
      "count": 1
    },
    {
      "name": "Page Views",
      "count": 1
    },
    {
      "name": "Responses",
      "count": 1
    },
    {
      "name": "Project",
      "count": 1
    },
    {
      "name": "Runs",
      "count": 1
    },
    {
      "name": "Attendees",
      "count": 1
    },
    {
      "name": "Transfers",
      "count": 1
    },
    {
      "name": "Identifications",
      "count": 1
    },
    {
      "name": "Storage",
      "count": 1
    },
    {
      "name": "agents",
      "count": 1
    }
  ];

export const loadExploreCompanies = async (page = 0, onSuccessCallback) => {

  const callback = (response, dispatch, state, onSuccessCallback) => {

    const safeCallback = () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    }

    console.log(response);

    const data = (!response || !response.data) ? [] : (response.data);
    const insights = (!response || !response.insights) ? [] : (response.insights);
    const insightsAll = (!response || !response.insightsAll) ? [] : (response.insightsAll);
    const totalCompanies = (!response || !response.totalCompanies) ? 0 : (response.totalCompanies);
    const totalAllCompanies = (!response || !response.totalAllCompanies) ? 0 : (response.totalAllCompanies);
    const employeeStats = (!response || !response.employeeStats) ? 0 : (response.employeeStats);
    const categoryStats = (!response || !response.categoryStats) ? 0 : (response.categoryStats);
    const pricingStats = (!response || !response.pricingStats) ? 0 : (response.pricingStats);
    const acqStats = (!response || !response.acqStats) ? 0 : (response.acqStats);
    const companiesPerPage = (!response || !response.companiesPerPage) ? 0 : (response.companiesPerPage);
    const currentPage = (!response || !response.currentPage) ? 0 : (response.currentPage);

    const companies = data; //data.map( d => selectCompanyAttributes(d.company, d.pricebook) );

    dispatch({
      type: LOAD_EXPLORE_COMPANIES,
      payload: {
        companies: companies,
        insights: temp,
        insightsAll: temp,
        totalCompanies,
        totalAllCompanies,
        employeeStats,
        categoryStats,
        pricingStats,
        acqStats,
        companiesPerPage,
        currentPage
      }
    });

    dispatch({
      type: SET_EXPLORE_COMPANIES_LOADING,
      payload: { loading: false }
    });

    safeCallback();
  }

  const fn = (dispatch, getState) => {

    // Extract the filter from state and pass it to the call
    const state = getState();
    const exploreFilter = state.filtersReducer && state.filtersReducer.exploreFilter ? state.filtersReducer.exploreFilter : {};

    dispatch({
      type: SET_EXPLORE_COMPANIES_LOADING,
      payload: { loading: true }
    });

    const param = { page, filter: exploreFilter };
    console.log(param);
    callPutApi(`${process.env.REACT_APP_ENDPOINT_URL}/getExploreCompanies`, param, (data) => callback(data, dispatch, getState(), onSuccessCallback),
      () => {
        (dispatch({
          type: SET_EXPLORE_COMPANIES_LOADING,
          payload: { loading: false }
        }))
      });
  };

  store.dispatch(fn);
}

export const loadCompanyNames = async (force = false) => {

  const callback = (response, dispatch, state) => {

    dispatch({
      type: LOAD_COMPANIES,
      payload: {
        companies: response.data
      }
    });
  }

  const callbackError = (data) => {
    console.log("Error")
  }

  const fn = (dispatch, getState) => {

    // See if they really need to be re-loaded
    if (force || !getState().companiesReducer || !getState().companiesReducer.allCompanyNames || !getState().companiesReducer.allCompanyNames.length) {
      callGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/getCompanies`, (data) => callback(data, dispatch, getState()), callbackError);
    } else {
      console.log("Skipping loadCompanyNames as they already seem to be loaded...");
    }
  };

  store.dispatch(fn);
}


export const resetSearchCompanies =  () => {

  store.dispatch({
    type: SEARCH_RESULTS, // ? SEARCH_RESULTS
    payload: {
      companies: [],
      keyword : ""
    }
  });

  store.dispatch({
    type: SEARCH_SORTBY, // ? SEARCH_RESULTS
    payload: {
      sortBy : "relevance"
    }
  });
};

export const setSearchBy = (employees) => {

  const sortBy = employees ? 'employees' : 'relevance';

  store.dispatch({
    type: SEARCH_SORTBY,
    payload: {
      sortBy
    }
  });
};

export const clearSearchResults = () => {


  const fn = (dispatch, getState) => {

    dispatch({
      type: CLEAR_SEARCH_RESULTS, // ? SEARCH_RESULTS

    });

  };
  store.dispatch(fn);
}

export const searchCompanies = async (keyword, onSuccessCallback, callbackError) => {

  const safeCallback = (results) => {
    if (onSuccessCallback) {
      onSuccessCallback(results);
    }
  }

  const callback = (response, dispatch, state) => {

    console.log(response.data);

    dispatch({
      type: SEARCH_RESULTS, // ? SEARCH_RESULTS
      payload: {
        companies: response.data,
        companiesByEmployees: response.data2,
        results : response.results,
        keyword
      }
    });

    safeCallback(response.results);
  }


  const param = { keyword, searchBy : 'employees' };
  const fn = (dispatch, getState) => {
    console.log('calling api')
    callPutApi(`${process.env.REACT_APP_ENDPOINT_URL}/searchCompanies`, param, (data) => callback(data, dispatch, getState()), callbackError);
  };

  store.dispatch(fn);
}
export const publicSearchCompanies = async (keyword, onSuccessCallback, callbackError) => {

  const safeCallback = (results) => {
    if (onSuccessCallback) {
      onSuccessCallback(results);
    }
  }

  const callback = (response, dispatch, state) => {

    console.log(response.data);

    dispatch({
      type: SEARCH_RESULTS, // ? SEARCH_RESULTS
      payload: {
        companies: response.data,
        companiesByEmployees: response.data2,
        results : response.results,
        keyword
      }
    });

    safeCallback(response.results);
  }


  const fn = (dispatch, getState) => {
    console.log('calling api')
    callPublicGetApi(`${process.env.REACT_APP_ENDPOINT_URL}/search?type=api&keyword=${encodeURIComponent(keyword)}`, (data) => callback(data, dispatch, getState()), callbackError);
  };

  store.dispatch(fn);
}