import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FavsControl from './controls/favsControl';
import ViewControl from './controls/viewControl';
import AddFavControl from './controls/addFavControl';
import ResetControl from './controls/resetControl';
import ExploreControl from './controls/exploreControl';
import InsightsControl from './controls/insightsControl';
import PlansControl from './controls/plansControl';
import OnboardingAdd from './components/Onboarding/OnboardingAdd';
import SuccessControl from './controls/successControl';
import { AddCompanyControl } from './controls/addCompanyControl';
import Settings from './components/Settings/Settings';
import Profile from './components/Settings/Profile';
import MySet from './components/Sets/MySet';
import CompanyDetailsControl from './components/Sets/CompanyDetailsControl';
import PublicCompanyControl from './components/PublicCompanyControl';
import CompanyControl from './components/CompanyControl';
import Checkout from './components/Settings/Checkout';
import ImageDiff from './components/Settings/ImageDiff';
import { loadUser, setUserName, loadCompanies, updateUserEmail, setLoginRedirect, invalidate } from './redux/actionsUsers';
import { loadSetPageInfo } from './redux/actionsPages';
import { refresh } from './redux/actionsUsers';
import { isFreemiumUser } from './helpers/common';
import SearchControl from './components/SearchControl';
import PublicSearchControl from './components/PublicSearchControl';
import InviteControl from './components/Settings/InviteControl';
import './App.css';

const myRouter = (
  <Routes>
    <Route path="/" element={<MySet />} />
    <Route path="/set" element={<MySet />} />
    {/* <Route path="/company" element={<CompanyDetailsControl />} /> */}
    <Route path="/checkout" element={<Checkout />} />
    <Route path="/company" element={<CompanyControl />} />
    {/* <Route path="/companies" element={<Profile />} /> */}
    {/* <Route path="/add" element={<AddFavControl />} /> */}
    {/* <Route path="/favourites" element={<FavsControl />} /> */}
    {/* <Route path="/favourites/view" element={<ViewControl />} /> */}
    {/* <Route path="/explore" element={<ExploreControl />} /> */}
    {/* <Route path="/explore/view" element={<ViewControl />} /> */}
    {/* <Route path="/view" element={<ViewControl />} /> */}
    {/* <Route path="/insights" element={<InsightsControl />} /> */}
    <Route path="/plans" element={<PlansControl />} />
    <Route path="/diff" element={<ImageDiff />} />
    {/* <Route path="/welcome" element={<OnboardingAdd />} />
    <Route path="/welcome-add" element={<OnboardingAdd />} /> */}
    <Route path="/success" element={<SuccessControl />} />
    <Route path="/new" element={<AddCompanyControl />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/reset" element={<ResetControl />} />
    <Route path="/search" element={<SearchControl />} />
    <Route path="/psearch" element={<PublicSearchControl autenticated={false} />} />
    <Route path="/workspaceInvite" element={<InviteControl />} />
  </Routes>
);

const loadingHTML = (
  <div className="flex h-screen">
    <div className="m-auto">
      <h3>Loading...</h3>
    </div>
  </div>
);

// let navigation = [
//   { name: 'Dashboard', href: '/', current: false },
//   { name: 'Settings', href: '/settings', current: false },
// ];
function getPathWithQueryParams() {
  // Create a new URL object using the current window location
  const url = new URL(window.location.href);

  // Get the pathname
  const pathname = url.pathname;

  // Get the query string
  const queryString = url.search;

  // Combine them into the desired format
  const fullPathWithQueryParams = `${pathname}${queryString}`;

  return fullPathWithQueryParams;
}

function App() {
  const { loginWithRedirect, isAuthenticated, isLoading, user, handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();

  const userReducer = useSelector((state) => state.userReducer);
  const companiesReducer = useSelector((state) => state.companiesReducer);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [loadingSetData, setLoadingSetData] = useState(false);
  const [emailParam, setEmailParam] = useState();
  const url = window.location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  const queryParam = queryParams.get('queryParam');
  const authParam = queryParams.get('auth');

  useEffect(() => {
    if (queryParam && queryParam !== emailParam) {
      setEmailParam(queryParam);
    }
  }, [queryParam, emailParam]);

  useEffect(() => {
    async function handleAuthRedirect() {
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        try {
          await handleRedirectCallback();

        } catch (error) {
          console.error(error);
        }
      }
    }
    handleAuthRedirect();
  }, [handleRedirectCallback, navigate]);

  const publicRoute = url.includes('/psearch') || url.includes('/pcompany');

  useEffect(() => {
    if (!publicRoute && !isLoading && !isAuthenticated) {
      if (url && url !== '/') {

        console.log('Setting deep link to', getPathWithQueryParams());
        localStorage.setItem('redirectPath', getPathWithQueryParams());
      }

      loginWithRedirect({
        screen_hint: authParam === 'signup' ? 'signup' : 'login',
      });
    }
    // else if (url === '/') {
    //   const redirectPath = localStorage. getItem('redirectPath');
    //   console.log('Redirect path', redirectPath);
    //   if (redirectPath) {
    //     localStorage.removeItem('redirectPath');
    //     window.location = redirectPath;
    //   } else {
    //     navigate('/');
    //   }
    // }

  }, [isLoading, isAuthenticated, url, loginWithRedirect]);

  if (isLoading) {
    return loadingHTML;
  }

  if (isAuthenticated) {
    if (userReducer && userReducer.loginRedirect) {
      if (userReducer.loginRedirect === url) {
        setLoginRedirect('');
      } else {
        window.location = userReducer.loginRedirect;
      }
    }
  }

  if (url.includes('/psearch')) {

    return (
      <div className="py-16 px-3 lg:px-9 max-w-screen-xl m-auto">
        <div><PublicSearchControl authenticated={false}></PublicSearchControl></div>
      </div>
    );
  } else if (url.includes('/pcompany')) {
    return (
      <div className="py-16 px-3 lg:px-9 max-w-screen-xl m-auto">
        <div><PublicCompanyControl authenticated={false}></PublicCompanyControl></div>
      </div>
    );
  }
  // return <>hi</>


  if (url.includes('reset')) {
    return <ResetControl />;
  }

  if (!loadingData && companiesReducer) {
    console.log('companiesReducer is not empty, kicking off refresh!');
    refresh();
  }

  if (!loadingData && (!userReducer || !userReducer.companyList || !userReducer.currentSet)) {
    console.log('Loading user and companies');
    setLoadingData(true);
    setLoadingUserData(true);
    setLoadingSetData(true);

    loadUser(() => {
      setLoadingUserData(false);
      loadCompanies(() => setLoadingData(false));
      loadSetPageInfo(
        null,
        () => setLoadingSetData(false),
        (msg) => {
          setLoadingSetData(false);
          console.log(msg);
          return <>Hmmmm... something went wrong.</>;
        }
      );

      if (url.endsWith('/')) return (window.location = '/set');
    });
  }

  if (userReducer && userReducer.invalidated) {
    console.log('Loading user as it has been invalidated');
    loadUser();
  } else if (
    userReducer &&
    (!userReducer.lastInvalidated ||
      new Date().getTime() - userReducer.lastInvalidated > 1000 * 60 * 24)
  ) {
    console.log('Invalidating user as it has been more than 24 hours since last invalidation');
    invalidate();
  }

  if (userReducer && userReducer.entitlement && !userReducer.email) {
    console.log('Found a user without email.');

    if (user.email) {
      updateUserEmail(user.email);
    } else {
      console.error('user.email is null');
    }
  }

  if (loadingData || loadingUserData || loadingSetData || !userReducer) {
    return loadingHTML;
  }

  const showWelcome = false;
  const freemium = isFreemiumUser(userReducer);

  if (url === '/') {
    if (showWelcome) {
      return (window.location = '/welcome');
    } else if (userReducer && userReducer.currentSetId) {
      return (window.location = '/set');
    } else {
      return (window.location = '/set');
    }
  }

  if (url.startsWith('/welcome')) {
    return <div className="max-w-screen-xl m-auto">{myRouter}</div>;
  }

  return (
    <div className="py-16 px-3 lg:px-9 max-w-screen-xl m-auto">
      <div>{myRouter}</div>
    </div>
  );
}

export default App;
