/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from 'react-redux'
import { loadExploreCompanies, loadCategories, getExploreCompanies } from "../redux/actionsCompanies";
import { setExploreFilter } from "../redux/actionsFilters";
import { getCategoryCompanies } from "../redux/actionsCompanies";
import { loadUser } from "../redux/actionsUsers";
import CategoryControl from "./categoryControl";
import ListControl from "./listControl";
import ReportModal from "./reportModal";
import PagerComponent from "../components/PagerComponent/PagerComponent";
import FilterExploreModal from "../components/FilterExploreModal/FilterExploreModal";
import { setExploreSortOrder } from "../redux/actionsFilters";
import { sizes, models, pricingModels } from "../helpers/common";

const mapStateToProps = state => {


  const exploreCompanies =  getExploreCompanies(state);
  const exploreTotalCompanies = state.companiesReducer &&  state.companiesReducer.exploreTotalCompanies ? state.companiesReducer.exploreTotalCompanies : 0;
  const overthelimit = (exploreTotalCompanies > 0) && (!exploreCompanies || exploreCompanies.length === 0);

  return {
    categories : state.companiesReducer &&  state.companiesReducer.categories ? state.companiesReducer.categories : [],
    companies : getCategoryCompanies(state),
    paidUser : state.userReducer && state.userReducer.entitlement ? state.userReducer.entitlement.fullExport : false,
    overthelimit,
    // pricingMetrics : ['Seats', 'Users', 'members'], //state.filtersReducer &&  state.filtersReducer.pricingMetrics ? state.filtersReducer.pricingMetrics : [],
    pricingMetrics : state.filtersReducer &&  state.filtersReducer.pricingMetrics ? state.filtersReducer.pricingMetrics.map( ps => ps.name) : [],
    exploreCompanies : exploreCompanies,
    exploreLoading :  state.companiesReducer ? state.companiesReducer.exploreLoading : false,
    exploreFilter :  state.filtersReducer &&  state.filtersReducer.exploreFilter ? state.filtersReducer.exploreFilter : {},
    exploreTotalCompanies: state.companiesReducer &&  state.companiesReducer.exploreTotalCompanies ? state.companiesReducer.exploreTotalCompanies : 0,
    exploreCompaniesPerPage: state.companiesReducer &&  state.companiesReducer.exploreCompaniesPerPage ? state.companiesReducer.exploreCompaniesPerPage : 0,
    exploreCurrentPage: state.companiesReducer &&  state.companiesReducer.exploreCurrentPage ? state.companiesReducer.exploreCurrentPage : 0,
    exploreTotalPages: state.companiesReducer &&  state.companiesReducer.exploreTotalCompanies &&  state.companiesReducer.exploreCompaniesPerPage ?
      Math.ceil(state.companiesReducer.exploreTotalCompanies/state.companiesReducer.exploreCompaniesPerPage)  : 0

  }
};

export const COLUMNS = {
  EMPLOYEES: 'employees',
  NAME: 'name',
  VALUEMETRIC: 'valuemetric',
  FREEMIUM: 'freemium',
  TRIAL: 'trial',
  LAST_MODIFIED: 'lastmodified',
  FAVOURITES: 'favourites',
};

export class ExploreControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slug: '',
      refreshing: false,
      showReportModal : false,
      showFilter : false,
      showLightbox : false,
      sortBy : "lastmodified",
      sortAscending : true,
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const slug = queryParams.get('slug');
    this.setState({ slug });

    console.log("LOading user");
    loadUser();
    loadCategories();
    loadExploreCompanies(0);

    if (slug) {
      console.log('loading companies for ', slug);
      this.setState({ loadingCategory: true });
      const onSuccess = () => {
        this.setState({loadingCategory: false});
      }
      // loadCategory(slug, onSuccess);

    } else {
      console.log('loading categories')
      // clearCategory();
      // loadCategories();
    }

    // console.log('slug', slug);
    this.sortByColumn(this.state.sortBy);
  }

  getSortFn(sortByArg) {
    const sortBy = sortByArg ? sortByArg : this.state.sortBy;
    const sortAscending = this.state.sortAscending;

    console.log('Sorting by', sortBy);

    if (sortBy) {
      switch (sortBy) {
        case COLUMNS.EMPLOYEES:
          return (a, b) => {
            return sortAscending
              ? parseInt(b.employees) - parseInt(a.employees)
              : parseInt(a.employees) - parseInt(b.employees);
          };
        case COLUMNS.NAME:
          return (a, b) => {
            return sortAscending
              ? b.companyId.localeCompare(a.companyId)
              : a.companyId.localeCompare(b.companyId);
          };
        case COLUMNS.VALUEMETRIC:
          return (a, b) => {
            const vm1 = a.valueMetric;
            const vm2 = b.valueMetric;
            return sortAscending
              ? vm1.localeCompare(vm2)
              : vm2.localeCompare(vm1);
          };
        case COLUMNS.FREEMIUM:
          return (a, b) => {
            const x = a.freemium;
            const y = b.freemium;
            if (sortAscending) {
              return x === y ? 0 : x ? -1 : 1;
            } else {
              return x === y ? 0 : x ? 1 : -1;
            }
          };
        case COLUMNS.TRIAL:
          return (a, b) => {
            const x = a.freetrial;
            const y = b.freetrial;
            if (sortAscending) {
              return x === y ? 0 : x ? -1 : 1;
            } else {
              return x === y ? 0 : x ? 1 : -1;
            }
          };
        case COLUMNS.LAST_MODIFIED:
          return (a, b) => {
            return sortAscending
              ? b.lastModified - a.lastModified
              : a.lastModified - b.lastModified;
          };
        default:
          break;
      }
    }
    const sortFn = (a, b) => {
      return b.employees - a.employees;
    };
    return sortFn;
  }

  sortByColumn(column) {
    this.setState({ sortBy: column, sortAscending: !this.state.sortAscending });
  }

  renderAll = () => {

    const pricingMetrics = !this.props.exploreFilter.pricingMetrics ? [] : this.props.exploreFilter.pricingMetrics;

    return (<>
      <div className="my-9">
        <div className="w-full flex justify-between">
        <div>{this.props.exploreLoading ? "Refreshing..." : " "}</div>
        <button onClick={() => this.setState({ showReportModal : true })}
                className="border text-white border-teal-500 font-bold py-2 px-2 w-48 text-center mb-3"
                style={{ background: '#008CFF'}}>
                  Generate Report
        </button>
        </div>
        <PagerComponent
          currentPage={this.props.exploreCurrentPage}
          totalPages={ this.props.exploreTotalPages }
          itemsPerPage={this.props.exploreCompaniesPerPage}
          totalItems={this.props.exploreTotalCompanies}
          clickHandler={(page) => { loadExploreCompanies(page);} }
          showFilterHandler={() => { this.setState({ showFilter: true })}}/>
      </div>
      <FilterExploreModal
        show={ this.state.showFilter }
        pricingMetricsData={ this.props.pricingMetrics.map( (pricingMetric,index) => ({ value: pricingMetric, label: pricingMetric, num : index})) }
        categories={ this.props.categories  }
        categoriesData={this.props.exploreFilter.categories}
        sizesData={ sizes }
        modelData={ models }
        pricingModelsData={ this.props.exploreFilter.pricingModels }
        pricingModels={ pricingModels }
        searchTerm={this.props.exploreFilter.searchTerm}
        sizes={this.props.exploreFilter.sizes}
        models={this.props.exploreFilter.models}
        pricingMetrics={pricingMetrics.map( (pricingMetric,index) => ({ value: pricingMetric, label: pricingMetric, num : index}))}
        onFilterHandler={ (filter) => { setExploreFilter(filter, () => loadExploreCompanies(0)); this.setState({ showFilter: false }); } }
        onCloseHandler={() => { this.setState({ showFilter: false })}}  />

      {
        this.props.overthelimit ?
          <>
            <div className="p-9">
              <p>Oooh... it looks like you've selected a large data set. Upgrade to a <a href="/plans" className="text-blue-500">paid plan </a> or apply more filters to narrow down the search results.</p>
            </div>
          </>
          :
        <ListControl
          filteredCompanies={this.props.exploreCompanies}
          sortOrderHandler={(sortBy, asc) => { setExploreSortOrder(sortBy, asc, () => loadExploreCompanies(0)); } }
          showLightbox={this.state.showLightbox}
          hideLightbox={() => { this.setState({ showLightbox: false })} } />
      }

      <div className="flex mt-3 mb-9">
        <ReportModal show={ this.state.showReportModal } paidUser={this.props.paidUser} companies={ [] } filter={ this.props.exploreFilter  } closeHandler={() => { this.setState({ showReportModal: false })} } />
      </div>
      </>)


  }

  renderCatgeory = () => {
    // render list or insights
    if (this.state.loadingCategory) {
      return (
        <>
          <div className="w-full my-3">
            Loading ... this will take just a second.
          </div>
        </>
      );
    }

    return (
      <>
        <div className="w-full">
          <div className="pt-6 w-full flex flex-row-reverse">
            <button
              onClick={() => this.setState({ showReportModal: true })}
              style={{ background: '#202046' }}
              className="border text-white border-teal-500 font-bold py-2 px-2 w-48 text-center "
            >
              Generate Report
            </button>
          </div>

          <div className="flex mt-3 mb-9">
            <ReportModal
              show={this.state.showReportModal}
              companies={this.props.companies}
              closeHandler={() => {
                this.setState({ showReportModal: false });
              }}
            />
          </div>
          <CategoryControl />
        </div>
      </>
    );
  };

  render() {
    if (this.state.refreshing) {
      return (
        <>
          <div className="w-full text-center mt-24">
            Loading data... this will take a few seconds!
          </div>
        </>
      );
    }

    if (this.state.slug) {
      return this.renderCatgeory();
    } else {

      return this.renderAll();
    }

    const categories = this.props.categories; // [{ label: 'Analyticss', count: 442},  { label: 'Artificial Intelligence', count: 123},  { label: 'CRM', count: 33},  { label: 'Collaboration & Productivity', count: 52}]

    return (
      <>
        <div className="w-full my-3">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  onClick={() => {
                    this.sortByColumn(COLUMNS.NAME);
                  }}
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  onClick={() => {
                    this.sortByColumn(COLUMNS.NAME);
                  }}
                >
                  Count
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {categories.map((category, index) => (
                // <tr key={index} className="hover:bg-blue-50 cursor-pointer" onClick={ () => 'window.location="/explore/"' + category.slug }>
                <tr
                  key={index}
                  className="hover:bg-blue-50 cursor-pointer"
                  onClick={() =>
                    (window.location = '/explore?slug=' + category.slug)
                  }
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex flex-row align-middle">
                      {category.label}
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div className="flex flex-row align-middle">
                      {category.count == '-1' ? ' ' : category.count}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(ExploreControl);
