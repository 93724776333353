/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyCompetitors, addUserSet, submitUserFav } from '../../redux/actionsUsers';
import Autosuggest from 'react-autosuggest';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function parseFav(urlParam) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    let url = urlParam.replace("www.", "");
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname.lastIndexOf(".") > 0 ? hostname.slice(0, hostname.lastIndexOf(".")) : hostname;
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, companies) => {

    if (!value || !companies) return [];

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let retValue = inputLength === 0 ? [] : companies.filter(company =>
        company.slug.toLowerCase().includes(inputValue.toLowerCase())
    ); // .slice(0, 10);

    retValue.sort((a, b) => {

        if (a.slug === value) {
            return -1;
        };
        if (b.slug === value) {
            return 1;
        };

        return a.slug.localeCompare(b.slug)
    });
    retValue = retValue.slice(0, 10);


    return retValue;
};


// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {

    if (!suggestion) return (<></>);

    if (suggestion.add) {
        return (
            <div className='flex flex-inline gap-x-1 align-middle italic '>
                <img className='h-5 w-5 align-middle mr-3' src="/icons/add.png"></img>Add "{suggestion.slug}"

            </div>)
    }

    return (
        <div className='flex flex-inline gap-x-1 align-middle	'>
            <img className='h-9 w-9' src={suggestion.logoUrl}></img><span className='mt-1'> {suggestion.slug}</span>
        </div>
    )
};
function hasInvalidCharacters(str) {
    // Check for spaces
    if (str.includes(" ")) {
        return true;
    }

    // Check for invalid URI characters
    const invalidCharsRegex = /[<>#%{}|\\"^[\]`]/;
    if (invalidCharsRegex.test(str)) {
        return true;
    }

    return false;
}

function includesPeriodAndCharacter(str) {
    const pattern = /\.[^\s]*$/;
    return pattern.test(str);
}

// TODO: add API that sets local userSettings upon render
const OnboardingAdd = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const slugParam = searchParams.get('slug');

    const userReducer = useSelector(state => state.userReducer);
    const [value, setValue] = useState(slugParam ? slugParam : "");
    const [validationMessage, setValidationMessage] = useState('');
    const [userInput, setUserInputFn] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [addLogoUrl, setAddLogoUrl] = useState("/icons/glass.png");
    const [name, setName] = useState("");
    const [fetching, setFetching] = useState(false);
    const [fetchingMessage, setFetchingMessage] = useState("Fetching...");
    const [competitors, setCompetitors] = useState([]);
    const [selected, setSelected] = useState([]);
    const [step, setStep] = useState(1);




    useEffect(() => {

        if (slugParam && userReducer && userReducer.companyList) {

            if (userReducer.companyList.find(c => c.slug === slugParam)) {
                console.log("GOTCHA")
                //
                setFetching(true);
                getCompanyCompetitors(slugParam, (data) => { setCompetitors(data); setStep(2); setFetching(false); });
                setStep(2);
            } else {
                setStep(1);
            }
        }

    }, []);


    if (!userReducer || !userReducer.companyList) { return <></>; }

    const isPaidAccount = userReducer && userReducer.entitlement ? userReducer.entitlement.maxpages > 0 : false;

    // const myCompanies = userReducer.myCompanies ? userReducer.myCompanies.sort((a, b) => a && a.slug && b && b.slug ? a.slug.localeCompare(b.slug) : false) : [];
    const companies = userReducer.companyList;

    const setUserInput = (value) => {
        let param = value;
        param = param.replace("https://", "");
        param = param.replace("http://", "");

        setUserInputFn(param);
    }

    const getLogoUrl = (slug) => {

        const company = companies.find(c => c.slug === slug.trim());
        console.log(companies.filter(c => c.slug.includes("last")));

        if (company) {
            return company.logoUrl;
        } else {
            console.error("NOT FOUND, ", slug);
        }

        return "";
    }

    const toggleSelected = (slug) => {

        if (selected.find(s => s === slug)) {
            setSelected(selected.filter(s => s !== slug));
        } else {
            setSelected([...selected, slug]);
        }

        setValidationMessage("");
    }
    const isSelected = (slug) => {

        return selected.find(s => s === slug);
    }

    const reset = () => {
        setCompetitors([]);
        window.location = '/welcome';
    }

    // if (competitors.length === 0 && step > 0) {
    //     // setStep(0);
    //     setCompetitors([]);
    // }

    if (fetching) {
        return (<>
            <div className="flex h-screen ">
                <div className="m-auto text-2xl text-gray-700 p-2">
                    <h3>{fetchingMessage}</h3>
                </div>
            </div>
        </>);
    }


    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = suggestion => {

        return suggestion.slug;

    }

    const changeHandler = (event, { newValue, method }) => {
        console.log("changed");
    }



    const onSelectedHandler = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        const value = suggestionValue;
        const company = userReducer.companyList.find(c => c.slug === value)
        console.log(value)

        if (company) {
            console.log("Found it!");
            // setValue("");
            // addUserFav(value, () => setValue(""));

            setAddLogoUrl(company.logoUrl);
        } else {
            setAddLogoUrl("/icons/glass.png");
        }
        // else {
        //     setValue(value.replace("https://", ""));
        //     setShowAddModal(true);
        // }
    }

    const onChange = (event, { newValue, method }) => {

        // console.log(method);

        setUserInputFn(newValue);
        setValidationMessage("");

        const company = userReducer.companyList.find(c => c.slug === newValue)

        if (company) {
            console.log("Found it!");
            // setValue("");
            // addUserFav(value, () => setValue(""));

            setAddLogoUrl(company.logoUrl);
        } else {
            setAddLogoUrl("/icons/glass.png");
        }


    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value, companies));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const goToStep = (stepNum) => {

        setValidationMessage("");
        setStep(stepNum);
        setFetching(false);
        if (stepNum <= 2) setSelected([]);
        if (stepNum <= 3) setName("");
    }

    // const step4Handler = () => {

    //     const setName = name ? name : `${userInput ? parseFav(userInput) : "My"} Set`;

    //     console.log(setName);

    //     setFetchingMessage("Creating your set... one sec!");

    //     setFetching(true);

    //     //http://localhost:3000/set?setId=a2bf3043-8b57-4ec6-b59b-7033ac641e36


    //     // Check if this is a new company
    //     if (!userReducer.companyList.find(c => c.slug === userInput)) {
    //         const slug = parseFav(userInput);

    //         const url = !userInput.endsWith("pricing") ? userInput + "/pricing" : userInput;
    //         console.log("calling submitUserFav with ", url);

    //         submitUserFav(url,
    //             () => addUserSet(setName, [...selected, slug], (setId) => window.location = isPaidAccount ? `/set` : `/checkout`)
    //         );

    //     } else {
    //         addUserSet(setName, selected, (setId) => window.location = isPaidAccount ? `/set?setId=${setId}` : `/checkout`);
    //     }
    // }

    const step4Handler = () => {
        window.location = isPaidAccount ? `/set` : `/checkout`
    }

    const step3Handler = () => {

        if (false) { // if (competitors.length > 3 && selected.length < 3) { // removing based on beta feedback

            setValidationMessage("Please choose at least 3 companies...")
        } else {
            // goToStep(4);

            const setName = name ? name : `${userInput ? parseFav(userInput) : "My"}`;

            console.log(setName);

            setFetchingMessage("Creating your competitive set... one sec!");

            setFetching(true);

            //http://localhost:3000/set?setId=a2bf3043-8b57-4ec6-b59b-7033ac641e36


            // Check if this is a new company
            if (!userReducer.companyList.find(c => c.slug === userInput)) {
                const slug = parseFav(userInput);

                const url = !userInput.endsWith("pricing") ? userInput + "/pricing" : userInput;
                console.log("calling submitUserFav with ", url);

                submitUserFav(url,
                    //() => addUserSet(setName, [...selected, slug], (setId) => window.location = isPaidAccount ? `/set` : `/checkout`)
                    () => addUserSet(setName, [...selected, slug], (setId) => goToStep(4))
                );

            } else {
                const slug = userInput;
                // addUserSet(setName, selected, (setId) => window.location = isPaidAccount ? `/set?setId=${setId}` : `/checkout`);
                addUserSet(setName, [...selected, slug], (setId) => goToStep(4));
            }
        }
    };

    const successFn = (data) => {
        setCompetitors(data);
        if (data && data.length > 0) goToStep(3);
        else goToStep(4);
    }

    const failFn = (data) => {

        setFetchingMessage("It will be just a few more seconds...");

        getCompanyCompetitors(userInput, successFn, () => {
            goToStep(2);
            setValidationMessage("Hmmm... we're having trouble accessing this URL");
        });
    }

    const step2Handler = () => {

        const invalidChar = hasInvalidCharacters(userInput);
        const includesDomain = includesPeriodAndCharacter(userInput);

        if (invalidChar) {
            setValidationMessage("The URL looks like it contains some invalid characters...");
            return;
        }

        if (!includesDomain) {
            setValidationMessage("The URL looks like it is missing a domain... ");
            return;
        }

        setValidationMessage("");
        setFetchingMessage("Taking a look at the website! This will take a  minute...");
        setFetching(true);

        getCompanyCompetitors(userInput, successFn, failFn);
    }

    const step1Handler = () => {

        if (!userInput) {
            setValidationMessage("Please type something in first...");
        }

        // Check if user chose an existing company
        const company = userReducer.companyList.find(c => c.slug === userInput);

        if (company) {
            console.log("Going to step 3");

            setFetchingMessage(`Fetching ${company.slug} info...`);
            setFetching(true);
            getCompanyCompetitors(userInput, successFn, failFn);
        } else {

            const invalidChar = hasInvalidCharacters(userInput);
            const includesDomain = includesPeriodAndCharacter(userInput);

            if (invalidChar || !includesDomain) {
                goToStep(2);
                return;
            }

            setFetchingMessage("Taking a look at the website! This will take a  minute...");
            setFetching(true);
            getCompanyCompetitors(userInput, successFn, failFn);
        }

        return;


        // if (!company) {

        // } else {
        //     window.location = '/welcome-add';
        // }

    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Company name ...',
        value: userInput,
        onChange
    };

    return (
        <>
            <div className="flex w-full h-screen">
                <div className='m-auto flex flex-col align-middle text-2xl w-full text-gray-700'>
                    {step === 1 &&
                        <>
                            <div className=" my-12 font-semibold  text-center mx-3">Type in your company name to get started</div>
                            <div className='flex flex-inline  md:flex-row flex-col align-middle m-auto gap-x-2'>

                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    onSuggestionSelected={onSelectedHandler}
                                    renderSuggestion={renderSuggestion}
                                    onChange={changeHandler}
                                    inputProps={inputProps}
                                />

                                <div className='md:my-0 my-9  mx-auto'>
                                    <button onClick={() => step1Handler()} className=" h-10 bg-custom-blue px-6 py-1.5 text-lg font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Get Started</button>
                                </div>

                            </div>
                            <div className='text-red-400 w-full text-center my-9 text-lg'>
                                {validationMessage ? validationMessage : <>&nbsp;</>}
                            </div>
                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className=" my-12 font-semibold  text-center">This company isn’t in our database yet. Please enter the URL to add it.</div>
                            <div className='flex  items-center gap-x-1 mb-32 flex-col md:flex-row  md:mx-auto'>
                                {/* <span className="text-gray-400">https://</span> */}
                                <input autoFocus type="text" placeholder='Type in company URL...' tabindex="0" className="px-3 text-xl border h-10 border-gray-300 md:w-96 w-64 my-3 mx-3" id="result" name="result" value={userInput} onChange={(e) => { setUserInput(e.target.value); setValidationMessage(""); }} />
                            </div>

                            <div className='my-6'>
                                <hr className='my-6' />
                                <div className='flex md:flex-row flex-col-reverse	 items-center justify-between my-9 mx-3 md:mx-0' >
                                    <div className='text-custom-blue' onClick={() => goToStep(1)}>Back</div>
                                    <div className='text-red-400 w-full text-center my-9 text-lg'>
                                        {validationMessage ? validationMessage : <>&nbsp;</>}
                                    </div>
                                    <button onClick={() => step2Handler()} className=" h-10 bg-custom-blue px-6 py-1.5 text-xl font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;</button>
                                </div>

                            </div>
                        </>
                    }
                    {step === 3 &&
                        <>
                            <div className=" mt-12 font-semibold  text-center">Select companies you'd like to compare <span className='text-custom-blue'>{parseFav(userInput)}</span> pricing to ... </div>
                            <div className=" my-3 mb-12 text-center text-base"> (you can always add more later)</div>

                            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 w-full items-center">

                                {competitors.slice(0, 16).map(competitor => (

                                    <div
                                        className={classNames(
                                            !isSelected(competitor) ? 'bg-gray-50 opacity-70	' : 'bg-blue-100',
                                            'border flex flex-col items-center justify-center text-lg p-6 mx-3 md:mx-0'
                                        )}
                                        onClick={() => toggleSelected(competitor)}>
                                        <img src={getLogoUrl(competitor)} className="h-24 w-24"></img>
                                        {competitor}
                                    </div>

                                ))}

                            </div>
                            <div className='my-6'>
                                <div className=' mx-3 md:mx-0'>{selected.length} Selected </div>
                                <hr className='my-6' />
                                <div className='flex md:flex-row flex-col-reverse	 items-center justify-between my-9 mx-3 md:mx-0' >
                                    <div className='text-custom-blue' onClick={() => goToStep(1)}>Back</div>
                                    <div className='text-red-400 w-full text-center my-9 text-lg'>
                                        {validationMessage ? validationMessage : <>&nbsp;</>}
                                    </div>
                                    <button onClick={() => step3Handler()} className=" h-10 bg-custom-blue px-6 py-1.5 text-xl font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            </div>
                        </>
                    }
                    {step === 4 &&
                        <>
                            <div className=" my-12 font-semibold  text-center">Amazing! 🌟 Your competitive intel is waiting.</div>
                            <div className=" mt-3 mb-12   text-center">Click next to check it out.</div>

                            <div className='flex flex-col align-middle w-full gap-x-1'>
                                <div className='flex flex-inline items-center gap-x-1 mx-auto md:w-96'>

                                    {/* <input autoFocus type="text" placeholder={"E.g. " + parseFav(userInput) + " Competitve Set"} tabindex="0" className="m-auto md:max-w-xl md:mx-0 mx-3 px-3 text-xl border h-10 border-gray-300 md:w-96 w-72 mb-32" id="result" name="result" value={name} onChange={(e) => setName(e.target.value)} /> */}
                                </div>
                                <hr className='my-6' />

                                <div className='flex md:flex-row flex-col-reverse	 items-center justify-between my-9 mx-3 md:mx-0' >
                                    <div className='text-custom-blue' onClick={() => competitors && competitors.length > 0 ? goToStep(3) : goToStep(1)}>Back</div>
                                    <button onClick={() => step4Handler()} className=" h-10 md:my-0 my-9 bg-custom-blue px-6 py-1.5 text-xl font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>

        </>
    );

};

export default OnboardingAdd;