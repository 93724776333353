import {
  LOAD_CATEGORIES,
  LOAD_COMPANIES,
  RESET,
  LOAD_CATEGORY_COMPANIES,
  LOAD_USER_COMPANIES,
  SET_EXPLORE_COMPANIES_LOADING,
  LOAD_EXPLORE_COMPANIES
} from '../actionTypes';

const initialState = {
  allCompanyNames: [],
};

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return null;
    }
    case LOAD_COMPANIES: {
      const { companies } = action.payload;

      companies.sort((c1, c2) => {
        return c1.companyId.localeCompare(c2.companyId);
      });

      return {
        ...state,
        allCompanyNames: companies.map((c) => c.companyId),
      };
    }
    case LOAD_CATEGORY_COMPANIES:
      const { companies } = action.payload;
      // console.log(companies);

      return {
        ...state,
        categoryCompanies: JSON.stringify(companies),
      };
    case LOAD_USER_COMPANIES:

        return {
          ...state,
          userCompanies: JSON.stringify(action.payload.companies)
        }
        break;
    case SET_EXPLORE_COMPANIES_LOADING:
      return {
        ...state,
        exploreLoading:  (action.payload.loading)
      }
    case LOAD_EXPLORE_COMPANIES:

          return {
            ...state,
            exploreCompanies:  (action.payload.companies),
            employeeStats : (action.payload.employeeStats),
            categoryStats : (action.payload.categoryStats),
            pricingStats : (action.payload.pricingStats),
            acqStats  : (action.payload.acqStats),
            totalAllCompanies : (action.payload.totalAllCompanies),
            exploreTotalCompanies:  (action.payload.totalCompanies),
            exploreCompaniesPerPage:  (action.payload.companiesPerPage),
            exploreCurrentPage:  (action.payload.currentPage)
          }
    case LOAD_CATEGORIES: {
      const { categories } = action.payload;

      categories.sort((c1, c2) => {
        return c1.label.localeCompare(c2.label);
      });

      return {
        ...state,
        categories: categories,
      };
    }
    default:
      return state;
  }
}
