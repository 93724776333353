/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { removeSlugFromSet } from '../../redux/actionsPages';
import { Fragment } from 'react'
import SearchControl from '../Settings/SearchControl';
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef } from 'react';
import { generateCompaniesCsvReport } from '../../redux/actionsUsers';
import { getCurrentSetCompanies, downloadCSV } from '../../helpers/common';
import { getVersionStats } from '../../helpers/common';
import { loadCompanies } from '../../redux/actionsUsers';
import UpgradeModal from '../Settings/UpgradeModal';

const fetchReport = async (slugs, setLoading) => {

    if (setLoading) setLoading(true);


    try {

        const csv = await generateCompaniesCsvReport(slugs);
        downloadCSV(csv, 'companies.csv');

    } catch (e) {
        console.error(e);
    }

    if (setLoading) setLoading(false);
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const removeCompany = (event, slug) => {
    removeSlugFromSet(slug);
    event.stopPropagation();
}

const getContextMenu = (slug, pricingPageUrl) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button onClick={(e) => e.stopPropagation()} className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {pricingPageUrl &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            window.open(pricingPageUrl, '_blank');
                                        }}
                                        target="_blank"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        View Pricing Page
                                    </a>
                                )}
                            </Menu.Item>
                        }
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="#"
                                    onClick={(event) => removeCompany(event, slug)}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                                        'block px-4 py-2 text-sm'
                                    )}
                                >
                                    Remove Company
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const SetCompanies = ({ onClickHandlerDetails, onShowGallery }) => {

    const intervalRef = useRef(null);
    const userReducer = useSelector(state => state.userReducer);
    const [fetching, setFetching] = useState(false);
    const [sortBy, setSortBy] = useState('change');
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const checkPending = (userReducer) => {

        console.log('Checking pending');
        if (userReducer.requests && userReducer.requests.filter(r => r.state === 'new' || r.state === 'inprogress').length > 0) {

            console.log("Found active requests. Calling refresh");
            loadCompanies();
        } else {
            console.log("No active requests");
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        console.log("useEffect")
        intervalRef.current = setInterval(() => {
            checkPending(userReducer);
        }, 15000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [userReducer]);

    if (!userReducer) { return <></>; }

    const myCompanies = getCurrentSetCompanies(userReducer);
    const showInstructions = (myCompanies.length === 0);
    const instructions = showInstructions ?
        <>

            <div className='px-16 text-gray-700 leading-9 text-xl'>
                <div className='mb-3 -ml-6 font-bold'>Welcome!</div>
                <ol class="list-decimal">
                    <li>Add companies</li>
                    <li>
                        {/* <div className='flex flex-row'> Customize your alerts
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="cursor-pointer block h-6 w-6 m-auto ml-4 text-gray-600"><path fill-rule="evenodd" d="M8.34 1.804A1 1 0 019.32 1h1.36a1 1 0 01.98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 011.262.125l.962.962a1 1 0 01.125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 01.804.98v1.361a1 1 0 01-.804.98l-1.473.295a6.95 6.95 0 01-.587 1.416l.834 1.25a1 1 0 01-.125 1.262l-.962.962a1 1 0 01-1.262.125l-1.25-.834a6.953 6.953 0 01-1.416.587l-.294 1.473a1 1 0 01-.98.804H9.32a1 1 0 01-.98-.804l-.295-1.473a6.957 6.957 0 01-1.416-.587l-1.25.834a1 1 0 01-1.262-.125l-.962-.962a1 1 0 01-.125-1.262l.834-1.25a6.957 6.957 0 01-.587-1.416l-1.473-.294A1 1 0 011 10.68V9.32a1 1 0 01.804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 01.125-1.262l.962-.962A1 1 0 015.38 3.03l1.25.834a6.957 6.957 0 011.416-.587l.294-1.473zM13 10a3 3 0 11-6 0 3 3 0 016 0z" clip-rule="evenodd"></path></svg>
                        </div> */}
                        Check out their history
                    </li>
                    <li>Get alerted when they get updated</li>
                </ol>
            </div>

        </> :
        <></>;

    const currentSet = userReducer.currentSet;
    if (!currentSet || !currentSet.attributes) {
        return <>Current set has not loaded</>
    }

    const setCompanies = [];
    currentSet.attributes.forEach(company => {
        const versions = currentSet.versions.find(v => v.slug === company.slug) ? currentSet.versions.find(v => v.slug === company.slug).versions : [];

        const stats = getVersionStats(userReducer, company.slug);
        const lastVersion = stats.daysOld; // || '-'; // versions && versions.length > 0 ? vertsionToDaySince(versions[versions.length - 1].version) : null;

        // console.log(company.slug, lastVersion);
        if (company.slug === 'approval') {
            console.log('approval');
            console.log(stats);
        }
        setCompanies.push({
            slug: company.slug,
            logoUrl: company.logoUrl,
            pricingPageUrl: company.url,
            privatePricing: company.privatePricing,
            versionCount: versions.length,
            daysSinceLastChange: lastVersion,
            trackingSince: stats.trackingSince,
            showProcessingState: false //lastVersion == null
        });
    });


    const fetchReportAction = () => {

        // const slugs = getCurrentSetCompanies(userReducer, true).map(c => c.slug);
        const slugs = currentSet.attributes.map(item => item.slug); // getCurrentSetSlugs(userReducer, true);
        console.log(slugs)
        if (slugs.length === 0) {
            alert("To generate a report, first add some companies ...")
        } else {
            fetchReport(slugs, setFetching);
        }
    };

    if (sortBy === 'name') {
        setCompanies.sort((a, b) => a.slug.localeCompare(b.slug));
    } else {

        setCompanies.sort((a, b) => {
            if (a.daysSinceLastChange === null && b.daysSinceLastChange === null) {
                return 0;
            }
            if (a.daysSinceLastChange === null) {
                return 1;
            }
            if (b.daysSinceLastChange === null) {
                return -1;
            }
            return a.daysSinceLastChange - b.daysSinceLastChange;
        });

    }

    const toggleSort = () => {
        if (sortBy === 'name') {
            setSortBy('change');
        } else {
            setSortBy('name');
        }
    }

    return (
        <>
            {/* <div className='flex flex-row justify-between '> */}
            <div className='flex flex-col w-full'>
                <div className='flex md:flex-row md:justify-between flex-col align-top  mt-12 mb-16'>
                    <SearchControl />
                    <div className='w-64 flex flex-row-reverse'>
                        {/* <SetsControl /> */}
                    </div>
                </div>
                {!showInstructions &&
                    <>
                        <div className='w-full flex flex-col md:flex-row justify-between mb-6 space-y-1'>
                            <div className='flex flex-row cursor-pointer' onClick={() => toggleSort()}>
                                Sort by:
                                <button className='text-custom-blue ml-1 flex flex-row  '>
                                    {sortBy === 'name' ? 'Name' : 'Last Updated'}

                                </button>
                                <ArrowsUpDownIcon className="h-4 w-4 my-auto ml-1 text-custom-blue" aria-hidden="true" />
                            </div>


                            <div className='flex flex-row space-x-2'>
                                <div className='text-custom-blue' onClick={() => onShowGallery()}>
                                    Show Gallery
                                </div>
                                <div>|</div>
                                <button onClick={() => fetchReportAction()} className='my-auto text-right text-custom-blue  lg:visible' type="button">Export to CSV</button>
                            </div>
                        </div>
                    </>}

            </div>
            {showInstructions && instructions}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
                {
                    setCompanies.map((company, setIndex) => (
                        <Fragment key={company.slug}>
                            <div className="bg-white p-6 border hover:bg-blue-50 flex flex-row items-center justify-between cursor-pointer" onClick={() => onClickHandlerDetails(company.slug)}>
                                <div className='flex flex-row'>
                                    <img className="h-16 w-16 mr-6" src={company.logoUrl}></img>
                                    <div className='flex flex-col'>

                                        <h2 className='font-medium text-lg mb-1 flex flex-row'>
                                            {company.slug}
                                        </h2>
                                        {company.showProcessingState ?
                                            <>
                                                {company.privatePricing ?
                                                    <span className='text-sm mr-2'>This company has private pricing.</span>
                                                    :
                                                    <span className='text-sm mr-2'>This company is being processed by PricingSaaS</span>
                                                }
                                            </>
                                            :
                                            <> {
                                                company.versionCount === 1 ?
                                                    <div
                                                        // onClick={(event) => { onClickHandler(company.slug); event.stopPropagation(); }}
                                                        className='flex flex-row cursor-pointer'><span className='text-sm mr-2'>No changes since {company.trackingSince}</span></div>
                                                    : <>
                                                        {company.daysSinceLastChange !== null && <>
                                                            <div
                                                                className='flex flex-row'><span className='text-sm mr-2'>Last pricing update:</span> <span className='text-sm text-custom-blue cursor-pointer'> {`${company.daysSinceLastChange == 0 ? '0' : company.daysSinceLastChange}`} days ago</span></div>
                                                        </>
                                                        }
                                                    </>

                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='float-right'>
                                    {getContextMenu(company.slug, company.pricingPageUrl)}
                                </div>
                            </div>
                        </Fragment>
                    ))
                }
                {userReducer.addingCompanyToSet &&

                    <Fragment key={'addCompany'}>
                        <div className="bg-white p-6 flex flex-row items-center justify-between cursor-pointer" >
                            Adding ...
                        </div>
                    </Fragment>
                }
            </div>
            {/* <UpgradeModal
                show={showUpgradeModal}
                closeHandler={() => setShowUpgradeModal(false)}
            /> */}
        </>
    );
};

export default SetCompanies;