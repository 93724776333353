import React from 'react';
import { callGetApi } from '../helpers/apiHelper';

export class AddCompanyControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newCompanyurl: '',
      submitted: false,
      submitting: false,
    };
  }

  submitForm() {
    let companyUrl = this.state.newCompanyurl;
    // const url = (process.env.STAGE && process.env.STAGE === 'prod') ? 'tasks.pricingsaas.com' : 'dev-tasks.pricingsaas.com';
    // const api = "https://" + url + "/scraper/createCompanyTask?url=" + companyUrl;

    const callback = (data) => {
      this.setState({ submitted: true, submitting: false });
      console.log(data);
      // window.location.href = "/companies";
    };

    this.setState({ submitting: true });
    callGetApi(
      process.env.REACT_APP_AUDIENCE +
        '/scraper/createCompanyTask?url=' +
        companyUrl,
      callback
    );
  }

  render() {
    return (
      <>
        <div
          style={{ paddingLeft: '20px', paddingTop: '10px' }}
          className="remove"
          hidden={this.state.submitted || this.state.submitting}
        >
          <div className="text-2xl flex pt-12 pb-9">Submit a Company</div>
          <form className="w-full flex flex-col">
            <label for="companyURL" className="mr-8">
              Company Pricing Page URL:{' '}
            </label>
            <input
              type="text"
              id="companyURL"
              className="border border-teal-500 w-96 my-3 h-9"
              placeholder="Type url here..."
              required
              onChange={(event) => {
                this.setState({ newCompanyurl: event.target.value });
              }}
            />
          </form>
          <br />
          <div className="w-full md:w-1/3 lg:w-1/3">
            <div className="pt-5">
              <div className="flex">
                <button
                  onClick={() => this.submitForm()}
                  className="border text-white border-teal-500 rounded-sm font-bold py-2 px-5 flex items-center hover:bg-teal-500 hover:text-white"
                  style={{ background: '#28CDC2' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-12 ">
          {this.state.submitting && <h1>Submitting...</h1>}
          {this.state.submitted && (
            <h1>Thank you! Your request has been submitted.</h1>
          )}
        </div>
      </>
    );
  }
}

export default AddCompanyControl;
