import {
  SET_CNP,
  FILTER_CNP,
  SET_FILTER_ATTRIBUTE,
  FILTERTYPE,
  UPDATE_FAVS,
  RESET
} from '../actionTypes';

const initialState = {
  companies: [],
  freemiumandtrialFilter: '',
  valueMetricFilter: '',
  enterpriseFilter: '',
  companyFilter: '',
  planNameFilter: '',
  companysizeFilter: '',
  resetFilter: '',
  favouritesFilter: '',
};

export default function cnpReducer(state = initialState, action) {
  switch (action.type) {
    case RESET: {
      return null;
    }
    case SET_CNP: {
      const { companies } = action.payload;

      return {
        ...state,
        // companies : [],
        // test : JSON.stringify(companies)
        companies: [...companies],
        companiesOriginal: [...companies],
      };
    }
    case UPDATE_FAVS: {
      const { companiesOriginal, companiesFiltered } = action.payload;

      return {
        ...state,
        companies: [...companiesFiltered],
        companiesOriginal: [...companiesOriginal],
      };
    }
    case SET_FILTER_ATTRIBUTE: {
      const { filterType, value } = action.payload;
      switch (filterType) {
        case FILTERTYPE.ENTERPRISETYPE: {
          return {
            ...state,
            enterpriseFilter: value,
          };
        }
        case FILTERTYPE.FREEMIUMANDFREETRIALTYPE: {
          return {
            ...state,
            freemiumandtrialFilter: value,
          };
        }
        case FILTERTYPE.VALUEMETRIC: {
          return {
            ...state,
            valueMetricFilter: value,
          };
        }
        case FILTERTYPE.PLANNAMES: {
          return {
            ...state,
            planNameFilter: value,
          };
        }
        case FILTERTYPE.COMPANYTYPE: {
          return {
            ...state,
            companyFilter: value,
          };
        }
        case FILTERTYPE.COMPANYSIZE: {
          return {
            ...state,
            companysizeFilter: value,
          };
        }
        case FILTERTYPE.FAVOURITESTYPE: {
          return {
            ...state,
            favouritesFilter: value,
          };
        }
        case FILTERTYPE.RESETTYPE: {
          return {
            ...state,
            resetFilter: value,
          };
        }
        default: {
          throw new Error('unknown filter type');
        }
      }
    }
    case FILTER_CNP: {
      const { content } = action.payload;

      return {
        ...state,
        companies: [...content],
      };
    }
    default:
      return state;
  }
}
