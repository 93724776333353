import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animeatedComponents = makeAnimated();

const FilterExploreModal = (props) => {
  const modelData = !props.modelData ? [] : props.modelData.map( (model,index) => ({ value: model, label: model, num : index}));
  const models = !props.models ? [] : props.models.map( (model,index) => ({ value: model, label: model, num : index}));
  const pricingModels = !props.pricingModels ? [] : props.pricingModels.map( (model,index) => ({ value: model, label: model, num : index}));
  const pricingModelsData = !props.pricingModelsData ? [] : props.pricingModelsData.map( (model,index) => ({ value: model, label: model, num : index}));
  const pricingMetricsData = !props.pricingMetricsData ? [] : props.pricingMetricsData ;
  const sizesData = !props.sizesData ? [] : props.sizesData.map( (size,index) => ({ value: size, label: size, num : index}));
  const sizes = !props.sizes ? [] : props.sizes.map( (size,index) => ({ value: size, label: size, num : index}));
  const categories = !props.categories ? [] : props.categories.map( (category,index) => ({ value: category.label, label: category.label, num : index}));
  const categoriesData = !props.categoriesData ? [] : props.categoriesData.map( (category,index) => ({ value: category, label: category, num : index}));
  const totalCompanies=    !props.totalCompanies ? null : props.totalCompanies;
  const totalAllCompanies=    !props.totalAllCompanies ? null : props.totalAllCompanies;


  const [searchTerm, setSearchTerm] = useState(props.searchTerm)
  const [selectedSizes, setSelectedSizes] = useState(sizes)
  const [selectedModels, setSelectedModel] = useState(models)
  const [selectedCategories, setSelectedCategories] = useState(categoriesData)
  const [selectedPricingModels, setSelectedPricingModels] = useState(pricingModelsData)
  const [selectedPricingMetrics, setSelectedPricingMetrics] = useState(props.pricingMetrics ? props.pricingMetrics : [])


return (
    <>
    { props.show &&
    <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-white opacity-95"></div>
      <div className="modal-container fixed w-full h-full z-50 overflow-y-auto ">
        <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50" onClick={() => props.onCloseHandler()}>
          <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
        <div className="modal-content container mx-auto h-auto text-left p-4">
          <div className="flex justify-between items-center pb-2">
            <p className="text-2xl font-bold">Filter Companies</p>
          </div>
          <div className="flex justify-between items-center pb-2">
            <p className="text-lg font-medium ">{ totalAllCompanies ? `Currently Showing ${totalCompanies} of ${totalAllCompanies}` : '' }</p>
          </div>
          <p className="text-sm w-full text-left py-9 text-gray-500">
              <label className="  pl-2  w-full text-left" htmlFor="keyword">Keywords</label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 p-2 w-full">
                <input type="text" name="keyword" id="keyword" onChange={(event) => setSearchTerm(event.target.value) } value={searchTerm} className="block w-full max-w-lg  border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:max-w-xs sm:text-sm"/>
              </div>
              <div className='my-6'>
              <label className="w-64 pl-2  " htmlFor="valuemetrics">Software Category</label>
                <Select
                  id="valuemetrics"
                  className="w-full p-1 mr-6"
                  closeMenuOnSelect={false}
                  components={animeatedComponents}
                  value={selectedCategories}
                  isMulti
                  // options={this.props.valuemetricsData}
                  options={categories}
                  onChange={((event) => setSelectedCategories(event))}
              />
              </div>
              <div className='my-6'>
              <label className="w-64 pl-2  " htmlFor="pricingModel">Pricing Model</label>
                <Select
                  id="pricingModel"
                  className="w-full p-1 mr-6"
                  closeMenuOnSelect={false}
                  components={animeatedComponents}
                  value={selectedPricingModels}
                  isMulti
                  // options={this.props.valuemetricsData}
                  options={pricingModels}
                  onChange={((event) => setSelectedPricingModels(event))}
              />
              </div>
              <div className='my-6'>
              <label className="w-64 pl-2  " htmlFor="valuemetrics">Acquisition Model</label>
                <Select
                  id="valuemetrics"
                  className="w-full p-1 mr-6"
                  closeMenuOnSelect={false}
                  components={animeatedComponents}
                  value={selectedModels}
                  isMulti
                  // options={this.props.valuemetricsData}
                  options={modelData}
                  onChange={((event) => setSelectedModel(event))}
              />
              </div>

              {/* <div className='my-6'>
              <label className="w-64 pl-2  " htmlFor="valuemetrics">Pricing Metrics</label>
                <Select
                  id="pricingMetrics"
                  className="w-full p-1 mr-6"
                  closeMenuOnSelect={false}
                  components={animeatedComponents}
                  value={selectedPricingMetrics}
                  isMulti
                  // options={this.props.valuemetricsData}
                  options={pricingMetricsData}
                  onChange={((event) => setSelectedPricingMetrics(event))}
              />
              </div> */}
              <label className="w-64  pl-2  " htmlFor="valuemetrics">Company Size</label>
              <Select
                id="valuemetrics"
                className="w-full p-1 mr-6"
                closeMenuOnSelect={false}
                components={animeatedComponents}
                value={selectedSizes}
                isMulti
                options={sizesData}
                onChange={((event) => setSelectedSizes(event))}
            />
            </p>

            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                style={{ background: '#008CFF'}}
                autoFocus
                onClick={ () => props.onFilterHandler( {
                  categories : selectedCategories.map( m => m.value),
                  searchTerm: searchTerm,
                  models: selectedModels.map( m => m.value),
                  pricingModels: selectedPricingModels.map( m => m.value),
                  pricingMetrics : selectedPricingMetrics.map( m => m.value),
                  sizes: selectedSizes.map( ss => ss.value)})}
              >
                Apply Filter
              </button>
            </div>

        </div>
      </div>
    </div>
    }
    </>
  )
};

export default FilterExploreModal;